<template>
<div class="h-full" :class="{ 'hasSpecialBorder': specialBorder }">
    <PinturaEditor v-if="ready" ref="doka" v-bind="editorProps" :src="inlineSrc" @pintura:loaderror="resetApplication()" @pintura:processerror="resetApplication()"  />
    <h2 class="text-left" v-else>
        Bitte Bild und Größe wählen.
    </h2>
</div>
</template>

<script>
import {
    mapState
} from 'vuex'
import {
    moveTo
} from '@/utils/fullpage'

// Import vue-pintura
import {
    PinturaEditor
} from 'vue-pintura'

// Import pintura
import {
    // editor
    createDefaultImageReader,
    createDefaultImageWriter,
    locale_en_gb,

    // plugins
    setPlugins,
    plugin_crop,
    plugin_crop_locale_en_gb,
    plugin_filter,
    plugin_filter_defaults,
    plugin_filter_locale_en_gb,
    plugin_finetune,
    plugin_finetune_defaults,
    plugin_finetune_locale_en_gb,
    plugin_annotate,
    plugin_annotate_locale_en_gb,
    markup_editor_defaults,
    markup_editor_locale_en_gb,
    colorStringToColorArray,
    openEditor,
    processImage,
    createDefaultImageOrienter,
    legacyDataToImageState
} from 'pintura'

const myLocales = {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...markup_editor_locale_en_gb,
    ...plugin_annotate_locale_en_gb
};

//(myLocales.statusLabelLoadImage = 'Verarbeite Bild...'),
//(myLocales.statusLabelProcessImage = 'Erstelle Vorschau...'),
(myLocales.labelReset = 'Zurücksetzen'),
(myLocales.labelDefault = 'Standard'),
(myLocales.labelAuto = 'Auto'),
(myLocales.labelNone = 'Keine'),
(myLocales.labelEdit = 'Bearbeiten'),
(myLocales.labelClose = 'Schliessen'),
(myLocales.labelSizeExtraSmall = 'sehr klein'),
(myLocales.labelSizeSmall = 'klein'),
(myLocales.labelSizeMediumSmall = 'medium klein'),
(myLocales.labelSizeMedium = 'medium'),
(myLocales.labelSizeMediumLarge = 'medium gross'),
(myLocales.labelSizeLarge = 'gross'),
(myLocales.labelSizeExtraLarge = 'sehr gross'),
(myLocales.labelButtonRevert = 'umkehren'),
(myLocales.labelButtonCancel = 'abbrechen'),
(myLocales.labelButtonUndo = 'zurücksetzen'),
(myLocales.labelButtonRedo = 'Schritt zurück'),
(myLocales.labelButtonExport = 'fertig'),
(myLocales.statusLabelButtonClose = 'schliessen'),
(myLocales.cropLabel = 'Bildausschnitt'),
(myLocales.cropLabelButtonRecenter = 'zentrieren'),
(myLocales.cropLabelButtonRotateLeft = 'nach links drehen'),
(myLocales.cropLabelButtonRotateRight = 'nach rechts drehen'),
(myLocales.cropLabelButtonFlipHorizontal = 'horizontal spiegeln'),
(myLocales.cropLabelButtonFlipVertical = 'vertikal spiegen'),
(myLocales.cropLabelSelectPreset = 'Form beschneiden'),
(myLocales.cropLabelCropBoundary = 'Rand beschneiden'),
(myLocales.cropLabelCropBoundaryEdge = 'Bildrand'),
(myLocales.cropLabelCropBoundaryNone = 'Keine'),
(myLocales.cropLabelTabRotation = 'Rotation'),
(myLocales.cropLabelTabZoom = 'Zoom');
(myLocales.finetuneLabel = 'Bearbeiten'),
(myLocales.finetuneLabelBrightness = 'Helligkeit'),
(myLocales.finetuneLabelContrast = 'Kontrast'),
(myLocales.finetuneLabelSaturation = 'Sättigung'),
(myLocales.finetuneLabelExposure = 'Belichtung'),
(myLocales.finetuneLabelTemperature = 'Temperatur'),
(myLocales.finetuneLabelGamma = 'Gamma'),
(myLocales.finetuneLabelClarity = 'Klarheit'),
(myLocales.finetuneLabelVignette = 'Vignette');
(myLocales.filterLabel = 'Bildfilter'),
(myLocales.filterLabelChrome = 'chrome'),
(myLocales.filterLabelFade = 'ausblassen'),
(myLocales.filterLabelCold = 'kalt'),
(myLocales.filterLabelWarm = 'warm'),
(myLocales.filterLabelPastel = 'pastel'),
(myLocales.filterLabelMonoDefault = 'schwarzweiss'),
(myLocales.filterLabelMonoNoir = 'schwarz'),
(myLocales.filterLabelMonoWash = 'auswaschen'),
(myLocales.filterLabelMonoStark = 'stark'),
(myLocales.filterLabelSepiaDefault = 'sepia'),
(myLocales.filterLabelSepiaBlues = 'blues'),
(myLocales.filterLabelSepiaRust = 'rost'),
(myLocales.filterLabelSepiaColor = 'färbe');
(myLocales.shapeLabelButtonSelectSticker = 'Bild wählen'),
(myLocales.shapeTitleButtonFlipHorizontal = 'horizontal spiegeln'),
(myLocales.shapeTitleButtonFlipVertical = 'vertikal spiegeln'),
(myLocales.shapeTitleButtonRemove = 'entfernen'),
(myLocales.shapeTitleButtonDuplicate = 'duplizieren'),
(myLocales.shapeTitleButtonMoveToFront = 'in den Vordergrund'),
(myLocales.shapeLabelInputText = 'Text bearbeiten'),
(myLocales.shapeLabelInputCancel = 'abbrechen'),
(myLocales.shapeLabelInputConfirm = 'bestätigen'),
(myLocales.shapeLabelStrokeNone = 'keine Kontur'),
(myLocales.shapeLabelFontStyleNormal = 'Normal'),
(myLocales.shapeLabelFontStyleBold = 'Bold'),
(myLocales.shapeLabelFontStyleItalic = 'Italic'),
(myLocales.shapeLabelFontStyleItalicBold = 'Italic Bold'),
(myLocales.shapeTitleBackgroundColor = 'Farbe füllen'),
(myLocales.shapeTitleFontFamily = 'Schrift'),
(myLocales.shapeTitleFontSize = 'Schriftgrösse'),
(myLocales.shapeTitleFontStyle = 'Schriftstil'),
(myLocales.shapeTitleLineHeight = 'Leading'),
(myLocales.shapeTitleLineStart = 'Start'),
(myLocales.shapeTitleLineEnd = 'End'),
(myLocales.shapeTitleStrokeWidth = 'Konturlänge'),
(myLocales.shapeTitleStrokeColor = 'Konturfarbe'),
(myLocales.shapeTitleLineDecorationBar = 'Linie'),
(myLocales.shapeTitleLineDecorationCircle = 'Kreis'),
(myLocales.shapeTitleLineDecorationSquare = 'Quadrat'),
(myLocales.shapeTitleLineDecorationArrow = 'Pfeil'),
(myLocales.shapeTitleLineDecorationCircleSolid = 'Kreis mit Kontur'),
(myLocales.shapeTitleLineDecorationSquareSolid = 'Quadrat mit Kontur'),
(myLocales.shapeTitleLineDecorationArrowSolid = 'Pfeil mit Kontur'),
(myLocales.shapeTitleColorTransparent = 'transparent'),
(myLocales.shapeTitleColorWhite = 'weiss'),
(myLocales.shapeTitleColorSilver = 'silber'),
(myLocales.shapeTitleColorGray = 'grau'),
(myLocales.shapeTitleColorBlack = 'schwarz'),
(myLocales.shapeTitleColorNavy = 'navy'),
(myLocales.shapeTitleColorBlue = 'blau'),
(myLocales.shapeTitleColorAqua = 'acqua'),
(myLocales.shapeTitleColorTeal = 'türkis'),
(myLocales.shapeTitleColorOlive = 'olive'),
(myLocales.shapeTitleColorGreen = 'grün'),
(myLocales.shapeTitleColorYellow = 'gelb'),
(myLocales.shapeTitleColorOrange = 'orange'),
(myLocales.shapeTitleColorRed = 'rot'),
(myLocales.shapeTitleColorMaroon = 'bordeaux'),
(myLocales.shapeTitleColorFuchsia = 'fuchsia'),
(myLocales.shapeTitleColorPurple = 'violett'),
(myLocales.shapeTitleTextColor = 'Schriftfarbe'),
(myLocales.shapeTitleTextAlign = 'Textausrichtung'),
(myLocales.shapeTitleTextAlignLeft = 'linksbündig'),
(myLocales.shapeTitleTextAlignCenter = 'zentrieren'),
(myLocales.shapeTitleTextAlignRight = 'rechtsbündig'),
(myLocales.annotateLabel = 'Gestalten'),
(myLocales.shapeLabelToolSharpie = 'schärfen'),
(myLocales.shapeLabelToolEraser = 'wegradieren'),
(myLocales.shapeLabelToolRectangle = 'Rechteck'),
(myLocales.shapeLabelToolEllipse = 'Ellipse'),
(myLocales.shapeLabelToolArrow = 'Pfeil'),
(myLocales.shapeLabelToolLine = 'Linie'),
(myLocales.shapeLabelToolText = 'Text'),
(myLocales.shapeLabelToolPreset = 'Stickers')

// console.log({
//     ...locale_en_gb,
//     ...plugin_crop_locale_en_gb,
//     ...plugin_finetune_locale_en_gb,
//     ...plugin_filter_locale_en_gb,
//     ...plugin_annotate_locale_en_gb,
//     ...markup_editor_locale_en_gb,
// });

setPlugins(plugin_crop, plugin_finetune, plugin_annotate, plugin_filter)

function computeMinSize(width, height, resolution = 72) {
    var min_height = (height / 2.54) * resolution
    var min_width = (width / 2.54) * resolution

    var result = {
        width: Math.round(min_width),
        height: Math.round(min_height)
    }
    console.log('computeMinSize', result)
    return result
}

export default {
    name: 'ImageEditor',
    components: {
        PinturaEditor
    },
    props: [
        'src',
        'ratio',
        'bleedX',
        'bleedY',
        'width',
        'height',
        'originalWidth',
        'originalHeight',
        'specialBorder'
    ],
    created() {
        console.log('created')
    },
    beforeDestroy() {
        console.log('beforeDestroy')
    },
    data() {
        return {
            // defaults
            editorProps: {
                // map legacy data objects to new imageState objects
                legacyDataToImageState: legacyDataToImageState,

                // used to create the editor, receives editor configuration, should return an editor instance
                createEditor: openEditor,

                // Required, used for reading the image data
                imageReader: createDefaultImageReader(),

                // optionally. can leave out when not generating a preview thumbnail and/or output image
                imageWriter: createDefaultImageWriter(),

                // used to generate poster images, runs an editor in the background
                imageProcessor: processImage,

                ...plugin_finetune_defaults,
                ...plugin_filter_defaults,
                ...markup_editor_defaults,

                locale: {
                    ...myLocales
                },

                // editor options
                editorOptions: {
                    imageOrienter: createDefaultImageOrienter(),
                    ...plugin_finetune_defaults,
                    ...plugin_filter_defaults,
                    ...markup_editor_defaults
                },
                imageCropAspectRatio: this.ratio,
                imageCropLimitToImage: true,
                imageCropMinSize: computeMinSize(this.width, this.height),
                enableButtonExport: false,

                willRenderCanvas: (shapes, state) => {
                    // console.log(state);   
                    const {
                        utilVisibility,
                        selectionRect
                    } = state
                    var lineColor

                    if (this.current_bleed.rgba) {
                        lineColor = colorStringToColorArray(this.current_bleed.rgba)
                    } else {
                        lineColor = colorStringToColorArray("rgba(255, 165, 0, .95)")
                    }

                    // exit if crop utils is not visible
                    if (utilVisibility.crop <= 0) return shapes

                    // shortcuts to selection rect
                    const {
                        x,
                        y,
                        width,
                        height
                    } = selectionRect

                    let interfaceShapes_;
                    if ( this.specialBorder ) {
                        interfaceShapes_ = [{
                            // top
                                x1: x - (this.bleedY / 2) * height,
                                x2: x + width + (this.bleedY / 2) * height,
                                y1: y - ((this.bleedY / 2) * height) / 2,
                                y2: y - ((this.bleedY / 2) * height) / 2,
                                strokeColor: lineColor, //[1, 0, 0],
                                strokeWidth: (this.bleedY / 2) * height
                            },
                            {
                            // bottom
                                x1: x,
                                x2: x + width,
                                y1: y + height + ((this.bleedY / 2) * height) / 2,
                                y2: y + height + ((this.bleedY / 2) * height) / 2,
                                strokeColor: lineColor,
                                strokeWidth: (this.bleedY / 2) * height
                            },
                            {
                            // left
                                x1: x - ((this.bleedX / 2) * width) / 2, // (this.bleedX*width)/2
                                x2: x - ((this.bleedX / 2) * width) / 2,
                                y1: y,
                                y2: y + height + (this.bleedY / 2) * height,
                                strokeColor: lineColor,
                                strokeWidth: (this.bleedY / 2) * height
                            },
                            {
                            // right
                                x1: x + width + ((this.bleedX / 2) * width) / 2,
                                x2: x + width + ((this.bleedX / 2) * width) / 2,
                                y1: y,
                                y2: y + height + (this.bleedY / 2) * height,
                                strokeColor: lineColor,
                                strokeWidth: (this.bleedY / 2) * height
                            },
                            ...shapes.interfaceShapes
                        ]
                    } else {
                        interfaceShapes_ = [{
                                x1: x,
                                x2: x + width,
                                y1: y + ((this.bleedY / 2) * height) / 2,
                                y2: y + ((this.bleedY / 2) * height) / 2,
                                strokeColor: lineColor, //[1, 0, 0],
                                strokeWidth: (this.bleedY / 2) * height
                            },
                            {
                                x1: x,
                                x2: x + width,
                                y1: y + height - ((this.bleedY / 2) * height) / 2,
                                y2: y + height - ((this.bleedY / 2) * height) / 2,
                                strokeColor: lineColor,
                                strokeWidth: (this.bleedY / 2) * height
                            },
                            {
                                x1: x + ((this.bleedX / 2) * width) / 2, // (this.bleedX*width)/2
                                x2: x + ((this.bleedX / 2) * width) / 2,
                                y1: y,
                                y2: y + height,
                                strokeColor: lineColor,
                                strokeWidth: (this.bleedY / 2) * height
                            },
                            {
                                x1: x + width - ((this.bleedX / 2) * width) / 2,
                                x2: x + width - ((this.bleedX / 2) * width) / 2,
                                y1: y,
                                y2: y + height,
                                strokeColor: lineColor,
                                strokeWidth: (this.bleedY / 2) * height
                            },
                            ...shapes.interfaceShapes
                        ]
                    }

                    // return updated shapes
                    return {
                        // copy props from shapes param
                        ...shapes,
                        interfaceShapes: interfaceShapes_,
                    }
                }
            },

            // inline state
            inlineSrc: this.src
        }
    },
    computed: {
        ...mapState({
            current_bleed: state => state.cart.current_edge_color
        }),
        ready() {
            return this.src && this.ratio
        }
    },
    methods: {
        updateImage(cb) {
            let file1, file2;
            const {
                height,
                width,
                x,
                y
            } = this.$refs.doka.editor.imageCrop

            if ( !this.specialBorder ) {
                this.$refs.doka.editor.imageCropMinSize = {
                    height: 1,
                    width: 1
                }
            }

            this.$refs.doka.editor
                .processImage()
                .then(write_output => {
                    file1 = write_output.dest
                    this.$store.commit('cart/setCurrentImageFullSize', {
                        width: width,
                        height: height
                    })

                    if ( this.specialBorder ) {
                        this.$refs.doka.editor
                        .processImage()
                        .then(write_output => {
                            file2 = write_output.dest
                            if (cb) cb(file1, file2)
                        })
                    } else {
                        this.$refs.doka.editor
                        .processImage({
                            imageCrop: {
                                x: x + (this.bleedX / 2) * width,
                                y: y + (this.bleedY / 2) * height,
                                width: width - (this.bleedX / 2) * width * 2,
                                height: height - (this.bleedY / 2) * height * 2
                            }
                        })
                        .then(write_output => {
                            file2 = write_output.dest
                            this.$refs.doka.editor.imageCropMinSize = computeMinSize(
                                this.width,
                                this.height
                            )
                            this.$refs.doka.editor.imageCrop = {
                                x: x,
                                y: y,
                                width: width,
                                height: height
                            }
                            if (cb) cb(file1, file2)
                        })
                    }
                })
        },
        moveTo(x) {
            moveTo(x)
        },
        resetApplication() {
            this.$store.commit('cart/setCurrentImage', null);
            this.$store.dispatch('cart/setCurrentOutputImage', null);
            moveTo(1);
        }
    }
}
</script>

<style>
@import '../../node_modules/pintura/pintura.css';

.pintura-editor {
    max-height: 40em;
    margin: 0 auto;

    --color-background: 30, 30, 30;
    --color-foreground: 255, 255, 255;
    box-shadow: 0 0 0 1px #eee;

    /* https://pqina.nl/pintura/docs/v8/api/style/ */
    --color-primary: #2990ff;
    --color-primary-dark: #1a80ec;
    --color-primary-text: #fff;
    --color-secondary: #03a9f4;
    --color-secondary-dark: #046bbf;
}

.PinturaRoot[data-env~='landscape']>.PinturaNavMain button {
    width: 5rem;
    height: 5rem;
}

.hasSpecialBorder .PinturaUtilHeader {
    margin-bottom: 1.5rem;
}

.hasSpecialBorder .PinturaUtilFooter {
    margin-top: 1.5rem;
}
</style>
