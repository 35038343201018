<template>
<b-overlay :show="addingToBasket" rounded="sm">
    <b-container class="container section-lines" fluid>
        <SideLine title="Vorschau" />
        <div class="container-inner mx-auto">
            <h2 class="title mb-6 md:mb-8 lg:mb-12">Vorschau</h2>
            <b-row class="text-left items-end">
                <b-col>
                    <div class="preview-image-container w-full relative mb-8 md:mb-10 lg:mb-14">
                        <img id="preview-background" class="preview-background w-full" :src="getImage('vorschau_mockup')" :class="current_size">
                        <img id="preview-chair" class="preview-chair" :src="getImage('preview_table')" :class="current_size">
                        <img id="preview-plant" class="preview-plant" :src="getImage('preview_plant')" :class="current_size">
                        <img id="preview-image" style="width: 200px; height: " class="preview-overlay absolute left-1/2" :style="setPreviewImage" :src="current_image" v-if="current_image" />
                    </div>
                </b-col>
            </b-row>
            <b-row class="text-right items-end">
                <b-col>
                    <div class="preview-buttons flex justify-center md:block">
                        <b-button @click="goBack" class="btn mr-3 btn-hollow btn-mobile">Zurück</b-button>
                        <b-button @click="addItemToBasket" class="btn btn-primary btn-mobile" variant="primary" :disabled="current_image == ''">In den Warenkorb</b-button>
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-container>
</b-overlay>
</template>

<script>
import {
    mapState,
    mapActions
} from 'vuex'
import SideLine from '../components/SideLine.vue'
import {
    moveTo,
    setAllowScrolling
} from '@/utils/fullpage'

export default {
    name: 'PreviewSection',
    props: {
        msg: String
    },
    components: {
        SideLine
    },
    computed: {
        ...mapState({
            current_image(state) {
                if ((!state.cart.current_output_image || state.cart.current_output_image.includes("blob")) && this.afterRefresh) {
                    this.afterRefresh = false;
                    return "";
                } else {
                    this.afterRefresh = false;
                    return state.cart.current_output_image;
                }
            },
            current_size(state) {
                if (state.cart.current_size && this.previewImageSize.width == 0) this.recalculatePreviewImage(state.cart.current_size)
                return "";
            },
            image_file: state => state.cart.current_image_file
        }),
        setPreviewImage() {            
            if(this.previewImageSize.height) {           
                let img = require(`../assets/vorschau_mockup.png`),
                //imageWidth = this.previewImageSize.width,
                imageWidth = 'auto',
                imageHeight = this.previewImageSize.height,
                wallHeight = document.getElementById("preview-background").height,
                calculation = (wallHeight / 2) - (imageHeight.slice(0,-2) / 2) - (wallHeight / 10)
                return {
                    'width':imageWidth, 
                    'height': this.previewImageSize.height,
                    'top': calculation + 'px',
                }
             } else {
                 return null
             }
        }

    },
    data() {
        return {
            previewImageSize: {
                width: 0,
                height: 0
            },
            afterRefresh: true,
            addingToBasket: false
        };
    },
    mounted() {
        this.$root.$on('recalculatePreviewSize', size => {
            this.recalculatePreviewImage(size)
        });
    },
    methods: {
        getImage(path) {
            var img = require(`../assets/${path}.png`)
            return img
        },
        ...mapActions({
            addToBasket: 'cart/addToBasket',
        }),
        moveTo(x) {
            moveTo(x)
        },
        goBack() {
            this.$store.dispatch('wizard/updateWizard', {
                index: 2,
                value: 0
            });
            moveTo(4);
        },
        addItemToBasket() {
            this.addingToBasket = true;
            this.$store.dispatch('cart/uploadUpscaledImage', () => {
                this.addToBasket((data) => {
                    this.$store.dispatch('cart/addFinalImage', data.data).then(() => {
                        this.$store.dispatch('cart/updateBasketItems', data).then(() => {
                            console.log("done");
                            this.addingToBasket = false;
                            this.$store.dispatch('wizard/updateWizard', {
                                index: 3,
                                value: 1
                            });
                            moveTo(6);
                            //setAllowScrolling(false,'up')
                        });
                    }) 
                });
            });
        },
        recalculatePreviewImage(currentSize) {
            if (document.getElementById("preview-background")) {
                let wall_w_ratio = (document.getElementById("preview-background").width * 0.76) / 380,
                    wall_h_ratio = (document.getElementById("preview-background").height * 0.83) / 220;

                this.previewImageSize.width = Math.trunc((currentSize.width * wall_w_ratio)) + "px";
                this.previewImageSize.height = Math.trunc((currentSize.height * wall_h_ratio)) + "px";
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
.container {
    background-color: #E9E9E9;
    color: #58585A;

    @media (max-width: 991px) {
        min-height: unset;
    }

    .container-inner {
        max-width: 1600px;

        @media (max-width: 1600px) {
            padding-top: 50px;
            padding-bottom: 70px;
            padding-right: 90px;
            padding-left: 90px;
        }

        @media (max-width: 1200px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        @media (max-width: 991px) {
            padding-top: 40px;
            padding-right: 0;
            padding-left: 0;
        }

        .preview-overlay {
            transform: translateX(-35%);
            z-index:5;
            box-shadow: rgba(50, 50, 93, 0.25) -3px 6px 12px -2px, rgba(0, 0, 0, 0.3) -3px 3px 7px -3px;
        }
        .preview-chair {
            position:absolute;
            z-index:10;
            top:52.6%;
            left:7.9%;
            width:34.7%;
        }
         .preview-plant {
            position:absolute;
            width:auto;
            z-index:10;
            top:25.6%;
            right:4.2%;
            height:60.8%;
        }


        .preview-buttons {
            .btn {
                &:disabled {
                    pointer-events: none;
                }
            }
        }
    }
}
</style>
