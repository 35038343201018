<template>
    <b-overlay :show="processing" rounded="sm">
        <b-container id="cart-section" class="container section-lines" fluid>
            <SideLine title="Warenkorb" />

            <div class="container-inner">
                <h2 class="title sm:text-center lg:text-left mb-3.5 md:mb-12 pb-0.5">Warenkorb</h2>

                <b-row class="cart-row text-left cart-headings no-border hidden md:flex">
                    <b-col class="cart-col cart-smaller-col" cols="3" xl="2">
                        <p class="font-bold">Vorschau</p>
                    </b-col>

                    <b-col class="cart-col" cols="4" xl="3">
                        <p class="font-bold">Produkt</p>
                    </b-col>

                    <b-col class="cart-col" cols="3">
                        <p class="font-bold">Anzahl</p>
                    </b-col>

                    <b-col class="cart-col hidden xl:flex" cols="2">
                        <p class="font-bold">Preis pro Stück</p>
                    </b-col>

                    <b-col class="cart-col mr-0 ml-auto text-right" cols="2">
                        <p class="font-bold">Preis</p>
                    </b-col>
                </b-row>

                <div class="cart-items" v-for="item in cart_items" :key="item.id">
                    <b-row class="cart-row text-left py-3 hidden md:flex">
                        <b-col class="cart-col cart-smaller-col" cols="3" xl="2">
                            <img :src="item.image" />
                        </b-col>

                        <b-col class="cart-col cart-product-info flex justify-center flex-col" cols="4" xl="3">
                            <p>{{ item.title }}</p>
                            <p>{{ formatText(item.text, 0) }}</p>
                            <p>{{ formatText(item.text, 1) }}</p>
                            <p>{{ formatText(item.text, 2) }}</p>
                            <p>{{ item.width }} x {{ item.height }} cm</p>
                        </b-col>

                        <b-col class="cart-col cart-item-quantity flex align-items-center flex-wrap flex-row 2xl:flex-col" cols="3">
                            <div class="flex align-items-center w-full 2xl:w-auto">
                                <span class="inline-flex quantity-minus quantity-icon rounded-full justify-center align-items-center cursor-pointer pb-1" @click="decreaseCount(item)">-</span>
                                <span class="inline-block quantity mx-1.5 lg:mx-2.5">{{ item.count }} Stück</span>
                                <span class="inline-flex quantity-plus quantity-icon rounded-full justify-center align-items-center cursor-pointer 2xl:mr-4 pb-1" @click="increaseCount(item)">+</span>
                            </div>

                            <div class="flex align-items-center pl-8 lg:ml-1 2xl:ml-0 lg:pl-12 2xl:pl-0 md:-mt-6 xl:-mt-4 2xl:mt-0">
                                <span class="inline-block quantity-remove cursor-pointer mr-3.5" @click="removeItemFromBasket(item)">
                                    <img src="../assets/svg/icon-cart-bin.svg" />
                                </span>
                            </div>
                        </b-col>

                        <b-col class="cart-col align-items-center cart-price hidden xl:flex" cols="2">
                            <p>CHF {{ roundPrice(Math.round(item.price * 100) / 100, 2) }}</p>
                        </b-col>
                        <b-col class="cart-col flex align-items-center justify-end mr-0 ml-auto cart-price" cols="2">
                            <p>CHF {{ roundPrice(item.price * item.count, 2) }}</p>
                        </b-col>
                    </b-row>

                    <b-row class="cart-row text-left py-3 flex md:hidden">
                        <b-col class="cart-col cart-item-quantity" cols="12">
                            <div class="cart-mobile-product-info flex">
                                <div class="cart-mobile-image flex-1 pr-1.5">
                                    <img :src="item.image" />
                                </div>

                                <div class="cart-mobile-info-inside flex-1 pl-1.5">
                                    <p>{{ item.title }}</p>
                                    <p>{{ formatText(item.text, 0) }}</p>
                                    <p>{{ formatText(item.text, 1) }}</p>
                                    <p>{{ formatText(item.text, 2) }}</p>
                                    <p>{{ item.width }} x {{ item.height }} cm</p>

                                    <div class="flex align-items-center w-full 2xl:w-auto mt-3">
                                        <span class="inline-flex quantity-minus quantity-icon rounded-full justify-center align-items-center cursor-pointer pb-1" @click="decreaseCount(item)">-</span>
                                        <span class="inline-block quantity mx-1.5 lg:mx-2.5">{{ item.count }} Stück</span>
                                        <span class="inline-flex quantity-plus quantity-icon rounded-full justify-center align-items-center cursor-pointer 2xl:mr-4 pb-1" @click="increaseCount(item)">+</span>
                                    
                                        <span class="inline-block quantity-remove cursor-pointer ml-3 mr-3.5" @click="removeItemFromBasket(item)">
                                            <img src="../assets/svg/icon-cart-bin.svg" />
                                        </span>
                                    </div>
                                    <p class="item-price mt-4">Preis: CHF {{ roundPrice(item.price * item.count, 2) }}</p>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>

                <b-row class="cart-row text-left cart-coupon-row">
                    <b-col class="cart-col cart-smaller-col hidden lg:flex" cols="3" xl="2"></b-col>

                    <b-col class="cart-col" cols="12" md="4" lg="3">
                        <p class="cart-coupon-row-header font-bold 2xl:pt-2">Gutschein einlösen</p>
                    </b-col>

                    <b-col class="cart-col cart-coupon text-right md:text-left" cols="12" md="6" lg="4" xl="3">
                        <b-form-input v-model="coupon" placeholder="Gutscheincode" class="cart-input-coupon text-center mb-4 md:mb-2.5"></b-form-input>
                        <p id="coupon-error" class="text-left">
                            <span v-if="discount.value != 0">Bezeichnung: {{ discount.name }}</span>
                        </p>
                        <div class="flex items-center cart-coupon-wrapper">
                            <a @click.prevent="addCoupon()" class="btn btn-secondary mt-3 md:mt-3 inline-block 2xl:hidden btn-mobile">Hinzufügen</a>
                            <span class="remove-coupon cursor-pointer inline-block 2xl:hidden mt-3 md:mt-3 ml-3.5" @click="removeCoupon()" v-if="discount.name">
                                <img src="../assets/svg/icon-cart-bin.svg" />
                            </span>
                        </div>
                    </b-col>

                    <b-col class="cart-col hidden xl:block" cols="2">
                        <div class="flex items-center cart-coupon-wrapper">
                            <a @click.prevent="addCoupon()" class="btn btn-secondary hidden 2xl:inline-block btn-mobile">Hinzufügen</a>
                            <span class="remove-coupon cursor-pointer hidden 2xl:inline-block ml-3.5" @click="removeCoupon()" v-if="discount.name">
                                <img src="../assets/svg/icon-cart-bin.svg" />
                            </span>
                        </div>
                    </b-col>

                    <b-col class="cart-col text-right mr-0 ml-auto cart-price hidden md:block" cols="2">
                        <p class="pt-2" v-if="discount.value != 0">CHF -{{ roundPrice(discount.value, 2) }}</p>
                    </b-col>
                </b-row>

                <b-row class="cart-row text-left cart-shipping-row no-border pt-6 md:pt-3 md:mt-1 pb-3">
                    <b-col class="cart-col cart-smaller-col hidden lg:flex" cols="3" xl="2"> </b-col>

                    <b-col class="cart-col flex" cols="9" md="8" lg="4" xl="3">
                        <input type="radio" name="some-radios" v-model="selected_shipping_method" value="mail" id="input-shipping-1" class="custom-radio" />
                        <label for="input-shipping-1" class="custom-radio-label">Versand</label>
                    </b-col>

                    <b-col class="cart-col hidden lg:flex" cols="3"> </b-col>

                    <b-col class="cart-col hidden xl:flex" cols="2"> </b-col>

                    <b-col class="cart-col text-right mr-0 ml-auto shipping-cost flex justify-end align-items-center cart-price" cols="3" md="2" lg="2" xl="2">
                        <p v-if="selected_shipping_method == 'mail'">CHF {{ roundPrice(shipping_costs, 2) }}</p>
                    </b-col>
                </b-row>

                <b-row class="cart-row text-left cart-shipping-row pb-6 md:pb-3">
                    <b-col class="cart-col cart-smaller-col hidden lg:flex" cols="3" xl="2"> </b-col>

                    <b-col class="cart-col flex" cols="10" md="8" lg="4" xl="3">
                        <input type="radio" name="some-radios" v-model="selected_shipping_method" value="visit" id="input-shipping-2" class="custom-radio" />
                        <label for="input-shipping-2" class="custom-radio-label">Abholung in unserer Filiale <small class="pl-2">(Eschenbach LU)</small></label>
                    </b-col>

                    <b-col class="cart-col hidden lg:flex" cols="3"> </b-col>

                    <b-col class="cart-col hidden xl:flex" cols="2"> </b-col>

                    <b-col class="cart-col text-right mr-0 ml-auto shipping-cost flex justify-end align-items-center cart-price" cols="2">
                        <p v-if="selected_shipping_method == 'visit'">Gratis</p>
                    </b-col>
                </b-row>

                <div class="cart-sub-total text-right pt-8 md:pt-7 mb-10 md:mb-8 2xl:mb-0">
                    <div class="total-wrapper flex justify-between md:inline-block pb-3.5">
                        <p class="font-bold inline-block">Nettobetrag</p>
                        <p class="font-bold inline-block md:ml-20">
                            {{ roundPrice(total_price - discount.value - (total_price - discount.value) * 0.077, 2) }}
                        </p>
                    </div>
                </div>
                <div class="cart-sub-total text-right pt-0 md:pt-0 mb-10 md:mb-4 2xl:mb-4">
                    <div class="total-wrapper flex justify-between md:inline-block pb-3.5">
                        <p class="font-bold inline-block">MwSt 7.7%</p>
                        <p class="font-bold inline-block md:ml-20">
                            {{ roundPrice((total_price - discount.value) * 0.077, 2) }}
                        </p>
                    </div>
                </div>
                <div class="cart-total text-right pt-0 md:pt-4 mb-10 md:mb-8 2xl:mb-14">
                    <div class="total-wrapper flex justify-between md:inline-block pb-3.5">
                        <p class="font-bold inline-block">Total</p>
                        <p class="font-bold inline-block md:ml-20">CHF {{ roundPrice(total_price - discount.value, 2) }}</p>
                    </div>
                </div>

                <div class="cart-comment text-right">
                    <div class="inline-block cart-comment-wrapper text-left w-full">
                        <p class="font-bold mb-2 md:mb-3.5">Bemerkungen</p>
                        <b-form-textarea id="textarea" v-model="comment" placeholder="Teilen Sie uns Ihr Anliegen mit" class="comment-textarea mb-8 2xl:mb-16" no-resize></b-form-textarea>
                    </div>
                </div>

                <div class="cart-buttons text-right flex flex-col justify-center md:block">
                    <a @click.prevent="uploadNewImage()" class="btn md:mr-3 btn-hollow btn-mobile btn-mobile-big"> Weitere Bilder bestellen </a>
                    <a @click.prevent="updateCart()" class="btn mb-0 btn-primary btn-mobile"> Zur Anschrift </a>
                </div>
            </div>
        </b-container>
    </b-overlay>
</template>

<script>
import SideLine from '../components/SideLine.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { moveTo,setAllowScrolling } from '@/utils/fullpage'

export default {
    name: 'CartSection',
    props: {
        msg: String
    },
    components: {
        SideLine
    },
    data() {
        return {
            selected_shipping_method: 'mail',
            coupon: '',
            comment: '',
            processing: false
        }
    },
    watch: {
        selected_shipping_method() {
            this.$store.dispatch('cart/setShippingMethod', this.selected_shipping_method)
            this.updateCart(false)
        },
        comment(val) {
            this.$store.dispatch('cart/setNote', val)
        }
    },
    computed: {
        ...mapState({
            cart_items: (state) => state.cart.cart_items,
            cart_items_count: (state) => state.cart.cart_items.count,
            shipping_costs: (state) => state.cart.cart_costs.shipping,
            price_tax: (state) => state.cart.cart_costs.tax,
            discount: (state) => state.cart.discount
        }),
        ...mapGetters({
            total_price: 'cart/totalPrice',
            total_items: 'cart/totalItems'
        })
    },
    methods: {
        moveTo,
        ...mapActions({
            removeFromBasket: 'cart/removeFromBasket'
        }),
        uploadNewImage() {
            moveTo(1)
            setAllowScrolling(true)
            this.$store.dispatch('wizard/updateWizard', {
                index: 0,
                value: 0
            })
        },
        increaseCount(item) {
            item.count++;
            this.updateCart(false);
        },
        decreaseCount(item) {
            (item.count > 1) ? item.count-- : this.removeItemFromBasket(item)
            this.updateCart(false);
        },
        updateCart(moveToNextSection = true) {
            this.processing = true
            this.$store.dispatch('cart/updateBasketCount', (data) => {
                this.$store.dispatch('cart/updateBasketItems', data).then(() => {
                    this.processing = false

                    this.addCoupon();

                    if (moveToNextSection) {
                        this.$store.dispatch('cart/setComment', this.comment)
                        this.$store.dispatch('wizard/updateWizard', {
                            index: 4,
                            value: 1
                        })
                        moveTo(7)
                       // setAllowScrolling(false)
                    }
                })
            })
        },
        removeItemFromBasket(item) {
            this.processing = true
            this.removeFromBasket({
                item: item,
                cb: () => {
                    this.processing = false
                    if (this.cart_items && !this.cart_items.length) moveTo(4)
                }
            })
        },
        removeCoupon() {
            this.processing = true
            this.$store.dispatch('cart/removeCoupon', () => {
                this.coupon = ''
                this.$store.commit('cart/updateDiscount', {
                    value: 0,
                    name: '',
                    enteredName: ''
                })
                document.getElementById('coupon-error').innerHTML = ''
                this.processing = false
            })
        },
        addCoupon() {
            if (this.discount.enteredName.toLowerCase() != this.coupon.toLowerCase()) {
                this.$store.dispatch('cart/applyCoupon', {
                    coupon: this.coupon,
                    cb: (data) => {
                        if (data.data.message) {
                            document.getElementById('coupon-error').innerHTML = '<span style="color: red;">Gutschein nicht gefunden oder ungültig!</span>'
                        } else {
                            let discountName = '',
                                discountValue = ''
                            data.data.positionen.forEach((elt) => {
                                if (!elt.auto && elt.fixed) {
                                    discountName = elt.bez
                                    discountValue = Math.abs(elt.preise.gesamt_brutto)
                                }
                            })
                            document.getElementById('coupon-error').innerHTML = 'Bezeichnung: ' + discountName
                            //console.log(this.total_price, data.data.preise.brutto)
                            this.$store.commit('cart/updateDiscount', {
                                value: discountValue,
                                name: discountName,
                                enteredName: this.coupon
                            })
                        }
                    }
                })
            }
        },
        formatText(text, index) {
            return text.split(', ')[index]
        },
        roundPrice(number, decimal) {
            return parseFloat(number).toFixed(decimal)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
#cart-section {
    &.container {
        background: #f2f2f2;
        color: #58585a;

        .container-inner {
            padding: 40px 170px;

            @media (max-width: 1600px) {
                padding: 40px 90px;
            }

            @media (max-width: 1200px) {
                padding: 40px;
            }

            @media (max-width: 991px) {
                padding: 40px 0 70px;
            }

            .cart-row {
                margin-left: 0px;
                margin-right: 0px;
                border-bottom: 1px solid #707070;

                &.no-border {
                    border-bottom: none;
                }

                &.cart-headings {
                    font-size: 1.125rem;
                    line-height: 1.25rem;
                }

                &.cart-coupon-row {
                    padding-top: 1.5rem;
                    padding-bottom: 1rem;
                    font-size: 1.125rem;
                    line-height: 1.25rem;

                    @media (max-width: 768px) {
                        padding-top: 1.25rem;
                    }

                    .cart-coupon-row-header {
                        @media (max-width: 768px) {
                            padding-bottom: 1rem;
                        }
                    }

                    .cart-coupon {
                        input {
                            font-family: 'Titillium Web';
                            font-size: 1.125rem;
                            line-height: 1.563rem;
                            padding: 0.35rem 0.75rem;
                            height: unset;
                            border-radius: 19px;
                            color: #58585a;
                            border: none;
                        }

                        ::placeholder {
                            color: #bcbcbc;
                        }

                        p {
                            color: #58585a;
                            font-size: 0.938rem;
                            line-height: 1.25rem;

                            @media (max-width: 768px) {
                                font-size: 0.813rem;
                            }
                        }

                        .btn {
                            min-width: unset;
                            width: 100%;
                            margin-bottom: 0;

                            @media (max-width: 768px) {
                                margin-bottom: 1.5rem;
                            }
                        }

                        .remove-coupon {
                            @media (max-width: 768px) {
                                margin-bottom: 1.5rem;
                            }
                        }
                    }

                    .cart-coupon-wrapper {
                        .btn {
                            min-width: 11.5rem;
                        }

                        .remove-coupon {
                            flex-shrink: 0;
                        }
                    }
                }

                &.cart-shipping-row {
                    .custom-radio-label {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom: 0;
                        align-items: center;
                        cursor: pointer;
                        color: #58585a;
                        margin-left: 35px;
                        font-size: 1.125rem;
                        line-height: 1.563rem;

                        @media (max-width: 991px) {
                            font-size: 1rem;
                            line-height: 1.45rem;
                        }

                        @media (max-width: 768px) {
                            margin-left: 30px;
                        }

                        &::before {
                            content: '';
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            border: 1px solid #707070;
                            border-radius: 50%;
                            position: absolute;
                            left: 10px;

                            @media (max-width: 768px) {
                                left: 0;
                            }
                        }
                    }

                    .custom-radio {
                        appearance: none;
                        display: none;
                    }

                    .custom-radio:checked + .custom-radio-label {
                        &::after {
                            content: '';
                            display: inline-block;
                            height: 16px;
                            width: 16px;
                            border-radius: 50%;
                            position: absolute;
                            left: 14px;
                            top: 50%;
                            transform: translateY(-50%);
                            background: #f7a600;

                            @media (max-width: 768px) {
                                left: 4px;
                            }
                        }
                    }

                    .shipping-cost {
                        font-size: 1.125rem;
                        line-height: 1.25rem;
                    }
                }

                .cart-col {
                    padding-left: 10px;
                    padding-right: 10px;

                    @media (max-width: 768px) {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    &.cart-smaller-col {
                        max-width: 245px;
                    }

                    &.cart-product-info {
                        font-size: 1.125rem;
                        line-height: 1.563rem;

                        @media (max-width: 1199px) {
                            font-size: 1rem;
                            line-height: 1.45rem;
                        }
                    }

                    &.cart-item-quantity {
                        .quantity-icon {
                            width: 39px;
                            height: 39px;
                            border: 1px solid #707070;
                            font-size: 1.25rem;
                            transition: all 0.2s ease;

                            @media (max-width: 991px) {
                                width: 27px;
                                height: 27px;
                            }

                            &:hover {
                                color: #fff;
                                background-color: rgb(112, 112, 112);
                            }
                        }

                        .quantity-remove,
                        .quantity-edit {
                            @media (max-width: 1600px) {
                                max-width: 20px;
                            }
                        }

                        .quantity {
                            font-size: 1.125rem;
                            line-height: 1.563rem;

                            @media (max-width: 991px) {
                                font-size: 1rem;
                                line-height: 1.45rem;
                            }
                        }
                    }

                    &.cart-price {
                        font-size: 1.125rem;
                        line-height: 1.563rem;

                        @media (max-width: 991px) {
                            font-size: 1rem;
                            line-height: 1.45rem;
                        }
                    }

                    .cart-mobile-product-info {
                        font-size: 0.938rem;
                        line-height: 1.25rem;
                    }
                }
            }

            .cart-total {
                font-size: 1.125rem;
                line-height: 1.25rem;

                .total-wrapper {
                    border-bottom: 6px double #707070;
                }
            }

            .cart-comment {
                font-size: 1.125rem;
                line-height: 1.25rem;

                .cart-comment-wrapper {
                    max-width: 29.5rem;

                    .comment-textarea {
                        padding: 0.813rem 1rem;
                        border: none;
                        min-height: 140px;

                        @media (max-width: 768px) {
                            border-radius: 14px;
                        }
                    }

                    ::placeholder {
                        color: #bcbcbc;
                    }
                }
            }

            .cart-buttons {
                .btn {
                    &:first-child {
                        @media (max-width: 768px) {
                            margin-bottom: 1.25rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
