<template>
    <div class="side-menu-wrapper relative h-full">
        <!-- content when user is not logged in -->
        <div class="side-menu-not-logged" v-if="!user_logged_in">
            <div class="side-menu-top pt-6 md:pt-7 pb-10 md:pb-11 px-9 md:px-14 text-right">
                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" version="1.1" class="side-menu-close mb-9 inline-block cursor-pointer" @click="$emit('close-menu')">
                    <g stroke="rgb(255, 255, 255)" stroke-width="2">
                        <line x1="0" y1="0" x2="20" y2="20" />
                        <line x1="0" y1="20" x2="20" y2="0" />
                    </g>
                </svg>

                <h2 id="side-menu-main-heading" class="heading font-bold">
                    <span v-if="!show_reset_password">Login</span>
                    <span v-else>Reset Password</span>
                </h2>

                <!-- show after register  -->
                <div id="side-menu-after-register-heading" class="hidden after-register text-right ml-auto">
                    <h2 class="heading font-bold mb-4">
                        Herzlichen Dank<br />
                        für deine Registrierung
                    </h2>
                    <p class="after-register-info text-lg md:pb-3">In wenigen Minuten erhältst du von uns eine automatische E-Mail mit Benutzername und Passwort.</p>
                </div>
                <!-- ************** -->
            </div>

            <Accordion v-if="!show_reset_password">
                <Accordion-item>
                    <template slot="accordion-trigger">
                        <h3 class="m-0">Anmelden</h3>
                    </template>
                    <template slot="accordion-content">
                        <div class="pt-8 pb-3 side-menu-nav">
                            <p class="login-info text-left md:text-center">Melde dich mit deinem Posterkönig-Konto an.</p>

                            <b-form id="side-menu-login-form">
                                <div class="form-fields form-login">
                                    <div class="form-item">
                                        <label label-for="login-input-1">E-Mail</label>
                                        <b-form-input id="login-input-1" v-model="login.email" type="email" placeholder="E-Mail" required class="input-side-menu"></b-form-input>
                                    </div>

                                    <div class="form-item">
                                        <label label-for="login-input-2">Passwort</label>
                                        <b-form-input id="login-input-2" v-model="login.password" type="password" placeholder="Passwort" required class="input-side-menu"></b-form-input>
                                    </div>
                                </div>

                                <span class="lost-password block text-left mb-8 md:mb-3 cursor-pointer text-xs" @click="show_forget_password = true">Passwort vergessen?</span>

                                <span id="login-error" class="hidden text-right text-red-500 pt-1 pb-3 text-lg">Ihre Login Daten sind nicht korrekt. Bitte überprüfen sie diese nochmals.</span>

                                <b-button @click.prevent="userLogin()" type="submit" class="submit-login-btn ml-auto" variant="primary" block v-if="!show_forget_password">Anmelden</b-button>
                            </b-form>

                            <transition name="fade">
                                <b-form id="side-menu-forget-password-form" v-if="show_forget_password">
                                    <div class="form-fields form-login">
                                        <div class="form-item">
                                            <label label-for="reset-password-input-1">E-Mail</label>
                                            <b-form-input id="reset-password-input-1" v-model="forget_password.email" type="email" placeholder="E-Mail" required class="input-side-menu"></b-form-input>
                                        </div>
                                    </div>

                                    <div class="button-tab-1-wrapper flex items-center justify-end buttons-forget-password">
                                        <div class="spinner" :class="{ visible: spinnerVisible }"></div>
                                        <b-button @click.prevent="show_forget_password = false" class="submit-login-btn" variant="secondary" block>Abbrechen</b-button>
                                        <b-button @click.prevent="forgetPassword()" type="submit" class="submit-login-btn ml-3 mt-0" variant="primary" block>Passwort zurücksetzen</b-button>
                                    </div>
                                </b-form>
                            </transition>

                            <span id="forget-password-error" class="hidden text-red-500 text-xl mt-4"></span>
                        </div>
                    </template>
                </Accordion-item>

                <Accordion-item id="accordion-register-form">
                    <template slot="accordion-trigger">
                        <h3 class="m-0">Registrieren</h3>
                    </template>
                    <template slot="accordion-content">
                        <div class="pt-8 pb-3 side-menu-nav">
                            <b-form id="side-menu-register-form">
                                <div class="form-fields">
                                    <div class="form-item">
                                        <label label-for="register-input-0">Anrede</label>
                                        <b-form-select id="register-input-0" v-model="account.salutation" :options="['Herr', 'Frau', 'Firma']" class="input-side-menu"></b-form-select>
                                    </div>

                                    <div class="form-item" v-if="account.salutation == 'Firma'">
                                        <label label-for="register-input-1">Firma</label>
                                        <b-form-input id="register-input-1" v-model="account.company" type="text" placeholder="Firma" class="input-side-menu"></b-form-input>
                                    </div>

                                    <div class="form-item">
                                        <label label-for="register-input-2">Vorname*</label>
                                        <b-form-input id="register-input-2" v-model="account.surname" type="text" placeholder="Vorname" required class="input-side-menu"></b-form-input>
                                    </div>

                                    <div class="form-item">
                                        <label label-for="register-input-3">Nachname*</label>
                                        <b-form-input id="register-input-3" v-model="account.name" type="text" placeholder="Nachname" required class="input-side-menu"></b-form-input>
                                    </div>

                                    <div class="form-item">
                                        <label label-for="register-input-4">Adresszusatz</label>
                                        <b-form-input id="register-input-4" v-model="account.address" type="text" placeholder="Adresszusatz" class="input-side-menu"></b-form-input>
                                    </div>

                                    <div class="form-item">
                                        <label label-for="register-input-5">Strasse*</label>
                                        <b-form-input id="register-input-5" v-model="account.street" type="text" placeholder="Strasse" required class="input-side-menu"></b-form-input>
                                    </div>

                                    <div class="form-two-items flex">
                                        <div class="form-item">
                                            <label label-for="register-input-6">PLZ*</label>
                                            <b-form-input id="register-input-6" v-model="account.postal_code" type="text" placeholder="PLZ" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-input-7">Ort*</label>
                                            <b-form-input id="register-input-7" v-model="account.city" type="text" placeholder="Ort" required class="input-side-menu"></b-form-input>
                                        </div>
                                    </div>

                                    <div class="form-item">
                                        <label label-for="register-input-8">Telefon*</label>
                                        <b-form-input id="register-input-8" v-model="account.telephone" type="tel" placeholder="Telefon" required class="input-side-menu"></b-form-input>
                                    </div>

                                    <div class="form-item">
                                        <label label-for="register-input-9">E-Mail*</label>
                                        <b-form-input id="register-input-9" v-model="account.email" type="email" placeholder="E-Mail" required class="input-side-menu"></b-form-input>
                                    </div>

                                    <div class="form-item">
                                        <label label-for="register-input-10">Passwort</label>
                                        <b-form-input id="register-input-10" v-model="account.password" type="password" placeholder="Passwort" required class="input-side-menu"></b-form-input>
                                    </div>

                                    <div class="form-item">
                                        <label label-for="register-input-11">Passwort wiederholen</label>
                                        <b-form-input id="register-input-11" v-model="account.password_repeat" type="password" placeholder="Passwort wiederholen" required class="input-side-menu"></b-form-input>
                                    </div>
                                </div>

                                <!--<p class="register-info text-lg mt-6 mb-4 md:ml-28 text-left">Wir senden Benutzernamen und Passwort an deine E-Mail Adresse.</p>-->

                                <span id="register-error" class="hidden text-right text-red-500 pb-3 text-lg"></span>

                                <b-button @click.prevent="userRegister()" type="submit" class="submit-login-btn ml-auto" variant="primary" block>Registrieren</b-button>
                            </b-form>
                        </div>
                    </template>
                </Accordion-item>
            </Accordion>

            <div class="side-menu-nav px-8" v-if="show_reset_password">
                <b-form id="side-menu-new-password-form">
                    <div class="form-fields form-login">
                        <div class="form-item">
                            <label label-for="new-password-input-1">Neues Passwort</label>
                            <b-form-input id="new-password-input-1" v-model="reset_password.new_password" type="password" placeholder="Neues Passwort" required class="input-side-menu"></b-form-input>
                        </div>

                        <div class="form-item">
                            <label label-for="new-password-input-2">Neues Passwort wiederholen</label>
                            <b-form-input id="new-password-input-2" v-model="reset_password.new_password_repeat" type="password" placeholder="Neues Passwort wiederholen" required class="input-side-menu"></b-form-input>
                        </div>
                    </div>

                    <div class="button-tab-1-wrapper flex items-center justify-end">
                        <div class="spinner" :class="{ visible: spinnerVisible }"></div>
                        <b-button @click.prevent="newPassword()" type="submit" class="submit-login-btn ml-3" variant="primary" block>Passwort zurücksetzen</b-button>
                    </div>
                </b-form>

                <span id="new-password-error" class="hidden text-red-500 text-xl mt-4"></span>
            </div>
        </div>
        <!-- ********** -->

        <!-- content when user is logged in -->
        <div class="side-menu-logged" v-if="user_logged_in">
            <div class="side-menu-logged-main">
                <div class="side-menu-top pt-6 md:pt-7 pb-10 md:pb-11 px-9 md:px-14 text-right">
                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" version="1.1" class="side-menu-close mb-9 inline-block cursor-pointer" @click="$emit('close-menu')">
                        <g stroke="rgb(255, 255, 255)" stroke-width="2">
                            <line x1="0" y1="0" x2="20" y2="20" />
                            <line x1="0" y1="20" x2="20" y2="0" />
                        </g>
                    </svg>

                    <h2 class="heading font-bold">
                        <span v-if="user_data.name">Hallo {{ user_data.surname }} {{ user_data.name }}</span>
                        <span v-else>Hallo {{ user_data.company }}</span>
                    </h2>
                </div>

                <div class="side-menu-tabs text-right text-2xl">
                    <div class="side-menu-tabs-section py-3.5 md:py-3 px-9 md:px-14 m-0 cursor-pointer" @click="openSideMenuTab(1)">
                        <h3>Einstellungen</h3>
                    </div>

                    <div class="side-menu-tabs-section py-3.5 md:py-3 px-9 md:px-14 m-0 cursor-pointer" @click="openSideMenuTab(2)">
                        <h3>Bestellungen</h3>
                    </div>

                    <div class="side-menu-tabs-section py-3.5 md:py-3 px-9 md:px-14 m-0 cursor-pointer" @click="openSideMenuTab(3)">
                        <h3>Rechnungen</h3>
                    </div>

                    <!-- <div class="side-menu-tabs-section py-3.5 md:py-3 px-9 md:px-14 m-0 cursor-pointer" @click="openSideMenuTab(4)">
                    <h3>Gutschein erfassen</h3>
                </div>-->
                </div>

                <div class="side-menu-link-bottom text-xl font-bold absolute right-9 md:right-14">
                    <a @click.prevent="userLogout()" href="#" class="flex align-items-center hover:no-underline hover:text-white">Abmelden <i class="fas fa-chevron-right ml-2 text-base"></i></a>
                </div>
            </div>

            <div class="side-menu-tab side-menu-tab-1 w-full h-full absolute top-0" :class="{ 'tab-active': activeTab == 1 }">
                <div class="side-menu-top pt-6 md:pt-7 pb-10 md:pb-11 px-9 md:px-14 text-right">
                    <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        class="side-menu-close mb-9 inline-block cursor-pointer"
                        @click="
                            $emit('close-menu')
                            openSideMenuTab(0)
                        "
                    >
                        <g stroke="rgb(255, 255, 255)" stroke-width="2">
                            <line x1="0" y1="0" x2="20" y2="20" />
                            <line x1="0" y1="20" x2="20" y2="0" />
                        </g>
                    </svg>

                    <h2 class="heading font-bold flex align-items-center justify-end"><img class="tab-arrow-left inline-block mr-3 cursor-pointer" src="../assets/svg/arrow-left.svg" @click="openSideMenuTab(0)" />Einstellungen</h2>
                </div>

                <Accordion>
                    <Accordion-item>
                        <template slot="accordion-trigger">
                            <h3 class="m-0">Benutzerkonto</h3>
                        </template>
                        <template slot="accordion-content">
                            <div class="pt-4 pb-14 side-menu-nav">
                                <b-form id="side-menu-reset-password-form">
                                    <div class="form-fields">
                                        <div class="form-item">
                                            <label label-for="reset-password-input-1">E-Mail</label>
                                            <b-form-input id="login-input-1" v-model="reset_password.email" type="email" placeholder="E-Mail" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="reset-password-input-2">Altes Passwort</label>
                                            <b-form-input id="login-input-2" v-model="reset_password.old_password" type="password" placeholder="Altes Passwort" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="reset-password-input-3">Neues Passwort</label>
                                            <b-form-input id="login-input-3" v-model="reset_password.new_password" type="password" placeholder="Neues Passwort" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="reset-password-input-4">Neues Passwort wiederholen</label>
                                            <b-form-input id="login-input-4" v-model="reset_password.new_password_repeat" type="password" placeholder="Neues Passwort wiederholen" required class="input-side-menu"></b-form-input>
                                        </div>
                                    </div>

                                    <span id="reset-password-error" class="hidden text-red-500 text-xl"></span>

                                    <div class="button-tab-1-wrapper flex items-center justify-end">
                                        <div class="spinner" :class="{ visible: spinnerVisible }"></div>
                                        <div id="checkmark-1" class="checkmark"></div>
                                        <b-button @click.prevent="resetPassword()" type="submit" class="submit-login-btn ml-3" variant="primary">Speichern</b-button>
                                    </div>
                                </b-form>
                            </div>
                        </template>
                    </Accordion-item>

                    <Accordion-item>
                        <template slot="accordion-trigger">
                            <h3 class="m-0">Rechnungsadresse</h3>
                        </template>

                        <template slot="accordion-content">
                            <div class="pt-8 pb-4 side-menu-nav">
                                <b-form id="billing-address-form">
                                    <div class="form-fields">
                                        <div class="form-item">
                                            <label label-for="register-2-input-0">Anrede</label>
                                            <b-form-select id="register-2-input-0" v-model="user_data.salutation" :options="['Herr', 'Frau', 'Firma']" class="input-side-menu"></b-form-select>
                                        </div>

                                        <div class="form-item" v-if="user_data.salutation == 'Firma'">
                                            <label label-for="register-2-input-1">Firma</label>
                                            <b-form-input id="register-2-input-1" v-model="user_data.company" type="text" placeholder="Firmenname" class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-2-input-2">Vorname*</label>
                                            <b-form-input id="register-2-input-2" v-model="user_data.surname" type="text" placeholder="Vorname" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-2-input-3">Nachname*</label>
                                            <b-form-input id="register-2-input-3" v-model="user_data.name" type="text" placeholder="Nachname" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-2-input-4">Adresszusatz</label>
                                            <b-form-input id="register-2-input-4" v-model="user_data.billing_address.address" type="text" placeholder="Adresszusatz" class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-2-input-5">Strasse*</label>
                                            <b-form-input id="register-2-input-5" v-model="user_data.billing_address.street" type="text" placeholder="Strasse" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-two-items flex">
                                            <div class="form-item">
                                                <label label-for="register-2-input-6">PLZ*</label>
                                                <b-form-input id="register-2-input-6" v-model="user_data.billing_address.postal_code" type="text" placeholder="PLZ" required class="input-side-menu"></b-form-input>
                                            </div>

                                            <div class="form-item">
                                                <label label-for="register-2-input-7">Ort*</label>
                                                <b-form-input id="register-2-input-7" v-model="user_data.billing_address.city" type="text" placeholder="Ort" required class="input-side-menu"></b-form-input>
                                            </div>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-2-input-8">Telefon*</label>
                                            <b-form-input id="register-2-input-8" v-model="user_data.telephone" type="tel" placeholder="Telefon" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-2-input-9">E-Mail*</label>
                                            <b-form-input id="register-2-input-9" v-model="user_data.email" type="email" placeholder="E-Mail" required class="input-side-menu"></b-form-input>
                                        </div>
                                    </div>

                                    <div class="button-tab-1-wrapper flex items-center justify-end">
                                        <div class="spinner" :class="{ visible: spinnerVisible }"></div>
                                        <div id="checkmark-2" class="checkmark"></div>
                                        <b-button @click.prevent="updateBillingAddress()" type="submit" class="submit-login-btn ml-3" variant="primary">Speichern</b-button>
                                    </div>
                                </b-form>
                            </div>
                        </template>
                    </Accordion-item>

                    <Accordion-item>
                        <template slot="accordion-trigger">
                            <h3 class="m-0">Lieferadresse</h3>
                        </template>

                        <template slot="accordion-content">
                            <div class="pt-8 pb-4 side-menu-nav">
                                <b-form id="shipping-address-form">
                                    <div class="form-fields">
                                        <div class="form-item">
                                            <label label-for="register-3-input-0">Anrede</label>
                                            <b-form-select id="register-3-input-0" v-model="user_data.shipping_address.salutation" :options="['Herr', 'Frau', 'Firma']" class="input-side-menu"></b-form-select>
                                        </div>

                                        <div class="form-item" v-if="user_data.shipping_address.salutation == 'Firma'">
                                            <label label-for="register-3-input-1">Firma</label>
                                            <b-form-input id="register-3-input-1" v-model="user_data.shipping_address.company" type="text" placeholder="Firmenname" class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-3-input-2">Vorname*</label>
                                            <b-form-input id="register-3-input-2" v-model="user_data.shipping_address.surname" type="text" placeholder="Vorname" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-3-input-3">Nachname*</label>
                                            <b-form-input id="register-3-input-3" v-model="user_data.shipping_address.name" type="text" placeholder="Nachname" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-3-input-4">Adresszusatz</label>
                                            <b-form-input id="register-3-input-4" v-model="user_data.shipping_address.address" type="text" placeholder="Adresszusatz" class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-3-input-5">Strasse*</label>
                                            <b-form-input id="register-3-input-5" v-model="user_data.shipping_address.street" type="text" placeholder="Strasse" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-two-items flex">
                                            <div class="form-item">
                                                <label label-for="register-3-input-6">PLZ*</label>
                                                <b-form-input id="register-3-input-6" v-model="user_data.shipping_address.postal_code" type="text" placeholder="PLZ" required class="input-side-menu"></b-form-input>
                                            </div>

                                            <div class="form-item">
                                                <label label-for="register-3-input-7">Ort*</label>
                                                <b-form-input id="register-3-input-7" v-model="user_data.shipping_address.city" type="text" placeholder="Ort" required class="input-side-menu"></b-form-input>
                                            </div>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-3-input-8">Telefon*</label>
                                            <b-form-input id="register-3-input-8" v-model="user_data.shipping_address.telephone" type="tel" placeholder="Telefon" required class="input-side-menu"></b-form-input>
                                        </div>

                                        <div class="form-item">
                                            <label label-for="register-3-input-9">E-Mail*</label>
                                            <b-form-input id="register-3-input-9" v-model="user_data.shipping_address.email" type="email" placeholder="E-Mail" required class="input-side-menu"></b-form-input>
                                        </div>
                                    </div>

                                    <div class="button-tab-1-wrapper flex items-center justify-end">
                                        <div class="spinner" :class="{ visible: spinnerVisible }"></div>
                                        <div id="checkmark-3" class="checkmark"></div>
                                        <b-button @click.prevent="updateShippingAddress()" type="submit" class="submit-login-btn ml-3" variant="primary">Speichern</b-button>
                                    </div>
                                </b-form>
                            </div>
                        </template>
                    </Accordion-item>
                </Accordion>

                <div class="side-menu-link-bottom text-xl font-bold absolute right-9 md:right-14">
                    <a @click.prevent="userLogout()" href="#" class="flex align-items-center hover:no-underline hover:text-white">Abmelden <i class="fas fa-chevron-right ml-2 text-base"></i></a>
                </div>
            </div>

            <div class="side-menu-tab side-menu-tab-2 w-full h-full absolute top-0" :class="{ 'tab-active': activeTab == 2 }">
                <div class="side-menu-top pt-6 md:pt-7 pb-10 md:pb-11 px-9 md:px-14 text-right">
                    <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        class="side-menu-close mb-9 inline-block cursor-pointer"
                        @click="
                            $emit('close-menu')
                            openSideMenuTab(0)
                        "
                    >
                        <g stroke="rgb(255, 255, 255)" stroke-width="2">
                            <line x1="0" y1="0" x2="20" y2="20" />
                            <line x1="0" y1="20" x2="20" y2="0" />
                        </g>
                    </svg>

                    <h2 class="heading font-bold flex align-items-center justify-end"><img class="tab-arrow-left inline-block mr-3 cursor-pointer" src="../assets/svg/arrow-left.svg" @click="openSideMenuTab(0)" />Bestellungen</h2>
                </div>

                <div class="order-history">
                    <table class="tabs-table w-full table-fixed hidden md:table">
                        <tr>
                            <th class="pl-14">Datum</th>
                            <th>Auftragsnummer</th>
                            <th class="text-right pr-14">Gesamtbetrag</th>
                        </tr>
                        <tr class="order-row cursor-pointer" v-for="(order, index) in order_items" :key="order.order_id">
                            <td class="order-date py-2 pl-14 pr-4 align-top" @click="openHiddenSection(index)" :class="{ 'section-active': tableActive.includes(index) }">
                                <span class="block">{{ order.date }}</span>
                                <transition name="table-hidden" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
                                    <div class="table-hidden pt-3 pb-1 cursor-default" v-show="tableActive.includes(index)" v-on:click.stop>
                                        <div class="mb-3 product-equal-height" v-for="product in order.products" :key="product.id">
                                            <img :src="product.img_src" />
                                        </div>
                                    </div>
                                </transition>
                            </td>
                            <td class="order-id py-2 pr-4 align-top" @click="openHiddenSection(index)" :class="{ 'section-active': tableActive.includes(index) }">
                                <span class="block">ABPK-{{ order.order_id }}</span>
                                <transition name="table-hidden" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
                                    <div class="table-hidden pt-3 pb-1 cursor-default" v-show="tableActive.includes(index)" v-on:click.stop>
                                        <div class="mb-3 product-equal-height" v-for="product in order.products" :key="product.id">
                                            {{ product.description }}
                                        </div>
                                    </div>
                                </transition>
                            </td>
                            <td class="order-total text-right py-2 pr-14 align-top" :class="{ 'section-active': tableActive.includes(index) }">{{ order.total }} CHF</td>
                        </tr>
                    </table>

                    <div class="tabs-table-mobile block md:hidden">
                        <div class="order-item cursor-pointer flex flex-col py-3.5" v-for="(order, index) in order_items" :key="order.order_id">
                            <div class="flex w-full" @click="openHiddenSection(index)" :class="{ 'section-active': tableActive.includes(index) }">
                                <div class="order-item-headers pl-9 pr-2.5 w-1/2">
                                    <span class="font-bold block">Datum</span>
                                    <span class="font-bold block">Auftragsnummer</span>
                                    <span class="font-bold block">Gesamtbetrag</span>
                                </div>

                                <div class="order-item-content pr-9 pl-2.5 w-1/2">
                                    <p>{{ order.date }}</p>
                                    <p>ABPK-{{ order.order_id }}</p>
                                    <p>{{ order.total }} CHF</p>
                                </div>
                            </div>

                            <transition name="table-hidden" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
                                <div class="table-hidden cursor-default px-9 pt-6 flex flex-col md:flex-row" v-show="tableActive.includes(index)" v-on:click.stop>
                                    <div class="mb-3" v-for="product in order.products" :key="product.id">
                                        <img class="w-1/2 pr-2.5" :src="product.img_src" />
                                        <p class="w-1/2 md:pl-2.5 pt-2.5 md:pt-0">{{ product.description }}</p>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <div class="side-menu-link-bottom text-xl font-bold absolute right-9 md:right-14">
                    <a @click.prevent="userLogout()" href="#" class="flex align-items-center hover:no-underline hover:text-white">Abmelden <i class="fas fa-chevron-right ml-2 text-base"></i></a>
                </div>
            </div>

            <div class="side-menu-tab side-menu-tab-3 w-full h-full absolute top-0" :class="{ 'tab-active': activeTab == 3 }">
                <div class="side-menu-top pt-6 md:pt-7 pb-10 md:pb-11 px-9 md:px-14 text-right">
                    <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        class="side-menu-close mb-9 inline-block cursor-pointer"
                        @click="
                            $emit('close-menu')
                            openSideMenuTab(0)
                        "
                    >
                        <g stroke="rgb(255, 255, 255)" stroke-width="2">
                            <line x1="0" y1="0" x2="20" y2="20" />
                            <line x1="0" y1="20" x2="20" y2="0" />
                        </g>
                    </svg>

                    <h2 class="heading font-bold flex align-items-center justify-end"><img class="tab-arrow-left inline-block mr-3 cursor-pointer" src="../assets/svg/arrow-left.svg" @click="openSideMenuTab(0)" />Rechnungen</h2>
                </div>

                <div class="invoice-history">
                    <table class="tabs-table w-full table-fixed hidden md:table">
                        <tr>
                            <th class="pl-14">Datum</th>
                            <th>Rechnungsnummer</th>
                            <th class="text-right pr-14">Gesamtbetrag</th>
                        </tr>
                        <tr class="order-row" v-for="order in order_items" :key="order.id">
                            <td class="order-date py-2 pl-14 pr-4 align-top">
                                <span class="block">{{ order.date }}</span>
                            </td>
                            <td class="order-id py-2 pr-4 align-top">
                                <span class="block">REPK-{{ order.order_id }}</span>
                            </td>
                            <td class="order-total text-right py-2 pr-14 align-top">
                                <span class="block">{{ order.total }} CHF</span>
                            </td>
                        </tr>
                    </table>

                    <div class="tabs-table-mobile block md:hidden">
                        <div class="order-item flex flex-col py-3.5" v-for="order in order_items" :key="order.id">
                            <div class="flex w-full">
                                <div class="order-item-headers pl-9 pr-2.5 w-1/2">
                                    <span class="font-bold block">Datum</span>
                                    <span class="font-bold block">Rechnungsnummer</span>
                                    <span class="font-bold block">Gesamtbetrag</span>
                                </div>

                                <div class="order-item-content pr-9 pl-2.5 w-1/2">
                                    <p>{{ order.date }}</p>
                                    <p>REPK-{{ order.order_id }}</p>
                                    <p>{{ order.total }} CHF</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="side-menu-link-bottom text-xl font-bold absolute right-9 md:right-14">
                    <a @click.prevent="userLogout()" href="#" class="flex align-items-center hover:no-underline hover:text-white">Abmelden <i class="fas fa-chevron-right ml-2 text-base"></i></a>
                </div>
            </div>

            <div class="side-menu-tab side-menu-tab-4 w-full h-full absolute top-0" :class="{ 'tab-active': activeTab == 4 }">
                <div class="side-menu-top pt-6 md:pt-7 pb-10 md:pb-11 px-9 md:px-14 text-right">
                    <svg
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        class="side-menu-close mb-9 inline-block cursor-pointer"
                        @click="
                            $emit('close-menu')
                            openSideMenuTab(0)
                        "
                    >
                        <g stroke="rgb(255, 255, 255)" stroke-width="2">
                            <line x1="0" y1="0" x2="20" y2="20" />
                            <line x1="0" y1="20" x2="20" y2="0" />
                        </g>
                    </svg>

                    <h2 class="heading font-bold flex align-items-center justify-end"><img class="tab-arrow-left inline-block mr-3 cursor-pointer" src="../assets/svg/arrow-left.svg" @click="openSideMenuTab(0)" />Gutschein erfassen</h2>
                </div>

                <div class="side-menu-nav side-menu-coupon pt-2.5 px-9 md:px-14 pb-28">
                    <b-form>
                        <div class="form-fields">
                            <div class="form-item">
                                <label label-for="add-coupon-input-1">Gutscheincode</label>
                                <b-form-input id="add-coupon-input-1" v-model="add_coupon.coupon" type="text" placeholder="Gutscheincode" required class="input-side-menu"></b-form-input>
                            </div>
                        </div>

                        <b-button type="submit" class="submit-login-btn ml-auto mt-3 md:mt-4" variant="primary" block>Hinzufügen</b-button>
                    </b-form>
                </div>

                <div class="coupon-history">
                    <table class="tabs-table w-full">
                        <tr>
                            <th class="pl-9 md:pl-14 w-1/2">Erfasster Gutschein</th>
                            <th>Rabatt</th>
                            <th class="text-right pr-9 md:pr-14"></th>
                        </tr>
                        <tr class="order-row" v-for="coupon in coupon_history" :key="coupon.id">
                            <td class="order-date py-2 pl-9 md:pl-14 pr-4 w-1/2">
                                <span class="block">{{ coupon.name }}</span>
                            </td>
                            <td class="order-id py-2 pr-4 font-bold">
                                <span class="block">{{ coupon.discount }}</span>
                            </td>
                            <td class="order-total text-right py-2 pr-9 md:pr-14">
                                <img src="../assets/svg/icon-bin.svg" class="cursor-pointer inline-block" />
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="side-menu-link-bottom text-xl font-bold absolute right-9 md:right-14">
                    <a @click.prevent="userLogout()" href="#" class="flex align-items-center hover:no-underline hover:text-white">Abmelden <i class="fas fa-chevron-right ml-2 text-base"></i></a>
                </div>
            </div>
        </div>
        <!-- ********** -->
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Accordion from './Accordion'
import AccordionItem from './Accordion-item'
import shop from '../api/shop'

export default {
    name: 'SideMenu',
    props: {
        msg: String
    },
    components: {
        Accordion,
        AccordionItem
    },
    data: function () {
        return {
            activeTab: undefined,
            tableActive: [],
            login: {
                email: '',
                password: ''
            },
            account: {
                salutation: '',
                company: '',
                surname: '',
                name: '',
                address: '',
                street: '',
                postal_code: '',
                city: '',
                telephone: '',
                email: '',
                password: '',
                password_repeat: ''
            },
            reset_password: {
                email: '',
                old_password: '',
                new_password: '',
                new_password_repeat: '',
                type: '',
                token: ''
            },
            forget_password: {
                email: '',
                url: 'https://pk.conseo-test.ch/#forget-password'
            },
            add_coupon: {
                coupon: ''
            },
            coupon_history: [
                {
                    id: 1,
                    name: 'Lepo20',
                    discount: '20%'
                },
                {
                    id: 2,
                    name: 'Lepo20',
                    discount: '20%'
                }
            ],
            spinnerVisible: false,
            show_forget_password: false,
            show_reset_password: false
        }
    },
    computed: {
        ...mapState({
            user_logged_in: (state) => state.user.user_logged_in,
            raw_user_data: (state) => state.user.raw_user_data,
            raw_shipping_address: (state) => state.user.raw_shipping_address,
            has_shipping_address: (state) => state.user.has_shipping_address,
            order_items: (state) => state.user.order_history,
            user_data(state) {
                this.reset_password.email = state.user.user_data.email
                return state.user.user_data
            }
        })
    },
    mounted() {
        let windowHash = window.location.hash.split('?token=')
        if (windowHash[0] == '#forget-password') {
            this.show_reset_password = true
            this.reset_password.token = windowHash[1]
            this.$emit('close-menu')
        }
    },
    methods: {
        openSideMenuTab(num) {
            this.activeTab = num
        },
        openHiddenSection(num) {
            if (this.tableActive.includes(num)) this.tableActive.splice(this.tableActive.indexOf(num), 1)
            else this.tableActive = [num]
        },
        start(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        end(el) {
            el.style.height = ''
        },
        userLogin() {
            if (document.getElementById('side-menu-login-form').checkValidity()) {
                this.$emit('show-loading')
                shop.userLogin(this.login.email, this.login.password, (data) => {
                    if (data.data.message == 'Forbidden') {
                        document.getElementById('login-error').style.display = 'inline-block'
                        this.$emit('show-loading')
                    } else {
                        this.$store.dispatch('user/updateUserStatus', true).then(() => {
                            this.$store.dispatch('user/getUser', () => {})
                            this.$emit('show-loading')
                        })
                    }
                })
            } else document.getElementById('side-menu-login-form').reportValidity()
        },
        userLogout() {
            if (this.user_logged_in) {
                shop.userLogout((data) => {
                    if (data.data.token)
                        this.$store.dispatch('user/updateUserStatus', false).then(() => {
                            this.$store.commit('user/clearUserData')
                            this.$emit('close-menu')
                        })
                })
            }
        },
        userRegister() {
            if (document.getElementById('side-menu-register-form').checkValidity()) {
                if (document.getElementById('register-input-10').value != document.getElementById('register-input-11').value) {
                    this.showErrorMessage('register-error', "Die Passwörter sind nicht gleich.")
                } else {
                    shop.userRegister(this.account, (data) => {
                        if (data.data.status == '200') {
                            document.getElementById('accordion-register-form').style.display = 'none'
                            document.getElementById('side-menu-main-heading').style.display = 'none'
                            document.getElementById('side-menu-after-register-heading').style.display = 'block'
                        } else if (data.data.message) {
                            document.getElementById('register-error').style.marginTop = '-30px'
                            this.showErrorMessage('register-error', data.data.message)
                        }
                    })
                }
            } else document.getElementById('side-menu-register-form').reportValidity()
        },
        forgetPassword() {
            if (document.getElementById('side-menu-forget-password-form').checkValidity()) {
                this.spinnerVisible = true

                shop.forgetPassword(this.forget_password, (data) => {
                    if ((data.data.message && data.data.message.toLowerCase() != 'success') || data.data.errors) {
                        this.showErrorMessage('forget-password-error', 'Ups, da hat wohl was nicht geklappt. Bitte nochmals versuchen.')
                    } else {
                        document.getElementById('forget-password-error').style.color = '#4BB543'
                        this.showErrorMessage('forget-password-error', 'Das Email um das Passwort zurück zu setzen wurde versendet.')
                        setTimeout(() => {
                            document.getElementById('forget-password-error').style.display = 'none'
                            document.getElementById('forget-password-error').style.color = 'rgb(239, 68, 68)'
                        }, 5000)
                    }
                    this.spinnerVisible = false
                })
            } else document.getElementById('side-menu-login-form').reportValidity()
        },
        newPassword() {
            if (document.getElementById('side-menu-new-password-form').checkValidity()) {
                if (document.getElementById('new-password-input-1').value != document.getElementById('new-password-input-2').value) {
                    this.showErrorMessage('new-password-error', "Die Passwörter sind nicht gleich.")
                } else {
                    this.spinnerVisible = true
                    this.reset_password.type = 'not_logged_in'

                    shop.resetPassword(this.reset_password, (data) => {
                        if ((data.data.message && data.data.message.toLowerCase() != 'success') || data.data.errors) {
                            this.showErrorMessage('new-password-error', 'Ups, da hat wohl was nicht geklappt. Bitte nochmals versuchen.')
                        } else {
                            document.getElementById('new-password-error').style.color = '#4BB543'
                            this.showErrorMessage('new-password-error', 'Dein Paswort wurde erfolgreich geändert.')
                            setTimeout(() => {
                                document.getElementById('new-password-error').style.display = 'none'
                                document.getElementById('new-password-error').style.color = 'rgb(239, 68, 68)'
                                this.show_reset_password = false
                                window.location.hash = ''
                            }, 5000)
                        }
                        this.spinnerVisible = false
                    })
                }
            } else document.getElementById('side-menu-reset-password-form').reportValidity()
        },
        resetPassword() {
            if (document.getElementById('side-menu-reset-password-form').checkValidity()) {
                if (document.getElementById('login-input-3').value != document.getElementById('login-input-4').value) {
                    this.showErrorMessage('reset-password-error', "Die Passwörter sind nicht gleich.")
                } else if (document.getElementById('login-input-1').value != this.user_data.email) {
                    this.showErrorMessage('reset-password-error', 'Falsche Email Adresse')
                } else {
                    this.spinnerVisible = true
                    this.reset_password.type = 'logged_in'
                    shop.resetPassword(this.reset_password, (data) => {
                        if ((data.data.message && data.data.message.toLowerCase() != 'success') || data.data.errors) {
                            this.showErrorMessage('reset-password-error', 'Dein altes Passwort stimmt nicht.')
                        } else {
                            document.getElementById('checkmark-1').style.display = 'inline-block'
                            document.getElementById('reset-password-error').style.color = '#4BB543'
                            this.showErrorMessage('reset-password-error', 'Dein Paswort wurde erfolgreich geändert.')
                            setTimeout(() => {
                                document.getElementById('checkmark-1').style.display = 'none'
                                document.getElementById('reset-password-error').style.display = 'none'
                                document.getElementById('reset-password-error').style.color = 'rgb(239, 68, 68)'
                            }, 5000)
                        }
                        this.spinnerVisible = false
                    })
                }
            } else document.getElementById('side-menu-reset-password-form').reportValidity()
        },
        updateBillingAddress() {
            if (document.getElementById('billing-address-form').checkValidity()) {
                this.spinnerVisible = true
                let billing_data = this.raw_user_data
                let salutation = 3

                if (this.user_data.salutation == 'Herr') salutation = 2
                else if (this.user_data.salutation == 'Firma') salutation = 1

                billing_data.anrede.value = salutation
                billing_data.anschrift1 = this.user_data.surname
                billing_data.anschrift2 = this.user_data.name
                billing_data.anschrift3 = this.user_data.company
                billing_data.ort = this.user_data.billing_address.city
                billing_data.plz = this.user_data.billing_address.postal_code
                billing_data.strasse = this.user_data.billing_address.street
                billing_data.telefon = this.user_data.telephone
                billing_data.mail = this.user_data.email

                shop.updateBillingAddress(billing_data, (data) => {
                    this.spinnerVisible = false
                    document.getElementById('checkmark-2').style.display = 'inline-block'
                    this.$store.commit('user/updateUserData', data.data)
                    this.$store.commit('user/updateRawUserData', data.data)

                    setTimeout(() => {
                        document.getElementById('checkmark-2').style.display = 'none'
                    }, 5000)
                })
            } else document.getElementById('billing-address-form').reportValidity()
        },
        updateShippingAddress() {
            if (document.getElementById('shipping-address-form').checkValidity()) {
                this.spinnerVisible = true
                let shipping_data
                let salutation = 3

                if (this.user_data.shipping_address.salutation == 'Herr') salutation = 2
                else if (this.user_data.shipping_address.salutation == 'Firma') salutation = 1

                if (this.has_shipping_address)
                    shipping_data = {
                        anrede: {
                            value: salutation
                        },
                        adressnummer: this.raw_shipping_address.adressnummer,
                        anschrift1: this.user_data.shipping_address.surname,
                        anschrift2: this.user_data.shipping_address.name,
                        anschrift3: this.user_data.shipping_address.company,
                        mail: this.user_data.shipping_address.email,
                        ort: this.user_data.shipping_address.city,
                        plz: this.user_data.shipping_address.postal_code,
                        strasse: this.user_data.shipping_address.street,
                        telefon: this.user_data.shipping_address.telephone
                    }
                else
                    shipping_data = {
                        anrede: {
                            value: salutation
                        },
                        anschrift1: this.user_data.shipping_address.surname,
                        anschrift2: this.user_data.shipping_address.name,
                        anschrift3: this.user_data.shipping_address.company,
                        email: this.user_data.shipping_address.email,
                        land: {
                            text: 'Schweiz',
                            value: 'CH'
                        },
                        ort: this.user_data.shipping_address.city,
                        plz: this.user_data.shipping_address.postal_code,
                        strasse: this.user_data.shipping_address.street,
                        tel: this.user_data.shipping_address.telephone
                    }

                shop.updateShippingAddress(shipping_data, this.has_shipping_address, (data) => {
                    if (data.data.message == 'Ok') {
                        this.spinnerVisible = false
                        document.getElementById('checkmark-3').style.display = 'inline-block'
                        this.$store.dispatch('user/getShippingAddress', this.user_data.email)
                        setTimeout(() => {
                            document.getElementById('checkmark-3').style.display = 'none'
                        }, 5000)
                    }
                })
            } else document.getElementById('shipping-address-form').reportValidity()
        },
        showErrorMessage(id, error) {
            document.getElementById(id).style.display = 'block'
            document.getElementById(id).innerHTML = error
        }
    }
}
</script>

<style lang="scss" scoped>
.side-menu-wrapper {
    .heading {
        font-size: 2.188rem;
        line-height: 2.5rem;
    }

    .after-register {
        max-width: 26.25rem;
    }

    .side-menu-nav {
        p {
            &.login-info {
                font-size: 1.125rem;
                line-height: 1.563rem;
                margin-bottom: 1.25rem;

                @media (max-width: 768px) {
                    margin-bottom: 2.5rem;
                }
            }
        }

        form {
            .form-fields {
                .form-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 1rem;

                    @media (max-width: 768px) {
                        margin-bottom: 0.813rem;
                        flex-direction: row;
                        flex-wrap: wrap;
                    }

                    &:last-child {
                        margin-bottom: 0.75rem;
                    }

                    label {
                        text-align: right;
                        margin-right: 0.688rem;
                        font-size: 0.813rem;
                        line-height: 1.25rem;
                        width: 5.75rem;
                        margin-bottom: 0;

                        @media (max-width: 768px) {
                            display: none;
                        }
                    }

                    input,
                    select {
                        flex: 1;
                        font-size: 1.125rem;
                        line-height: 1;
                        border-radius: 19px;
                        padding: 0.25rem 1rem;
                        border: 1px solid #fff;

                        @media (max-width: 768px) {
                            width: 100%;
                        }

                        &:focus {
                            border-color: #80bdff;
                            outline: 0;
                            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 50%);
                        }
                    }

                    input {
                        height: unset;
                    }

                    ::placeholder {
                        color: #bcbcbc;
                    }
                }

                &.form-login {
                    .form-item {
                        label {
                            @media (max-width: 768px) {
                                display: block;
                                width: 100%;
                                text-align: left;
                                margin-bottom: 0.2rem;
                            }
                        }
                    }
                }

                .form-two-items {
                    .form-item {
                        &:first-child {
                            input {
                                width: 8.25rem;

                                @media (max-width: 768px) {
                                    width: 7rem;
                                }
                            }
                        }

                        &:last-child {
                            flex: 1;

                            label {
                                width: unset;
                                margin-left: 0.75rem;
                            }

                            @media (max-width: 768px) {
                                input {
                                    margin-left: 0.75rem;
                                }
                            }
                        }
                    }
                }
            }

            .button-tab-1-wrapper {
                margin-top: 2rem;

                @media (max-width: 768px) {
                    margin-top: 1.875rem;
                }

                &.buttons-forget-password {
                    .btn {
                        min-width: fit-content;
                        padding-inline: 1.5rem;

                        &.btn-secondary {
                            border: 1px solid white;
                        }
                    }
                }
            }

            .submit-login-btn {
                width: unset;
            }

            .spinner {
                display: none;
                border: 3px solid #f3f3f3;
                border-radius: 50%;
                border-top: 3px solid rgb(247, 166, 0);
                width: 24px;
                height: 24px;
                -webkit-animation: spin 1s linear infinite;
                animation: spin 1s linear infinite;

                &.visible {
                    display: block;
                }
            }

            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }

                100% {
                    -webkit-transform: rotate(360deg);
                }
            }

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }

            .checkmark {
                display: none;
                transform: rotate(45deg);
                height: 21px;
                width: 12px;
                border-bottom: 5px solid #78b13f;
                border-right: 5px solid #78b13f;
                margin-top: -2px;
                margin-right: 5px;
            }
        }

        span.lost-password {
            color: #f7a600;
            margin-left: 6.5rem;

            @media (max-width: 768px) {
                margin-left: 1.1rem;
            }
        }
    }

    .side-menu-tabs {
        .side-menu-tabs-section {
            border-bottom: 1px solid #707070;

            &:first-child {
                border-top: 1px solid #707070;
            }
        }
    }

    .side-menu-link-bottom {
        bottom: 2.375rem;

        @media (max-width: 768px) {
            bottom: 1.75rem;
        }

        i {
            margin-bottom: -3px;
        }
    }

    .side-menu-tab {
        background-color: rgb(88, 88, 90);
        right: -100%;
        transition: all 0.4s ease;

        &.tab-active {
            right: 0;
        }

        // &.side-menu-tab-1 {
        //     input {
        //         /*font-size: 1rem !important;*/
        //         /*padding: 0.15rem 1rem !important;*/
        //     }
        // }

        &.side-menu-tab-4 {
            .heading {
                @media (max-width: 768px) {
                    font-size: 1.8rem;
                    line-height: 2rem;
                }
            }
        }

        .order-history,
        .invoice-history {
            height: 72%;
            overflow: auto;
        }
    }

    .tabs-table {
        th {
            font-size: 0.938rem;
            line-height: 1.25rem;
            padding-bottom: 0.813rem;
        }

        tr {
            border-bottom: 1px solid #707070;
        }

        td {
            font-size: 1.125rem;
            line-height: 1.25rem;

            img {
                max-width: 100%;
            }

            &.section-active {
                font-weight: bold;
            }

            .table-hidden {
                font-weight: normal !important;
            }
        }
    }

    .tabs-table-mobile {
        font-size: 0.938rem;
        line-height: 1.5rem;

        .order-item {
            border-bottom: 1px solid #707070;

            &:first-child {
                border-top: 1px solid #707070;
            }
        }
    }
}

.table-hidden-enter-active,
.table-hidden-leave-active {
    will-change: height, opacity;
    transition: height 0.3s linear, opacity 0.2s linear;
    overflow: hidden;
}

.table-hidden-enter,
.table-hidden-leave-to {
    height: 0 !important;
    opacity: 0;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

</style>
