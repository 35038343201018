import shop from '../api/shop'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  materials: [],
  frames: [],
  edges: []
})

// getters
const getters = {
  material_frames: (state) => {
    var materials = state.materials.map((material) => {
      return material.id
    })
    return state.frames.map((frame) => {
      return materials.includes(frame.parent)
    })
  },
  material_edges: (state) => {
    var materials = state.materials.map((material) => {
      return material.id
    })
    return state.edges.map((edge) => {
      return materials.includes(edge.parent)
    })
  }
}

// actions
const actions = {
  getSession({ commit }) {
    return new Promise(function (resolve, reject) {
      if (!document.cookie.includes('session=')) {
        shop.getSession( ( result ) => {
          if ( !result.data.token ) {
            document.cookie.split(';').forEach(function (c) {
              document.cookie = c
                .replace(/^ +/, '')
                .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
            });
            console.log('cookie deleted - because session expired for materials');
            // document.getElementById('app').remove()
            // alert(
            //   'Posterkönig ist wegen Wartungsarbeiten momentan nicht verfügbar. Bitte versuchen Sie es später nochmals.'
            // )
          } else document.cookie = 'session=' + result.data.token + ';path=/' // 3 days
          resolve()
        })
      } else {
        resolve()
      }
    })
  },
  getMaterials({ commit }, cb) {
    let materialsArray = [],
      framesArray = [],
      edgesArray = []

    shop.getMaterials((items) => {
      if (!items || !items.length) {
        //document.getElementById("app").remove();
        document.cookie.split(';').forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
        });
        console.log('cookie deleted - because session expired for materials');
        // alert(
        //   'Posterkönig ist wegen Wartungsarbeiten momentan nicht verfügbar.'
        // )
      } else {
        items.forEach((material) => {
          materialsArray.push({
            id: material.value,
            metaDescription: material.metatagsDescription,
            metaKeywords: material.metatagsKeywords,
            parent: '',
            image: material.bildname,
            reference: material.text,
            link: material.kurzlink
          })

          material.children.forEach((frame) => {
            framesArray.push({
              id: frame.value,
              metaDescription: frame.metatagsDescription,
              metaKeywords: material.metatagsKeywords,
              parent: frame.parentGroup,
              image: frame.bildname,
              reference: frame.text,
              link: material.kurzlink
            })

            frame.children.forEach((edge) => {
              edgesArray.push({
                id: edge.value,
                metaDescription: edge.metatagsDescription,
                metaKeywords: material.metatagsKeywords,
                parent: edge.parentGroup,
                image: edge.bildname,
                reference: edge.text,
                link: material.kurzlink
              })
            })
          })
        })

        commit('setMaterials', materialsArray)
        commit('setFrames', framesArray)
        commit('setEdges', edgesArray)
        if (cb) cb(materialsArray)
      }
    })
  }
}

// mutations
const mutations = {
  setMaterials(state, items) {
    state.materials = items
  },
  setFrames(state, items) {
    state.frames = items
  },
  setEdges(state, items) {
    state.edges = items
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
