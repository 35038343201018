<template>
<b-container id="material-section" class="container section-lines" fluid>
    <SideLine title="Material" />
    <div class="container-inner text-right">
        <div class="m-auto max-w-7xl">
            <div class="text-center">
                <h2 class="title secondary">Wähle dein Material</h2>
            </div>
        </div>

        <div class="material-boxes justify-content-center mb-6 xl:mb-20 mt-16 xl:mt-16 hidden md:flex">
            <div class="material-box-item px-2 pb-4 xl:pb-0 xl:px-1.5 xl:mx-3" v-for="material in materials" :key="material.image">
                <div class="text-center item-material-box cursor-pointer" :class="{'item-material-active': current_material == material}" @click="chooseMaterial(material)">
                    <div class="material-box-image inline-block rounded-full">
                        <img class="block mx-auto my-0 w-full" :src=" baseAPIUrl + material.image " />
                    </div>
                    <p class="item-material-box-title mt-2"><b>{{material.reference}}</b></p>

                    <p class="item-material-box-description mt-2">
                        {{stripTags(material.metaKeywords)}}
                    </p>

                </div>
            </div>
        </div>

        <div class="material-swiper block md:hidden pt-12">
            <swiper class="swiper" :options="swiperOption" ref="swiper" @click-slide="clickSlide">
                <swiper-slide v-for="material in materials" :key="material.image">
                    <div class="text-center item-material-box cursor-pointer" :class="{'item-material-active': current_material == material}">
                        <div class="material-box-image inline-block rounded-full">
                            <img class="block mx-auto my-0 w-full" :src=" baseAPIUrl + material.image " />
                        </div>
                        <p class="item-material-box-title mt-1.5 mx-auto"><b>{{material.reference}}</b></p>

                       <!--<p class="item-material-box-description mt-1.5 mx-auto">
                            {{material.metaKeywords}}
                        </p>--> 
                    </div>
                </swiper-slide>
            </swiper>
        </div>

        <div class="material-box-wrapper mx-auto my-0" v-if="current_material">
            <div class="material-box mx-auto my-0 flex align-items-center justify-center">
                <div class="material-box-img cursor-pointer">
                    <img class="rounded-full hidden xl:block" :src="baseAPIUrl + current_material.image" />
                </div>
                <div class="material-box-text xl:ml-14">
                    <p class="material-title title secondary cursor-pointer pt-14 md:pt-0 mb-3 md:mb-2 xl:mb-3">{{current_material.reference}}</p>
                    <p class="pb-9 md:pb-6 xl:pb-8">{{ stripTags(current_material.metaDescription) }}</p>
                    <div class="flex justify-center xl:justify-start">
                        <a v-bind:href="current_material.link" class="btn btn-hollow mb-0 mr-3 xl:mr-0 btn-mobile">Mehr Infos</a>
                        <a @click="$bvModal.show('modal-upload-file')"  class="btn btn-primary mt-6 xl:mt-0 hidden xl:inline-block btn-mobile" v-if="!current_image_main">Bild hochladen</a>
                        <a @click="nextSection()"  class="btn btn-primary mt-6 xl:mt-0 hidden xl:inline-block btn-mobile" v-if="current_image_main">Weiter</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- <a @click="$bvModal.show('modal-upload-file')"  class="btn btn-primary mt-6 xl:mt-2 hidden xl:inline-block btn-mobile" v-if="!current_image_main">Bild hochladen</a> -->
        <!-- <a @click="nextSection()"  class="btn btn-primary mt-6 xl:mt-2 hidden xl:inline-block btn-mobile" v-if="current_image_main">Weiter</a> -->

    </div>

    <div id="wizard-box" class="text-right wizard-box fixed top-1/2">
        <b class="pb-1.5 block">Du bist auf dem<br>richtigen Weg</b>
        <ul class="m-0 p-0" :class="wizard_status">
            <li id="wizard-item-1">Foto-Upload <b-icon icon="check-2"></b-icon>
            </li>
            <li id="wizard-item-2">Materialwahl <b-icon icon="check-2"></b-icon>
            </li>
            <li id="wizard-item-3">Konfiguration <b-icon icon="check-2"></b-icon>
            </li>
            <li id="wizard-item-4">Vorschau <b-icon icon="check-2"></b-icon>
            </li>
            <li id="wizard-item-5">Warenkorb <b-icon icon="check-2"></b-icon>
            </li>
        </ul>
    </div>

</b-container>
</template>

<script>
import SideLine from '../components/SideLine.vue'
import {
    Swiper,
    SwiperSlide
} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import {
    mapState
} from 'vuex'
import {
    moveTo
} from '@/utils/fullpage'
import config from '@/utils/config'

export default {
    name: 'MaterialSection',
    props: {
        msg: String
    },
    components: {
        Swiper,
        SwiperSlide,
        SideLine,
    },
    computed: {
        ...mapState({
            materials: state => state.shop.materials,
            current_material: state => state.cart.current_material,
            current_image: state => state.cart.current_image,
            current_image_main: (state) => state.cart.current_image,
            wizard_status(state) {
                let classList = "";
                for (let i = 0; i < state.wizard.wizard_status.length; i++) {
                    if (state.wizard.wizard_status[i] == 1) classList += ' active-section-' + (i + 1);
                }
                return classList;
            }
        }),
        swiper() {
            return this.$refs.swiper.$swiper
        }
    },
    watch: {
        update_swiper() {
            var material = this.current_material
            //console.log('update_swiper', material)
        }
    },
    mounted() {
        window.onscroll = this.showWizard;
        this.showWizard();
    },
    data() {
        return {
            // slide: 0,
            // sliding: null,
            swiperOption: {
                slidesPerView: 2,
                spaceBetween: 25,
                loop: true,
                loopFillGroupWithBlank: false,
                centeredSlides: true,
                breakpoints: {
                    450: {
                        slidesPerView: 2.5,
                    },
                    500: {
                        slidesPerView: 3,
                    }
                }
            },
            baseAPIUrl: config.baseAPIUrl
        }
    },
    methods: {
        moveTo(x) {
            moveTo(x)
        },
        nextSection() {
            this.moveTo(4);
            this.$store.dispatch('wizard/updateWizard', {
                index: 1,
                value: 1
            });
        },
        stripTags(x) {
            return x && x.replace(/[<]br[^>]*[>]/gi, '')
        },
        clickSlide(index, real_index) {
            // use real_index
            var material = this.materials[real_index]
            console.log(material)
            this.chooseMaterial(material)
        },
        chooseMaterial(material) {
            if (this.current_image_main) this.$root.$emit('chooseMaterialFromMaterialSection', material);
            else if (material !== this.current_material) {
                this.$store.commit('cart/setCurrentFrame', null)
                this.$store.commit('cart/setCurrentEdge', null)
                this.$store.dispatch('cart/setCurrentMaterial', material)
            } 
        },
        showWizard() {
            var landing_section = document.getElementById('start-section'),
                wizard = document.getElementById('wizard-box');
            var rect = landing_section.getBoundingClientRect();
            if (window.scrollY > (rect.height - 100)) {
                wizard.style.display = 'block';
            } else {
                wizard.style.display = 'none';
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
.justify-center {
    align-items: center;
}
#material-section {
    min-width: 100%;
    height: 100%;
    width: 100%;
    min-height: 100vh;
    max-width: none;
    background: #E9E9E9;

    @media (max-width: 991px) {
        min-height: 500px;
    }

    .container-inner {
        padding-top: 70px;
        padding-bottom: 70px;

        @media (max-width: 1199px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        @media (max-width: 991px) {
            padding-right: 0;
            padding-left: 0;
        }

        @media (max-width: 768px) {
            padding-top: 40px;
            padding-bottom: 70px;
        }
    }

    .material-boxes {
        @media (max-width: 1199px) {
            flex-wrap: wrap;
        }

        .material-box-item {
            max-width: 12.5rem;

            @media (max-width: 1400px) and (min-width: 1200px) {
                margin-left: 0.5rem !important;
                margin-right: 0.5rem !important;
            }

            &:first-child {
                margin-left: 0 !important;
                padding-left: 0;
            }

            &:last-child {
                margin-right: 0 !important;
                padding-right: 0;
            }

            .material-box-image {
                overflow: hidden;
                transition: all .2s ease;
                border: 5px solid transparent;

                img {
                    transition: all .2s ease;
                    border-radius: 50%;

                    @media (max-width: 1199px) {
                        max-width: 9rem;
                    }
                }

                &:hover {
                    border: 3px solid #f7a600 !important;

                    img {
                        transform: scale(1.1);
                    }
                }
            }

            .item-material-active {
                .material-box-image {
                    border: 3px solid #f7a600 !important;
                }
            }

            .item-material-box-title {
                color: #F7A600;
                font-size: 1.25rem;
                line-height: 1.6rem;
                transition: color .2s ease;

                @media (max-width: 1400px) {
                    font-size: 1.2rem;
                    line-height: 1.4rem;
                }

                &:hover {
                    color: #FDBE3C;
                }
            }

            .item-material-box-description {
                color: #58585A;
                font-size: 1.125rem;
                line-height: 1.563rem;

                @media (max-width: 1400px) {
                    font-size: 1rem;
                    line-height: 1.4rem;
                }
            }
        }
    }

    .material-box-wrapper {
        max-width: 70%;

        @media (max-width: 1400px) {
            max-width: 90%;
        }

        @media (max-width: 1199px) {
            max-width: 100%;
        }

        .material-box {
            .material-title {
                font-size: 2.4rem
            }

            .material-box-img {
                position: relative;
                &:after {
                    position: absolute;
                   bottom:0;
                    right:0;
                    content: '';
                    width:92px;
                    height:70px;
                    background: transparent url('../assets/svg/batch-hd.svg') no-repeat top left;

                    @media (max-width: 1199px) {
                        display: none;
                    }
                }
                img {
                    max-width: 21.875rem;
                }
            }

            .material-title {
                @media (max-width: 1199px) {
                    font-size: 1.8rem;
                    line-height: 2rem;
                }

                @media (max-width: 991px) {
                    font-size: 2.188rem;
                    line-height: 2.5rem;
                }
            }

            .material-box-text {
                max-width: 35rem;
                font-size: 1.375rem;
                line-height: 2rem;
                color: #58585A;
                text-align: left;

                @media (max-width: 1199px) {
                    font-size: 1.1rem;
                    line-height: 1.5rem;
                    max-width: 100%;
                    text-align: center;
                }

                @media (max-width: 991px) {
                    font-size: 1.25rem;
                    line-height: 1.875rem;
                }
            }
        }
    }

    .material-swiper {
        margin-right: -2.25rem;
        margin-left: -2.25rem;

        .swiper {
            height: unset !important;
            width: 100%;
            padding-bottom: 0 !important;
            padding-top: 0 !important;
            color: #58585A;

            .swiper-wrapper {
                align-items: flex-end;
            }

            .item-material-box {
                padding-top: 3px;

                .material-box-image {
                    overflow: hidden;
                    transition: all .2s ease;
                    border: 2px solid transparent;

                    img {
                        max-width: 173px;
                        transition: all .2s ease;
                    }

                    &:hover {
                        border: 3px solid #f7a600;

                        img {
                            transform: scale(1.1);
                        }
                    }
                }

                &.item-material-active {
                    .material-box-image {
                        border: 3px solid #f7a600;
                    }
                }

                .item-material-box-title {
                    font-size: 1.063rem;
                    line-height: 1.625rem;
                    color: #F7A600;
                    max-width: 173px;
                }

                .item-material-box-description {
                    font-size: 0.938rem;
                    line-height: 1.25rem;
                    max-width: 173px;
                }
            }

            .swiper-slide {
                &:not(.swiper-slide-active) {
                    .item-material-box {
                        .material-box-image {
                            img {
                                max-width: 129px;
                            }
                        }
                    }
                }
            }
        }
    }

    .wizard-box {
        z-index: 999;
        transform: translateY(-50%);
        right: 44px;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #58585A;

        @media (max-width: 1600px) {
            right: 20px;
        }

        @media (max-width: 1200px) {
            right: 10px;
        }

        @media (max-width: 991px) {
            display: none !important;
        }

        b {
            font-size: 1rem;
            line-height: 1.25rem;

            @media (max-width: 1200px) {
                font-size: 0.9rem;
                line-height: 1.1rem;
            }
        }

        ul {
            li {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin: 0;
                font-size: 0.938rem;
                line-height: 1.25rem;
                margin-bottom: 2px;
                color: #BCBCBC;

                @media (max-width: 1200px) {
                    font-size: 0.8rem;
                    line-height: 1.1rem;
                }

                svg {
                    margin-left: 5px;

                    @media (max-width: 1200px) {
                        margin-left: 3px;
                    }
                }
            }

            &.active-section-1 li#wizard-item-1,
            &.active-section-2 li#wizard-item-2,
            &.active-section-3 li#wizard-item-3,
            &.active-section-4 li#wizard-item-4,
            &.active-section-5 li#wizard-item-5 {
                color: #58585A;
                font-weight: 600;

                svg {
                    color: #F7A600;
                }
            }
        }
    }
}
</style>
