import Vue from 'vue'
import shop from '../api/shop'
import config from '@/utils/config'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
  user_logged_in: null,
  user_data: {
    salutation: '',
    email: '',
    name: '',
    surname: '',
    company: '',
    billing_address: {
      address: '',
      street: '',
      city: '',
      postal_code: ''
    },
    shipping_address: {
      salutation: '',
      name: '',
      surname: '',
      company: '',
      address: '',
      street: '',
      city: '',
      postal_code: '',
      email: '',
      telephone: ''
    },
    telephone: ''
  },
  order_history: [],
  raw_user_data: null,
  raw_shipping_address: null,
  has_shipping_address: false
})

// getters
const getters = {
}

// actions
const actions = {
  updateUserStatus({dispatch, commit}, payload) {
    commit('updateUserStatus', payload);
  },
  getUser({dispatch, commit}, cb) {
    shop.getUser(data => {
      if (!data.data.mail) commit('updateUserStatus', false);
      else {
        dispatch('getShippingAddress', data.data.mail);
        dispatch('getOrderHistory', {
          email: data.data.mail,
          cb: cb
        });
        commit('updateUserStatus', true);
        commit('updateUserData', data.data);
        commit('updateRawUserData', data.data);
      } 
    });
  },
  getShippingAddress({commit}, email) {
    let user_email = email;
    shop.getAddresses(data => {
      if (data.data.length) {
        data.data.forEach(elt => {
          if (elt.email == user_email) {
            commit('updateShippingAddress', elt);
            commit('updateRawShippingAddress', elt);
          }
        });
      }
    });
  },
  getOrderHistory({commit}, payload) {
    let user_email = payload.email;

    shop.getOrderHistory(data => {
      if (data.data.length) {
        let orderItems = [];

        data.data.forEach((elt, i) => {
          if (elt.adressen.lieferanschrift.email == user_email || elt.adressen.rechnungsanschrift.email == user_email) {
            let date = elt.beleg.bezeichnung.split(" ");
            let orderProducts = [];

            elt.positionen.forEach((item, j) => {
              orderProducts.push({
                id: j,
                img_src: (item.druckdaten[0]) ? config.baseAPIUrl + item.druckdaten[0].pfad + item.druckdaten[0].vorschau : "",
                description: item.bez2
              })
            });

            orderItems.push({
              id: i,
              date: date[date.length - 1],
              order_id: elt.beleg.nummer,
              total: elt.preise.brutto,
              products: orderProducts
            });
          }
        });

        orderItems.sort(function(a,b){
          let dateArrA = a.date.split("."),
             dateArrB = b.date.split(".");
          return new Date(dateArrB[2]+"-"+dateArrB[1]+"-"+dateArrB[0]) - new Date(dateArrA[2]+"-"+dateArrA[1]+"-"+dateArrA[0]);
        });

        if (payload.cb) payload.cb();

        commit("updateOrderItems", orderItems);
      } else if (payload.cb) payload.cb();
    });
  }
}

// mutations
const mutations = {
  updateUserStatus(state, payload) {
    state.user_logged_in = payload
  },
  updateUserData(state, payload) {
    state.user_data.salutation = payload.anrede.text;
    state.user_data.email = payload.mail;
    state.user_data.surname = payload.anschrift1;
    state.user_data.name = payload.anschrift2;
    state.user_data.company = payload.anschrift3;
    state.user_data.billing_address.street = payload.strasse;
    state.user_data.billing_address.city = payload.ort;
    state.user_data.billing_address.postal_code = payload.plz;
    state.user_data.telephone = payload.telefon;
  },
  updateShippingAddress(state, payload) {
    state.user_data.shipping_address.salutation = payload.anrede.text;
    state.user_data.shipping_address.surname = payload.anschrift1;
    state.user_data.shipping_address.name = payload.anschrift2;
    state.user_data.shipping_address.street = payload.strasse;
    state.user_data.shipping_address.city = payload.ort;
    state.user_data.shipping_address.postal_code = payload.plz;
    state.user_data.shipping_address.company = payload.anschrift3;
    state.user_data.shipping_address.email = payload.email;
    state.user_data.shipping_address.telephone = payload.tel;
  },
  updateRawUserData(state, payload) {
    state.raw_user_data = payload;
  },
  updateOrderItems(state, payload) {
    state.order_history = payload;
  },
  updateRawShippingAddress(state, payload) {
    state.raw_shipping_address = payload;
    state.has_shipping_address = true;
  },
  clearUserData(state) {
    state.user_data = {
      salutation: '',
      email: '',
      name: '',
      surname: '',
      company: '',
      billing_address: {
        address: '',
        street: '',
        city: '',
        postal_code: ''
      },
      shipping_address: {
        salutation: '',
        name: '',
        surname: '',
        company: '',
        address: '',
        street: '',
        city: '',
        postal_code: '',
        email: '',
        telephone: ''
      },
      telephone: ''
    }
    state.raw_user_data = null;
    state.raw_shipping_address = null;
    state.has_shipping_address = false;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}