<template>
<b-overlay :show="uploading" rounded="sm">
    <b-container id="start-section" class="section container" fluid>
        <b-row>
            <b-col cols="12" lg="6" xl="8" class="text-left landing-hero">
                <b-navbar-brand href="./" class="ml-11 mt-8 mb-12 2xl:mb-0 hidden lg:block"><img class="nav-logo" src="../assets/svg/posterkoenig-logo.svg" /></b-navbar-brand>

                <div class="text-right">
                    <div class="circles text-center pt-8 xl:pt-2 mr-12 xl:mr-20 ml-12 xl:ml-40 hidden lg:flex items-center xl:items-end justify-center xl:justify-end">
                        <div class="circle-upload flex items-center justify-center text-white outline-hidden p-2 cursor-pointer rounded-full xl:-mr-9" @click="$bvModal.show('modal-upload-file')">
                            <div class="circle-inner p-3">
                                <img class="mx-auto" src="../assets/svg/cloud-computing.svg" />
                                <span class="circle-inner-header block my-3"><b>Eigenes Bild hochladen</b></span>
                                <span class="circle-inner-text">Klicke hier um dein Bild hochzuladen.</span>
                            </div>
                        </div>
                        <div class="circle-upload flex items-center justify-center text-white outline-hidden p-3 secondary mt-8 xl:mt-0 xl:-mb-32 float-left cursor-pointer rounded-full" @click="openSearchModal()">
                            <div class="circle-inner p-3">
                                <i class="fa-search fa-2x fas text-3xl mb-1" aria-hidden="true"></i>
                                <span class="circle-inner-text">Bildergalerie durchstöbern</span>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col cols="12" lg="6" xl="4" class="text-left overflow-hidden landing-right bg-white">
                <NavBar @show-loading="showLoading()"></NavBar>
                <div class="inner--text mr-4">
                    <h2 class="title primary main-title">
                        Wir drucken dein Foto auf Leinwand, Alu-Dibond, Acrylglas, Poster
                        und mehr
                    </h2>
                    <p class="landing-right-body pb-8 md:mb-5">
                        <b class="inline-block pb-2 intro">Dein Fotoprodukt – einfach, schnell, preiswert und gedruckt in der Schweiz.</b>
                        Bring deine schönsten Momente in den Mittelpunkt deiner Wohnung. Mit
                        einem hochwertigen Leinwandfoto, Fotoposter, Foto auf Acrylglas,
                        Foto auf Alu-Dibond, Foto auf Hartschaumplatte oder Gallery Print.
                        Lade ganz einfach dein Lieblingsfoto hoch oder besuche unsere
                        Galerie. Bestelle jetzt dein persönliches Foto auf Leinwand, Poster,
                        Alu-Dibond und mehr.
                    </p>
                    <div class="material-boxes-wrapper">
                        <b-row class="mt-5 mb-4 hidden lg:flex" v-for="(chunk, index) in materialsChunks" :key="index" :class="checkChunkLength(chunk)">
                            <b-col class="text-center landing-material-box" v-for="material in chunk" :key="material.image">
                                <span @click="chooseMaterial(material)">
                                    <img class="block" :src=" baseAPIUrl + material.image " />
                                    <span class="landing-material-box-title inline-block font-bold pt-2.5">{{material.reference}}</span>
                                </span>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="text-center md:mt-12">
                        <a @click.prevent="$bvModal.show('modal-upload-file')" class="btn btn-primary hidden lg:inline-block">
                            <img class="icon-shopping-cart inline h-4" src="../assets/shopping-cart.svg" />
                            Jetzt bestellen
                        </a>

                        <a @click.prevent="$bvModal.show('modal-upload-file')" class="btn btn-primary inline-block lg:hidden">
                            Bild hochladen
                        </a>
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-modal id="modal-upload-file" :hide-header="true" :hide-footer="true" centered>
            <div class="basic-modal modal-upload-file w-full h-full absolute left-0 bg-white" :class="{ 'modal-search-active': modalSearchActive }">
                <div class="modal-close absolute top-4 md:top-7 right-7" @click="$bvModal.hide('modal-upload-file')">
                    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" version="1.1" class="inline-block cursor-pointer">
                        <g stroke="rgb(112, 112, 112)" stroke-width="1">
                            <line x1="0" y1="0" x2="20" y2="20" />
                            <line x1="0" y1="20" x2="20" y2="0" />
                        </g>
                    </svg>
                </div>
                <div class="upload-file-section pb-6 md:pb-12 mb-6 md:mb-14 px-6 md:px-8 text-center" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input class="hidden" type="file" @change="onChange" ref="file" id="upload-file" accept="image/png, image/jpeg" />

                    <div class="upload-file-section-inner">
                        <img class="inline-block mb-4" src="../assets/svg/cloud-computing-yellow.svg" />
                        <p class="upload-file-title font-bold">Eigenes Bild hochladen</p>
                        <p class="upload-file-text max-w-md mx-auto mb-3 md:pb-10">
                            Auf «Datei auswählen» klicken oder per Drag&Drop direkt in
                            diesen Rahmen ziehen
                        </p>
                        <label id="upload-image-button" for="upload-file" class="block cursor-pointer">
                            <a class="btn btn-primary mt-1 mb-2.5 btn-mobile">Datei auswählen</a>
                        </label>
                        <p class="upload-file-info max-w-sm mx-auto">
                            Erlaubte Dateiformate: JPG, PNG, GIF, maximal 500 MB. Farbmodus:
                            RGB (keine CMYK-Bilder)
                        </p>
                    </div>
                </div>
                <div class="circle-upload flex items-center justify-center text-white outline-hidden secondary p-3 text-center mx-auto cursor-pointer rounded-full" @click="toggleSearchModal()">
                    <div class="circle-inner p-3">
                        <i class="fa-search fa-2x fas text-3xl mb-0.5" aria-hidden="true"></i>
                        <span class="circle-inner-text">Bildergalerie durchstöbern</span>
                    </div>
                </div>
            </div>

            <div class="basic-modal modal-search-picture w-full h-full absolute left-0 bg-white" :class="{ 'modal-search-active': modalSearchActive }">
                <b-overlay :show="searching" rounded="sm">
                    <div class="modal-navigation flex justify-between flex-wrap md:flex-nowrap lg:block mb-2 md:mb-4 lg:mb-0">
                        <div class="modal-arrow lg:absolute top-7 left-7 flex algin-items-center cursor-pointer" @click="toggleSearchModal()">
                            <img style="max-height: 45px;" src="../assets/svg/icon-arrow-down.svg" />
                            <p class="ml-3.5">Eigenes Bild<br>hochladen</p>
                        </div>

                        <h2 class="title text-center mb-3.5 md:mb-7 mt-10 md:mt-0 order-1 md:order-none w-full md:w-auto">Gib dein gewünschtes Motiv ein</h2>

                        <div class="modal-close lg:absolute top-7 right-7" @click="toggleSearchModal()">
                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" version="1.1" class="inline-block cursor-pointer">
                                <g stroke="rgb(112, 112, 112)" stroke-width="1">
                                    <line x1="0" y1="0" x2="20" y2="20" />
                                    <line x1="0" y1="20" x2="20" y2="0" />
                                </g>
                            </svg>
                        </div>
                    </div>

                    <b-input-group class="justify-center mb-14">
                        <b-form-input v-model="keyword" placeholder="Suchbegriff" class="w-full md:w-auto md:mr-3" @keyup.enter="resetUnsplash()"></b-form-input>
                        <a @click="resetUnsplash()" class="btn btn-primary mt-3.5 md:mt-0">Suchen</a>
                    </b-input-group>

                    <div class="unsplash-images-container">
                        <div v-if="result.length">
                            <div class="unsplash-images-row" v-for="(chunk, index) in resultChunks" :key="`chunk-${index}`">
                                <b-row>
                                    <b-col v-for="img in chunk" :key="img.id" cols="3" class="unsplash-img-col mb-6">
                                        <img :src="img.urls.thumb" class="unsplash-img cursor-pointer block" :class="{ active: img == selectedImg }" @click="chooseImage(img)" />
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>

                    <div class="upload-image-button text-right mt-6">
                        <a @click="acceptChoosenImage()" class="btn btn-primary" v-if="result.length" :class="{ disabled: !selectedImg }">Bild wählen</a>
                    </div>
                </b-overlay>
            </div>
        </b-modal>

        <b-toast id="toast-image-uploaded" solid no-close-button>
            <div class="toast-image-uploaded-content text-white relative">
                <img class="absolute cursor-pointer" src="../assets/svg/button-close-grey.svg" @click="$bvToast.hide('toast-image-uploaded')" />
                <p>Das Bild wurde erfolgreich hochgeladen</p>
            </div>
        </b-toast>

        <b-toast id="toast-image-too-small" solid no-close-button>
            <div class="toast-image-uploaded-content toast-image-too-small-content text-white relative">
                <img class="absolute cursor-pointer" src="../assets/svg/button-close-grey.svg" @click="$bvToast.hide('toast-image-too-small')" />
                <p>
                    Die Auflösung deines Bildes ist zu niedrig.<br>
                    Bitte lade ein Bild hoch das mind. 800x800 Pixel vorweist
                </p>
            </div>
        </b-toast>
    </b-container>
</b-overlay>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import axios from 'axios'
import {
    setupCache
} from 'axios-cache-adapter'

// Create `axios-cache-adapter` instance
const cache = setupCache({
    maxAge: 15 * 60 * 1000
})

import {
    mapState
} from 'vuex'
import {
    moveTo
} from '@/utils/fullpage'
import {
    loadProgressBar
} from 'axios-progress-bar'
import {
    scaleImage
} from '@/utils/resize.js'
import config from '@/utils/config'

const custom_axios = axios.create({
    baseURL: 'https://pk.conseo-test.ch',
    adapter: cache.adapter
})

function chunk(arr, chunkSize) {
    if (chunkSize <= 0) throw 'Invalid chunk size'
    if (!arr) return []

    var R = []
    for (var i = 0; i < arr.length; i += chunkSize)
        R.push(arr.slice(i, i + chunkSize))
    return R
}

export default {
    name: 'LandingSection',
    props: {
        msg: String
    },
    components: {
        NavBar
    },
    data() {
        return {
            keyword: '',
            selectedImg: null,
            result: [],
            currentPage: 1,
            filelist: [],
            uploading: false,
            modalSearchActive: false,
            searching: false,
            baseAPIUrl: config.baseAPIUrl
        }
    },
    computed: {
        resultChunks() {
            return chunk(this.result, 4)
        },
        materialsChunks() {
            return chunk(this.materials, 3)
        },
        ...mapState({
            materials: state => state.shop.materials,
            current_material: state => state.cart.current_material,
            user_logged_in: state => state.user.user_logged_in
        })
    },
    created() {
        loadProgressBar(null, custom_axios)

        this.$store.dispatch('shop/getSession').then(() => {

            this.$store.dispatch('shop/getMaterials', items => {
                if (!items || !items.length) {
                    document.cookie.split(';').forEach(function (c) {
                    document.cookie = c
                        .replace(/^ +/, '')
                        .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
                    });
                    console.log('cookie deleted - because session expired for materials');
                } else {
                    console.log(items)
                    if (items && items.length) {
                        if (!this.current_material) this.$store.dispatch('cart/setCurrentMaterial', items[0])
                        else this.$store.dispatch('cart/setCurrentMaterial', items[items.findIndex(e => e.id == this.current_material.id)])
                    }
                }
            })

            this.$store.dispatch('user/getUser');
        })
    },
    mounted() {
        if (window.location.hash == '#upload-foto' || window.location.hash == '#_upload-foto') {
            setTimeout(() => {
                this.$bvModal.show('modal-upload-file')
            }, 700);
        }
    },
    methods: {
        moveTo(x) {
            moveTo(x)
        },
        resetUnsplash() {
            this.currentPage = 1;
            this.result = [];
            this.searchKeyword();
        },
        searchKeyword() {
            this.searching = true;
            axios
                .get(
                    'https://api.unsplash.com/search/photos?client_id=wVRZaNI_lXxjNklKgVyEjfo7_L5HFFXS6w9aBnt10XQ&orientation=landscape&page=' + this.currentPage + '&per_page=30&lang=de&query=' +
                    encodeURIComponent(this.keyword)
                )
                .then(r => {
                    this.searching = false;
                    console.log(r)
                    r.data.results.forEach(elt => {
                        this.result.push(elt)
                    });
                })
        },
        chooseImage(img) {
            console.log(img)
            this.selectedImg = img
        },
        acceptChoosenImage() {
            this.$bvModal.hide('modal-upload-file')
            this.$store.commit('cart/setCurrentImage', this.selectedImg.urls.full)
            this.$store.commit('cart/setCurrentPreviewImage', this.selectedImg.urls.full);

            var memoryImg = document.createElement('img')
            memoryImg.onload = () => {
                var width = memoryImg.width
                var height = memoryImg.height
                this.$store.commit('cart/setCurrentImageSize', {
                    width: width,
                    height: height
                })
            }
            memoryImg.src = this.selectedImg.urls.full
            this.$store.dispatch('wizard/updateWizard', {
                index: 0,
                value: 1
            })
            moveTo(3)
        },
        onChange() {
            this.filelist = []
            var files = [...this.$refs.file.files]
            let uploadSuccess = true
            this.uploading = true
            this.$bvModal.hide('modal-upload-file')

            let fileReader = new FileReader();
            fileReader.onload = () => {
                var img = new Image;
                img.onload = () => {
                    if (img.width > 800 || img.height > 800) {
                        scaleImage(files[0], 800).then(blob => {
                            files.push(new File([blob], files[0].name, {
                                type: files[0].type
                            }));

                            files.forEach((file, i) => {
                                if (file.type == 'image/jpeg' || file.type == 'image/png') {
                                    var fileReader = new FileReader()
                                    fileReader.onloadend = fileLoadedEvent => {
                                        var imageData = fileLoadedEvent.target.result
                                        this.filelist.push(imageData)
                                        // <upload>
                                        var form = new FormData()
                                        form.append('file', file)
                                        custom_axios
                                            .post(
                                                '/wp-content/plugins/posterkoenig-plugin/api.php/upload',
                                                form, {
                                                    headers: {
                                                        'Content-Type': 'multipart/form-data'
                                                    }
                                                }
                                            )
                                            .then(r => {
                                                console.log(r);
                                                var data = r.data
                                                if (!data.url) {
                                                    document.cookie.split(';').forEach(function (c) {
                                                        document.cookie = c
                                                            .replace(/^ +/, '')
                                                            .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
                                                        });
                                                    console.log('cookie deleted - because session expired for materials');
                                                    return
                                                }

                                                if (i == 0) {
                                                    this.$bvToast.show('toast-image-uploaded')

                                                    this.$store.commit('cart/setCurrentImage', data.url);

                                                    var memoryImg = document.createElement('img')
                                                    memoryImg.onload = () => {
                                                        var width = memoryImg.width
                                                        var height = memoryImg.height
                                                        this.$store.commit('cart/setCurrentImageSize', {
                                                            width: width,
                                                            height: height
                                                        })
                                                    }
                                                    memoryImg.src = data.url
                                                    moveTo(3)
                                                    this.$store.dispatch('wizard/updateWizard', {
                                                        index: 0,
                                                        value: 1
                                                    })
                                                    this.uploading = false;
                                                } else {
                                                    this.$store.commit('cart/setCurrentPreviewImage', data.url);
                                                }

                                            })
                                        // </upload>
                                    }
                                    fileReader.readAsDataURL(file)
                                } else {
                                    alert(
                                        'Der Upload dieses Dateityps ist nicht erlaubt. Bitte verwende nur JPG oder PNG'
                                    );
                                    this.uploading = false;
                                    uploadSuccess = false
                                }
                            })
                            if (uploadSuccess) {
                                setTimeout(() => {
                                    console.log('file change')
                                    console.log(this.filelist)
                                }, 1000)
                            }
                        });
                    } else {
                        this.uploading = false;
                        this.$bvToast.show('toast-image-too-small');
                    }
                };
                img.src = fileReader.result;
            };
            fileReader.readAsDataURL(files[0]);
        },
        dragover(event) {
            // https://stenvdb.be/articles/building-a-vuejs-drag-and-drop-file-component
            event.preventDefault()
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('drag-hover')) {
                event.currentTarget.classList.add('drag-hover')
            }
        },
        dragleave(event) {
            event.currentTarget.classList.remove('drag-hover')
        },
        drop(event) {
            event.preventDefault()
            this.$refs.file.files = event.dataTransfer.files
            this.onChange() // Trigger the onChange event manually
            event.currentTarget.classList.remove('drag-hover')
        },
        toggleSearchModal() {
            this.bindScrollEvent();
            this.modalSearchActive = !this.modalSearchActive
        },
        checkChunkLength(chunk) {
            if (chunk.length < 3) return "row-smaller";
        },
        uploadImageToggle() {
            document.getElementById('upload-image-button').click();
        },
        openSearchModal() {
            this.$bvModal.show('modal-upload-file');
            this.bindScrollEvent();
            if (!this.modalSearchActive) this.toggleSearchModal();
        },
        bindScrollEvent() {
            setTimeout(() => {
                const imagesContainer = document.querySelector('.unsplash-images-container')
                if (imagesContainer) {
                    imagesContainer.addEventListener('scroll', () => {
                        if (imagesContainer.offsetHeight + imagesContainer.scrollTop >= imagesContainer.scrollHeight) {
                            this.currentPage++;
                            this.searchKeyword();
                        }
                    })
                }
            }, 1000)
        },
        showLoading() {
            this.uploading = !this.uploading;
        },
        chooseMaterial(material) {
            if (this.current_image_main) this.$root.$emit('chooseMaterialFromMaterialSection', material);
            else if (material !== this.current_material) {
                this.$store.commit('cart/setCurrentFrame', null)
                this.$store.commit('cart/setCurrentEdge', null)
                this.$store.dispatch('cart/setCurrentMaterial', material)
            }
            moveTo(3);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
@import '../../node_modules/axios-progress-bar/dist/nprogress.css';

#start-section {
    .landing-hero {
        min-height: 100vh;
        background: url(../assets/landing-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: 991px) {
            min-height: 500px;
        }

        @media (max-width: 1620px) and (min-width: 1200px) {
            max-width: 60%;
            flex: 0 0 60%;
        }

        @media (max-width: 991px) {
            min-height: 50vh;
            background-position: center !important;
        }

        @media (max-width: 768px) {
            min-height: 420px;
        }

        .navbar-brand {
            @media (max-width: 1200px) and (min-width: 992px) {
                margin-left: 1rem !important;
                margin-top: 1rem !important;
            }

            .nav-logo {
                height: auto;
                max-width: 300px;

            }
        }

        .circles {
            @media (max-width: 1200px) {
                flex-direction: column;
            }

            .circle-upload {
                width: 21.875rem;
                height: 21.875rem;
                background: #f8a500;
                transition: all 0.2s ease;

                .circle-inner {
                    .circle-inner-header {
                        font-size: 2rem;
                        line-height: 2.313rem;
                    }

                    .circle-inner-text {
                        font-size: 1.25rem;
                        line-height: 1.563rem;
                    }
                }

                &:hover,
                &:active,
                &:focus {
                    background: #333;
                    border-color: transparent;
                }

                &.secondary {
                    background: #4f4f4f;
                    border-color: transparent;
                    width: 9.375rem;
                    height: 9.375rem;

                    &:hover,
                    &:active,
                    &:focus {
                        background: #f8a500;
                        border-color: transparent;
                    }

                    .circle-inner-text {
                        font-size: 1.125rem;
                        line-height: 1.563rem;
                    }
                }
            }
        }
    }

    .landing-right {
        padding: 1rem 3rem;
        z-index: 9;

        @media (max-width: 1620px) and (min-width: 1200px) {
            max-width: 40%;
            flex: 0 0 40%;
        }

        @media (max-width: 991px) {
            padding: 3rem 2rem;
        }

        @media (max-width: 768px) {
            padding: 1.25rem 2rem 4rem;
        }

        .title {
            &.main-title {
                margin-bottom: 1.25rem;

                @media (max-width: 1900px) {
                    font-size: 2.5rem;
                    line-height: 2.813rem;
                }

                @media (max-width: 1350px) {
                    font-size: 2rem;
                    line-height: 2.25rem;
                }

                @media (max-width: 768px) {
                    font-size: 1.75rem;
                    line-height: 2.188rem;
                }
            }
        }

        .landing-right-body {
            font-size: 1.125rem;
            line-height: 1.5rem;
            color: #58585A;

            .intro {
                font-size: 1.375rem;
                line-height: 1.75rem;

                @media (max-width: 768px) {
                    font-size: 1.375rem;
                    line-height: 1.813rem;
                }
            }
        }

        .material-boxes-wrapper {
            .landing-material-box {
                .landing-material-box-title {
                    font-size: 1rem;
                    line-height: 1.25rem;
                    color: #58585a;
                }

                span {
                    transition: all .2s ease;
                }

                img {
                    transition: all .2s ease;
                    margin: 0 auto;
                    width: 7.5rem;
                    border-radius: 50%;
                }

                &:hover {
                    cursor:pointer;
                    span {
                        color: #f7a600;
                    }

                    img {
                        transform: scale(1.1);
                    }
                }
            }

            .row-smaller {
                justify-content: center;

                .landing-material-box {
                    padding-left: 30px;
                    padding-right: 30px;
                    width: unset;
                    max-width: unset;
                    flex: unset;
                }
            }
        }

        .icon-shopping-cart {
            margin-top: -5px;
            margin-right: 5px;
        }

        .inner--text {
            padding-right: 4rem;

            @media (max-width: 1620px) {
                padding-right: 2rem;
            }

            @media(max-width: 1200px) {
                padding-right: 0;
            }

            @media (max-width: 991px) {
                padding-right: 0;
            }

            @media (max-width: 768px) {
                padding-right: 0;
            }
        }
    }

}

.modal {
    .modal-content {
        border-radius: 0;
        outline: none !important;
        font-family: 'Titillium Web', Avenir, Helvetica, Arial, sans-serif !important;
    }
}

.modal-open {
    .modal {
        opacity: 1;
    }
}

#modal-upload-file {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;

    @media (max-width: 768px) {
        padding-left: 0.75rem !important;
        padding-right: 0.75rem !important;
    }

    .modal-dialog {
        max-width: 964px;

        .modal-body {
            .basic-modal {
                color: #58585a;
            }
        }
    }
}

#modal-upload-file {
    .modal-body {
        padding: 3.5rem 3.125rem;
        overflow: hidden;
        min-height: 800px;

        @media (max-width: 768px) {
            min-height: 620px;
        }

        .basic-modal {
            &.modal-upload-file {
                top: 0;
                padding: 3.5rem 3.125rem;
                transition: all 0.5s ease;

                @media (max-width: 768px) {
                    padding: 3.5rem 1.5rem 2rem;
                }

                &.modal-search-active {
                    top: -100%;
                }
            }

            &.modal-search-picture {
                bottom: -100%;
                padding: 3.5rem 3.125rem;
                transition: all 0.5s ease;

                @media (max-width: 768px) {
                    padding: 3rem 2rem 3rem;
                }

                &.modal-search-active {
                    bottom: 0;
                }
            }

            .upload-file-section {
                border-radius: 2.188rem;
                border: 2px dashed #f7a600;
                padding-top: 4.5rem;

                @media (max-width: 768px) {
                    padding-top: 1.75rem;
                }

                img {
                    @media (max-width: 768px) {
                        max-width: 80px;
                    }
                }

                &.drag-hover {
                    background-color: #333;
                }

                .upload-file-title {
                    font-size: 1.375rem;
                    line-height: 2.125rem;
                }

                .upload-file-text {
                    font-size: 1.375rem;
                    line-height: 1.875rem;

                    @media (max-width: 768px) {
                        font-size: 1.1rem;
                        line-height: 1.5rem;
                        margin-top: 0.5rem;
                    }
                }

                .upload-file-info {
                    font-size: 0.938rem;
                    line-height: 1.25rem;
                }
            }

            .circle-upload {
                background: #4f4f4f;
                border-color: transparent;
                width: 9.375rem;
                height: 9.375rem;
                transition: all 0.2s ease;
                font-size: 1.125rem;
                line-height: 1.563rem;

                @media (max-width: 768px) {
                    width: 7.5rem;
                    height: 7.5rem;
                    font-size: 0.9rem;
                    line-height: 1.2rem;
                }

                &:hover,
                &:active,
                &:focus {
                    background: #7c7c7b;
                    border-color: #7c7c7b;
                }
            }
        }
    }
}

.basic-modal.modal-search-picture {
    .modal-arrow {
        font-size: 0.938rem;
        line-height: 1.25rem;
    }

    .title {
        font-size: 2.188rem;
        line-height: 2.5rem;

        @media (max-width: 768px) {
            font-size: 1.5rem;
            line-height: 1.85rem;
        }
    }

    .btn {
        min-width: 183px;
    }

    input {
        max-width: 372px;
        font-family: 'Titillium Web', Avenir, Helvetica, Arial, sans-serif !important;
        border-radius: 19px !important;
        color: #58585a;
        font-size: 1.125rem;
        line-height: 1.563rem;
        height: 38px;
        padding: 0.2rem 1.063rem;

        @media (max-width: 768px) {
            max-width: unset;
            width: 100%;
        }
    }

    ::placeholder {
        color: #bcbcbc;
    }

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: #bcbcbc;
        border-radius: 6px;
    }

    .unsplash-images-container {
        max-height: 440px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-left: -3.125rem;
        margin-right: -3.125rem;
        padding-left: 3.125rem;
        padding-right: calc(3.125rem - 8px);
        scrollbar-color: #bcbcbc transparent;
        scrollbar-width: thin;

        @media (max-width: 768px) {
            max-height: 200px;
        }

        .row {
            margin-left: -12px;
            margin-right: -12px;

            .unsplash-img-col {
                padding: 0 8px;

                img {
                    transition: all 0.2s ease;
                    border: 5px solid transparent;

                    &.active {
                        background-color: #007bff;
                        border: 5px solid #007bff;
                    }
                }
            }
        }
    }
}

.b-toaster.b-toaster-top-right {
    right: 0;
    top: 3.75rem;

    @media (max-width: 768px) {
        top: 6rem;
    }

    .b-toaster-slot {
        max-width: 450px;

        @media (max-width: 768px) {
            max-width: 300px;
        }

        .b-toast {
            max-width: 450px;

            @media (max-width: 768px) {
                max-width: 300px;
            }

            #toast-image-uploaded, #toast-image-too-small {
                max-width: 450px;
                border-color: #f7a600;
                background-color: #f7a600;
                box-shadow: none;
                border-radius: 10px 0 0 10px;

                @media (max-width: 768px) {
                    max-width: 300px;
                }

                .toast-body {
                    padding: 0;

                    .toast-image-uploaded-content, .toast-image-too-small-content {
                        padding: 1.25rem 1rem 1.25rem 2.5rem;
                        font-size: 1.375rem;
                        line-height: 2.125rem;

                        @media (max-width: 768px) {
                            padding: 0.75rem 1rem 0.75rem 1.5rem;
                            font-size: 1.2rem;
                            line-height: 1.75rem;
                        }

                        img {
                            top: -14px;
                            left: -14px;
                        }
                    }
                }
            }

            #toast-image-too-small {
                border-color: #fd3406;
                background-color: #fd3406;
                
                .toast-body {
                    .toast-image-too-small-content {
                        font-size: 1.2rem;
                        line-height: 1.75rem;
                    }
                }
            }
        }
    }
}

#nprogress {
    .bar {
        background: rgb(255, 165, 0) !important;
    }

    .peg {
        box-shadow: 0 0 10px rgb(255, 165, 0), 0 0 5px rgb(255, 165, 0) !important;
    }

    .spinner-icon {
        border-top-color: rgb(255, 165, 0) !important;
        border-left-color: rgb(255, 165, 0) !important;
    }
}
</style>
