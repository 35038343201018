import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import cart from './cart'
import shop from './shop'
import wizard from './wizard'
import user from './user'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        cart,
        shop,
        wizard,
        user
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],
})

export default store
