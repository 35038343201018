import Vue from 'vue'
// initial state
// shape: [{ id, quantity }]
const state = () => ({
  wizard_status: [0, 0, 0, 0, 0]
})

// getters
const getters = {
}

// actions
const actions = {
  updateWizard({dispatch, commit}, payload) {
    commit('updateWizard', payload);
  },
}

// mutations
const mutations = {
  updateWizard(state, payload) {
    if ((payload.index == 0 && payload.value == 1) || (payload.index == 0 && payload.value == 0)) {
      state.wizard_status = [0, 0, 0, 0, 0];
    }
    Vue.set(state.wizard_status, payload.index, payload.value);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}