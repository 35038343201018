import shop from '../api/shop'
import {
  changeDpiBlob
} from 'changedpi'
import axios from 'axios'
import {
  addBorder,
  scaleImage,
  dataURLtoFile
} from '@/utils/resize.js'

import ConvertAPI from 'convertapi'
import {
  setupCache
} from 'axios-cache-adapter'
import config from '@/utils/config'

// Create `axios-cache-adapter` instance
const cache = setupCache( {
  maxAge: 15 * 60 * 1000
} )

const custom_axios = axios.create( {
  baseURL: 'https://pk.conseo-test.ch',
  adapter: cache.adapter
} )

// initial state
// shape: [{ id, quantity }]
const state = () => ( {
  current_material: null,
  current_item: null, // from shop.getMaterialDetails // ->["item number"]
  current_material_detail: null,
  current_image: null,
  current_preview_image: null,
  current_image_size: {},
  current_image_full_size: {},
  current_frame: null,
  current_edge: null,
  current_edge_color: {
    rgba: 'rgba(255, 165, 0, .95)',
    hex: '#ffa500'
  },
  color_picker: {
    hex: '#ffa500',
    rgba: {
      r: 255,
      g: 165,
      b: 0,
      a: 0.95
    },
    hsl: {
      h: 39,
      s: 1,
      l: 0.5
    }
  },
  current_size: null,
  previews_size: null,
  current_bleed: 0,
  current_format: 'best',
  current_output_image: null,
  current_image_file: null,
  current_image_file_full: null,
  current_shipping_method: 'mail', // 'mail' | 'visit'
  current_payment_method: 1,
  address: {},
  billing_address: {
    salutation: '',
    company: '',
    name: '',
    surname: '',
    address_line_1: '',
    address_line_2: '',
    email: '',
    telephone: '',
    street: '',
    postal_code: '',
    city: ''
  },
  shipping_address: {
    salutation: '',
    company: '',
    name: '',
    surname: '',
    address_line_1: '',
    address_line_2: '',
    email: '',
    telephone: '',
    street: '',
    postal_code: '',
    city: ''
  },
  note: '',
  items: [],
  checkoutStatus: null,
  cart_items: [],
  cart_costs: {
    shipping: 14,
    tax: 0,
    total_price: 0
  },
  discount: {
    value: 0,
    name: '',
    enteredName: ''
  },
  cart_last_position: null,
  comment: '',
  selected_options_frame: false,
  selected_options_edge: false,
  selected_options_size: false,
  show_preview: false
} )

// getters
const getters = {
  totalPrice: ( state, getters ) => {
    let result = 0,
      shipping_method = state.current_shipping_method
    state.cart_items.forEach( x => {
      result += x.price * x.count
    } )
    if ( shipping_method == 'mail' ) {
      result += state.cart_costs.shipping
    }
    return result
  },
  totalItems: ( state, getters ) => {
    let result = 0
    state.cart_items.forEach( x => {
      result += x.count
    } )
    return result
  },
  currentSize: state => state.current_size
}

// actions
const actions = {
  setCurrentMaterial( {
    dispatch,
    commit
  }, material ) {
    commit( 'setCurrentMaterial', material )
  },
  setCurrentItem( {
    dispatch,
    commit
  }, item ) {
    commit( 'setCurrentItem', item )
  },
  setCurrentOutputImage( {
    dispatch,
    commit
  }, item ) {
    commit( 'setCurrentOutputImage', item )
  },
  setCurrentImageFile( {
    dispatch,
    commit
  }, item ) {
    commit( 'setCurrentImageFile', item )
  },
  setCurrentImageFileFull( {
    dispatch,
    commit
  }, item ) {
    commit( 'setCurrentImageFileFull', item )
  },
  setAddress( {
    dispatch,
    commit
  }, item ) {
    commit( 'setAddress', item )
  },
  setNote( {
    dispatch,
    commit
  }, item ) {
    commit( 'setNote', item )
  },
  setShippingMethod( {
    dispatch,
    commit
  }, shipping_method ) {
    commit( 'setShippingMethod', shipping_method )
  },
  setPaymentMethod( {
    dispatch,
    commit
  }, payment_method ) {
    commit( 'setPaymentMethod', payment_method )
  },
  setComment( {
    commit
  }, comment ) {
    commit( 'setComment', comment )
  },
  submitBasket( {
    state,
    dispatch,
    commit
  } ) {
    var data = {
      items: state.items,
      address: {
        ...state.address,
        notes: state.note
      },
      shipping_method: state.current_shipping_method
    }
    console.log( 'submitBasket' )
    console.log( data )
    shop.orderBasket( data )
  },
  getCurrentMaterialDetail( {
    commit
  }, material_id ) {
    console.log( 'getCurrentMaterialDetail' )
    console.log( material_id )
    shop.getMaterialDetails( material_id, detail => {
      console.log( 'getCurrentMaterialDetail' )
      console.log( detail )
      commit( 'setCurrentMaterialDetail', detail )
    } )
  },
  addToBasket( {
    state,
    commit
  }, cb ) {
    let itemObj = {
      amount: 1,
      id: state.current_item.nr
    }

    shop.addToCart( itemObj, data => {
      commit( 'setCartLastPosition', data.data.last_position )
      scaleImage( state.current_image_file, 600 ).then( blob => {
        let img_file = new File( [ blob ], state.current_image_file.name, {
          type: state.current_image_file.type
        } )

        shop.uploadImage( img_file, data.data.last_position, () => {
          shop.getCart( data => {
            commit( 'resetFrameAndEdge' )
            cb( data )
          } )
        } )
      } )
    } )
  },
  updateBasketItems( {
    state,
    commit
  }, data ) {
    let cartItems = []
    data.data.positionen.forEach( elt => {
      if ( !elt.auto && !elt.fixed ) {
        cartItems.push( {
          id: elt.pos,
          price: elt.preise.einzel_brutto,
          count: elt.menge,
          title: elt.bez,
          text: elt.bez2,
          image: config.baseAPIUrl +
            elt.druckdaten[ 0 ].pfad +
            elt.druckdaten[ 0 ].vorschau,
          width: elt.additional[
            elt.additional.findIndex( e => e.name == 'uploaded_image_width' )
          ].value,
          height: elt.additional[
            elt.additional.findIndex( e => e.name == 'uploaded_image_height' )
          ].value,
          final_image: elt.additional[
            elt.additional.findIndex( e => e.name == 'final_uploaded_image' )
          ].value
        } )
      }
    } )
    commit( 'updateBasketItems', cartItems )
    commit( 'updateCartCosts', {
      shipping: data.data.versandart.brutto,
      tax: data.data.preise.steuer,
      total_price: data.data.preise.brutto
    } )
  },
  removeFromBasket( {
    commit
  }, payload ) {
    shop.removeFromCart( payload.item.id, data => {
      console.log( data )
      commit( 'removeFromBasket', payload.item )
      payload.cb()
    } )
  },
  removeCoupon( {
    commit
  }, cb ) {
    shop.getCart( data => {
      let couponPos = []
      data.data.positionen.forEach( elt => {
        if ( !elt.auto && elt.fixed ) couponPos = elt.pos
      } )

      shop.removeFromCart( couponPos, data => {
        commit( 'updateCartCosts', {
          shipping: data.data.versandart.brutto,
          tax: data.data.preise.steuer,
          total_price: data.data.preise.brutto
        } )
        cb( data )
      } )
    } )
  },
  updateBasketCount( {
    state,
    commit
  }, cb ) {
    shop.getCart( data => {
      let basketData = data.data
      basketData.positionen.forEach( ( e1, i ) => {
        state.cart_items.forEach( e2 => {
          if ( e1.pos == e2.id ) basketData.positionen[ i ].menge = e2.count
        } )
      } )

      basketData.bemerkung = 'test'

      shop.updateBasketItems( basketData, data => {
        data.data.versandart.typ.value = (state.current_shipping_method == 'mail') ? 3 : 10;
        shop.updateBasketAddress(data.data, data => {
          console.log(data)
          cb (data)
        })
      } )
    } )
  },
  applyCoupon( {
    state
  }, payload ) {
    shop.applyCoupon( payload.coupon, data => {
      payload.cb( data )
    } )
  },
  saveAddress( {
    commit
  }, payload ) {
    commit( 'updateBillingAddress', {
      salutation: payload.billing_address.salutation,
      company: payload.billing_address.company || '',
      name: payload.billing_address.name,
      surname: payload.billing_address.surname,
      address_line_1: payload.billing_address.street,
      address_line_2: payload.billing_address.postal_code +
        ' ' +
        payload.shipping_address.street,
      email: payload.billing_address.email,
      telephone: payload.billing_address.telephone,
      street: payload.billing_address.street,
      postal_code: payload.billing_address.postal_code,
      city: payload.billing_address.city
    } )
    commit( 'updateShippingAddress', {
      salutation: payload.shipping_address.salutation,
      company: payload.shipping_address.company || '',
      name: payload.shipping_address.name,
      surname: payload.shipping_address.surname,
      address_line_1: payload.shipping_address.street,
      address_line_2: payload.shipping_address.postal_code + ' ' + payload.shipping_address.street,
      email: payload.shipping_address.email,
      telephone: payload.shipping_address.telephone,
      street: payload.shipping_address.street,
      postal_code: payload.shipping_address.postal_code,
      city: payload.shipping_address.city
    } )
    payload.cb()
  },
  addAddressToCart( {
    state,
    commit
  }, payload ) {
    shop.getCart( data => {
      data.data.adressen.lieferanschrift.anrede.value = state.shipping_address.salutation
      data.data.adressen.lieferanschrift.anschrift1 = state.shipping_address.surname
      data.data.adressen.lieferanschrift.anschrift2 = state.shipping_address.name
      data.data.adressen.lieferanschrift.anschrift3 = ( state.shipping_address.salutation == 1 ) ? state.shipping_address.company : ''
      data.data.adressen.lieferanschrift.email = state.shipping_address.email
      data.data.adressen.lieferanschrift.land.value = 'CH'
      data.data.adressen.lieferanschrift.ort = state.shipping_address.city
      data.data.adressen.lieferanschrift.plz = state.shipping_address.postal_code
      data.data.adressen.lieferanschrift.strasse = state.shipping_address.street
      data.data.adressen.lieferanschrift.tel = state.shipping_address.telephone

      data.data.adressen.rechnungsanschrift.anrede.value = state.billing_address.salutation
      data.data.adressen.rechnungsanschrift.anschrift1 = state.billing_address.surname
      data.data.adressen.rechnungsanschrift.anschrift2 = state.billing_address.name 
      data.data.adressen.rechnungsanschrift.anschrift3 = ( state.billing_address.salutation == 1 ) ? state.billing_address.company : ''
      data.data.adressen.rechnungsanschrift.email = state.billing_address.email
      data.data.adressen.rechnungsanschrift.land.value = 'CH'
      data.data.adressen.rechnungsanschrift.ort = state.billing_address.city
      data.data.adressen.rechnungsanschrift.plz = state.billing_address.postal_code
      data.data.adressen.rechnungsanschrift.strasse = state.billing_address.street
      data.data.adressen.rechnungsanschrift.tel = state.billing_address.telephone

      data.data.bemerkung = state.comment

      shop.updateBasketAddress( data.data, data => {
        console.log( data )
        payload.cb()
      } )
    } )
  },
  uploadUpscaledImage( {
    rootState,
    state,
    commit
  }, cb ) {
    let width = state.current_image_full_size.width, // current width in px
      height = state.current_image_full_size.height, // current height in px
      destWidth = ( 150 * state.current_size.width ) / 2.54, // desired width in px
      destHeight = ( 150 * state.current_size.height ) / 2.54, // desired height in px
      ratioX =
        ( 2.54 * state.current_image_full_size.width ) /
        150 /
        state.current_size.width, // how much smaller is the current image from the desired size
      ratioY =
        ( 2.54 * state.current_image_full_size.height ) /
        150 /
        state.current_size.height, // how much smaller is the current image from the desired size
      bleed = state.current_bleed, // save for reset
      reset = false,
      resetBleed = false,
      doubleBorder = false

    // add the grey border for leinwand and poster
    let materialName = state.current_material.reference.toLowerCase()
    if ( ( materialName.includes( 'leinwand' ) || materialName.includes( 'poster' ) ) && state.current_edge_color.hex == '#ffa500' ) {
      if ( state.current_material.reference.toLowerCase().includes( 'leinwand' ) ) state.current_bleed = 5
      else if ( state.current_material.reference.toLowerCase().includes( 'poster' ) ) state.current_bleed = 0.2

      width += ( ( 150 * bleed ) / 2.54 ) * ratioX // make the image bigger by bleed
      height += ( ( 150 * bleed ) / 2.54 ) * ratioY // make the image bigger by bleed
      destWidth += ( 150 * bleed ) / 2.54 // make the image bigger by bleed
      destHeight += ( 150 * bleed ) / 2.54 // make the image bigger by bleed
      state.current_edge_color.hex = '#808080'
      reset = true
    }
    // add the grey border on top of normal border for leinwand
    if ( materialName.includes( 'leinwand' ) && !reset ) {
      let frameName = rootState.shop.frames
        .filter( e => {
          return e.id == state.current_frame
        } )[ 0 ]
        .reference.toLowerCase()
      if ( frameName.includes( '2cm' ) || frameName.includes( '2 cm' ) )
        state.current_bleed = 10
      // change the bleed according to the frame
      else if ( frameName.includes( '4cm' ) || frameName.includes( '4 cm' ) )
        state.current_bleed = 18 // change the bleed according to the frame
      resetBleed = true
      doubleBorder = true
    }

    //rescale the bleed by the ratio
    let bleedX = ( ( 150 * state.current_bleed ) / 5.08 ) * ratioX,
      bleedY = ( ( 150 * state.current_bleed ) / 5.08 ) * ratioY

    //make the output larger by the bleed
    destWidth += ( 150 * state.current_bleed ) / 2.54
    destHeight += ( 150 * state.current_bleed ) / 2.54

    console.log( 'border color ', state.current_edge_color.hex );

    if ( materialName.includes( 'alu' ) ) {
      //remove border for foto auf alu
      bleedX = 0;
      bleedY = 0;
    }

    //draw the border
    addBorder(
      state.current_image_file_full,
      width,
      height,
      bleedX,
      bleedY,
      state.current_edge_color.hex,
      doubleBorder
    ).then( function ( blob ) {
      //convert to a file
      let file = new File( [ blob ], state.current_image_file_full.name, {
        type: state.current_image_file_full.type
      } )
      let fileReader = new FileReader()

      //process image with ConvertAPI
      shop.processImageConvertAPI( file, destWidth, destHeight, data => {
        console.log( data )

        //convert dataURL to a file
        let newFile = dataURLtoFile(
          'data:text/plain;base64,' + data[ 'data' ][ 'Files' ][ 0 ][ 'FileData' ],
          data[ 'data' ][ 'Files' ][ 0 ][ 'FileName' ]
        )

        console.log( newFile )
        fileReader.onloadend = fileLoadedEvent => {
          // upload
          let form = new FormData()
          form.append( 'file', newFile )
          custom_axios
            .post(
              '/wp-content/plugins/posterkoenig-plugin/api.php/upload',
              form, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
            )
            .then( r => {
              console.log( 'uploaded file 150dpi', r )
              commit( 'setFinalImage', r.data.url )
              cb()
            } )
        }
        fileReader.readAsDataURL( newFile )
      } )
    } )

    //reset the values if grey border is needed
    if ( reset ) {
      state.current_edge_color.hex = '#ffa500'
      state.current_bleed = bleed
    }

    if ( resetBleed ) state.current_bleed = bleed
  },
  uploadFilesToFTP( {
    state
  }, payload ) {
    for ( let i = 0; i < state.cart_items.length; i++ ) {
      custom_axios
        .get( state.cart_items[ i ].final_image, {
          responseType: 'arraybuffer'
        } )
        .then( r => {
          let fileStream =
            'data://text/plain;base64,' +
            Buffer.from( r.data, 'binary' ).toString( 'base64' )
          let fileName =
            'order-' + payload.orderId + '-image-' + ( i + 1 ) + '.jpg'

          shop.uploadToFTP( fileStream, fileName, data => {
            console.log( data )
            if ( i == state.cart_items.length - 1 ) payload.cb()
          } )
        } )
    }
  },
  addFinalImage( {
    state
  }, data ) {
    data.positionen.forEach( elt => {
      if ( elt.pos == state.cart_last_position ) {
        elt.additional[
          elt.additional.findIndex( e => e.name == 'final_uploaded_image' )
        ].value = state.current_item.bild
        elt.additional[
          elt.additional.findIndex( e => e.name == 'uploaded_image_width' )
        ].value = state.current_size.width
        elt.additional[
          elt.additional.findIndex( e => e.name == 'uploaded_image_height' )
        ].value = state.current_size.height
      }
    } )

    shop.updateBasketItems( data, data => {
      console.log( 'basket added image', data )
    } )
  }
}

// mutations
const mutations = {
  setCurrentImage( state, image ) {
    state.current_image = image
  },
  setCurrentImageSize( state, dim ) {
    state.current_image_size = dim
  },
  setCurrentImageFullSize( state, dim ) {
    state.current_image_full_size = dim
  },
  setCurrentOutputImage( state, image ) {
    state.current_output_image = image
  },
  setCurrentPreviewImage( state, image ) {
    state.current_preview_image = image
  },
  setFinalImage( state, image ) {
    state.current_item.bild = image
  },
  setCurrentImageFile( state, file ) {
    state.current_image_file = file
  },
  setCurrentImageFileFull( state, file ) {
    state.current_image_file_full = file
  },
  setCurrentMaterial( state, material ) {
    state.current_material = material
  },
  setCurrentMaterialDetail( state, detail ) {
    state.current_material_detail = detail
  },
  setCartBleed( state, bleed ) {
    state.current_bleed = bleed
  },
  setCurrentFormat( state, format ) {
    state.current_format = format
  },
  setCurrentItem( state, detail ) {
    state.current_item = detail
  },
  setAddress( state, address ) {
    state.address = address
  },
  setNote( state, note ) {
    state.note = note
  },
  setShippingMethod( state, shipping_method ) {
    state.current_shipping_method = shipping_method
  },
  setPaymentMethod( state, payment_method ) {
    state.current_payment_method = payment_method
  },
  setComment( state, comment ) {
    state.comment = comment
  },
  setCurrentFrame( state, frame ) {
    state.current_frame = frame
  },
  setCurrentEdge( state, edge ) {
    state.current_edge = edge
  },
  setCurrentEdgeColor( state, payload ) {
    console.log( payload )
    state.current_edge_color.hex = payload.hex
    state.current_edge_color.rgba = payload.rgba
  },
  setColorPicker( state, payload ) {
    state.color_picker.hex = payload.hex
    state.color_picker.rgba = payload.rgba
    state.color_picker.hsl = payload.hsl
  },
  setCurrentSize( state, size ) {
    state.current_size = size
  },
  setPreviewsSize( state, size ) {
    state.previews_size = size
  },
  resetFrameAndEdge( state ) {
    console.log( 'resetting' )
    state.current_frame = null
    state.current_edge = null
  },
  updateBasketItems( state, data ) {
    state.cart_items = data
  },
  removeFromBasket( state, item ) {
    let index = state.cart_items.findIndex( _item => _item.id === item.id )
    state.cart_items.splice( index, 1 )
  },
  updateCartCosts( state, payload ) {
    state.cart_costs.shipping = payload.shipping
    state.cart_costs.tax = payload.tax
    state.cart_costs.total_price = payload.total_price
  },
  updateDiscount( state, payload ) {
    state.discount.value = payload.value
    state.discount.name = payload.name
    state.discount.enteredName = payload.enteredName
  },
  updateBillingAddress( state, payload ) {
    state.billing_address = payload
  },
  updateShippingAddress( state, payload ) {
    state.shipping_address = payload
  },
  setCartLastPosition( state, pos ) {
    state.cart_last_position = pos
  },
  updateSelectedOptions( state, pos ) {
    if ( pos == 1 ) state.selected_options_frame = true
    else if ( pos == 2 ) state.selected_options_edge = true
    else if ( pos == 3 ) state.selected_options_size = true
  },
  showPreview( state ) {
    state.show_preview = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}