<template>
  <li class="accordion__item block text-right py-3.5 md:py-3 px-9 md:px-14 m-0 relative">
    <div class="accordion__trigger cursor-pointer" :class="{'accordion__trigger_active': visible}" @click="open">
      <slot name="accordion-trigger"></slot>
    </div>

    <transition name="accordion" @enter="start" @after-enter="end" @before-leave="start" @after-leave="end">
      <div class="accordion__content" v-show="visible">
        <ul>
          <slot name="accordion-content"></slot>
        </ul>
      </div>
    </transition>
  </li>
</template>


<script>
export default {
  props: {},
  inject: ["Accordion"],
  data() {
    return {
      index: null
    };
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    }
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    }
  },
  created() {
    this.index = this.Accordion.count++;
  }
};
</script>

<style lang="scss" scoped>
    .accordion__item {
        border-bottom: 1px solid #707070;
    }

    .accordion__trigger {
        font-size: 1.563rem;
        line-height: 1;
    }

    .accordion-enter-active,
    .accordion-leave-active {
        will-change: height, opacity;
        transition: height 0.5s ease, opacity 0.5s ease;
        overflow: hidden;
    }

    .accordion-enter,
    .accordion-leave-to {
        height: 0 !important;
        opacity: 0;
    }
</style>
