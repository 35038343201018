<template>
<b-overlay :show="processing" rounded="sm">
  <b-container id="buy-section" class="container section-lines" fluid>
    <SideLine title="Übersicht & Zahlung" />

    <div class="container-inner">
      <h2
        class="title sm:text-center lg:text-left mb-3.5 md:mb-8 xl:mb-12 pb-0.5"
      >
        Übersicht & Zahlung
      </h2>

      <b-row class="buy-row text-left buy-headings no-border hidden md:flex">
        <b-col class="buy-col buy-smaller-col" cols="4" lg="3" xl="2">
          <p class="font-bold">Vorschau</p>
        </b-col>

        <b-col class="buy-col" cols="4" lg="3">
          <p class="font-bold">Produkt</p>
        </b-col>

        <b-col class="buy-col" cols="2" xl="3">
          <p class="font-bold">Anzahl</p>
        </b-col>

        <b-col class="buy-col hidden lg:block" cols="2">
          <p class="font-bold">Preis pro Stück</p>
        </b-col>

        <b-col class="buy-col mr-0 ml-auto text-right" cols="2">
          <p class="font-bold">Preis</p>
        </b-col>
      </b-row>

      <div class="buy-items" v-for="item in cart_items" :key="item.id">
        <b-row class="buy-row text-left py-3 hidden md:flex">
          <b-col class="buy-col buy-smaller-col" cols="4" lg="3" xl="2">
            <img :src="item.image" />
          </b-col>

          <b-col
            class="buy-col buy-bigger-text flex justify-center flex-col"
            cols="4"
            lg="3"
          >
            <p>{{ item.title}}</p>
            <p>{{ formatText(item.text, 0) }}</p>
            <p>{{ formatText(item.text, 1) }}</p>
            <p>{{ formatText(item.text, 2) }}</p>
            <p>{{ item.width }} x {{ item.height }} cm</p>
           <!--<p><b>Anzahl:</b> {{ item.count }}</p>--> 
          </b-col>

          <b-col
            class="buy-col buy-bigger-text flex align-items-center"
            cols="2"
            xl="3"
          >
            <span class="inline-block quantity">{{ item.count }} Stück</span>
          </b-col>

          <b-col
            class="buy-col align-items-center buy-bigger-text hidden lg:flex"
            cols="2"
          >
            <p>CHF {{ roundPrice(item.price, 2) }}</p>
          </b-col>

          <b-col
            class="buy-col flex align-items-center justify-end mr-0 ml-auto buy-bigger-text"
            cols="2"
          >
            <p>CHF {{ roundPrice(item.price * item.count , 2) }}</p>
          </b-col>
        </b-row>

        <b-row class="buy-row text-left py-3 flex md:hidden">
          <b-col class="buy-col" cols="12">
            <div class="buy-mobile-product-info flex">
              <div class="buy-mobile-image flex-1 pr-1.5">
                <img :src="item.image" />
              </div>

              <div class="buy-mobile-info-inside flex-1 pl-1.5">
                <p>{{ formatText(item.text, 0) }}</p>
                <p>{{ formatText(item.text, 1) }}</p>
                <p>{{ formatText(item.text, 2) }}</p>
                <p>{{ item.width }} x {{ item.height }} cm</p>
                <p><b>Anzahl:</b> {{ item.count }}</p>

                <p class="item-price mt-3">CHF {{ roundPrice(item.price * item.count , 2) }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-row v-if="discount.name" class="buy-row text-left buy-row-spacing">
        <b-col class="buy-col buy-smaller-col hidden lg:block" cols="3" xl="2">
        </b-col>

        <b-col class="buy-col buy-bigger-text" cols="8" lg="4" xl="3">
          <p>
            Gutschein «{{ discount.name }}»
          </p>
        </b-col>

        <b-col class="buy-col hidden lg:block" cols="2" xl="3"> </b-col>

        <b-col class="buy-col hidden lg:block" cols="1" xl="2"> </b-col>

        <b-col
          class="buy-col mr-0 ml-auto text-right buy-bigger-text"
          cols="4"
          lg="2"
        >
          <p v-if="discount.value != 0">CHF - {{ roundPrice(discount.value  , 2) }}</p>
        </b-col>
      </b-row>

      <b-row class="buy-row text-left buy-row-spacing">
        <b-col class="buy-col buy-smaller-col hidden lg:block" cols="3" xl="2">
        </b-col>

        <b-col class="buy-col buy-bigger-text" cols="8" lg="4" xl="3">
          <p v-if="current_shipping_method == 'mail'">Porto &amp; Verpackung</p>
          <p v-if="current_shipping_method == 'visit'">
            Abholung in Eschenbach LU
          </p>
        </b-col>

        <b-col class="buy-col hidden lg:block" cols="2" xl="3"> </b-col>

        <b-col class="buy-col hidden lg:block" cols="1" xl="2"> </b-col>

        <b-col
          class="buy-col mr-0 ml-auto text-right buy-bigger-text"
          cols="4"
          lg="2"
        >
          <p v-if="current_shipping_method == 'mail'">
            CHF {{ roundPrice(shipping_costs  , 2) }}
          </p>
          <p v-if="current_shipping_method == 'visit'">Gratis</p>
        </b-col>
      </b-row>

      <b-row class="buy-row text-left buy-row-spacing">
        <b-col class="buy-col buy-smaller-col hidden lg:block" cols="3" xl="2">
        </b-col>

        <b-col class="buy-col buy-bigger-text" cols="8" lg="4" xl="3">
          <p>Nettobetrag</p>
        </b-col>

        <b-col class="buy-col hidden lg:block" cols="2" xl="3"> </b-col>

        <b-col class="buy-col hidden lg:block" cols="1" xl="2"> </b-col>

        <b-col
          class="buy-col mr-0 ml-auto text-right buy-bigger-text"
          cols="4"
          lg="2"
        >
          <p>CHF {{ roundPrice((total_price - discount.value) - (total_price - discount.value) * 0.077, 2) }}</p>
        </b-col>
      </b-row>

      <b-row class="buy-row text-left buy-row-spacing">
        <b-col class="buy-col buy-smaller-col hidden lg:block" cols="3" xl="2">
        </b-col>

        <b-col class="buy-col buy-bigger-text" cols="8" lg="4" xl="3">
          <p>MwSt 7.7%</p>
        </b-col>

        <b-col class="buy-col hidden lg:block" cols="2" xl="3"> </b-col>

        <b-col class="buy-col hidden lg:block" cols="1" xl="2"> </b-col>

        <b-col
          class="buy-col mr-0 ml-auto text-right buy-bigger-text"
          cols="4"
          lg="2"
        >
          <p>CHF {{ roundPrice((total_price - discount.value) * 0.077, 2) }}</p>
        </b-col>
      </b-row>

      <div class="buy-total text-right pt-7 mb-6 md:mb-8">
        <div class="total-wrapper flex justify-between md:inline-block pb-3.5">
          <p class="font-bold inline-block">Total</p>
          <p class="font-bold inline-block md:ml-24">
            CHF {{ roundPrice(total_price - discount.value , 2) }}
          </p>
        </div>
      </div>

      <h3 class="title-small font-bold text-left mb-1 mt-16 md:mt-0">
        Wähle deine Zahlart
      </h3>

      <b-row class="buy-row payment-row text-left no-border">
        <b-col
          class="buy-col payment-methods-col pt-3 pb-12 md:pb-0"
          cols="12"
          md="8"
          lg="6"
          xl="4"
        >
          <div class="payment-method-wrapper inline-block">
            <div
              class="payment-method cursor-pointer text-center"
              :class="{ activeArt: current_payment_method == 1 }"
              @click="setPaymentMethod(1)"
            >
              <div
                class="payment-img-wrapper rounded-full mx-auto bg-white flex align-items-center justify-center"
              >
                <img
                  class="inline-block"
                  src="../assets/svg/icon-invoice.svg"
                />
              </div>
              <p class="mt-3.5">Bezahlung auf Rechnung</p>
            </div>
          </div>

          <div class="payment-method-wrapper inline-block">
            <div
              v-if="false"
              class="payment-method cursor-pointer text-center"
              :class="{ activeArt: current_payment_method == 2 }"
              @click="setPaymentMethod(2)"
            >
              <div
                class="payment-img-wrapper rounded-full mx-auto bg-white flex align-items-center justify-center"
              >
                <img
                  class="inline-block"
                  src="../assets/svg/icon-credit-card.svg"
                />
              </div>
              <p class="mt-3.5">Bezahlung mit Kreditkarte</p>
            </div>
          </div>

          <div class="payment-method-wrapper inline-block">
            <div
              v-if="false"
              class="payment-method cursor-pointer text-center"
              :class="{ activeArt: current_payment_method == 3 }"
              @click="setPaymentMethod(3)"
            >
              <div
                class="payment-img-wrapper rounded-full mx-auto bg-white flex align-items-center justify-center"
              >
                <img
                  class="inline-block"
                  src="../assets/svg/icon-cash-payment.svg"
                />
              </div>
              <p class="mt-3.5">Barzahlung bei Abholung</p>
            </div>
          </div>
        </b-col>

        <b-col
          class="buy-col payment-smaller-col buy-col-address-1"
          cols="6"
          md="4"
          lg="2"
        >
          <div class="delivery-address">
            <p>
              <span class="font-bold block">Rechnungsadresse</span>
              <span class="buy-bigger-text" v-if="true">
                <span v-if="(billing_address.salutation == 1)">
                  {{ billing_address.company }}<br/></span>               
                  {{ billing_address.name }} {{ billing_address.surname }}<br/>
                {{ billing_address.address_line_1 }}<br />
                {{billing_address.postal_code}} {{ billing_address.city }}<br />              
              </span>
            </p>
          </div>

          <div class="delivery-address hidden md:block xl:hidden mt-3">
            <p>
              <span class="font-bold block">Lieferadresse</span>
              <span class="buy-bigger-text" v-if="true">
                <span v-if="(shipping_address.salutation == 1)">
                {{ shipping_address.company }}<br /></span>
                {{ shipping_address.name }} {{ shipping_address.surname }}<br/>
                {{ shipping_address.address_line_1 }}<br />
                {{shipping_address.postal_code}} {{ shipping_address.city }}<br />
              </span>
            </p>
          </div>
        </b-col>

        <b-col
          class="buy-col payment-smaller-col md:hidden xl:block buy-col-address-2"
          cols="6"
          md="2"
        >
          <div class="delivery-address">
            <p>
              <span class="font-bold block">Lieferadresse</span>
              <span class="buy-bigger-text" v-if="true">
                <span v-if="(shipping_address.salutation == 1)">
                {{ shipping_address.company }}<br /></span>
                {{ shipping_address.name }} {{ shipping_address.surname }}<br/>
                {{ shipping_address.address_line_1 }}<br />
                {{shipping_address.postal_code}} {{ shipping_address.city }}<br />
              </span>
            </p>
          </div>
        </b-col>

        <b-col
          class="buy-col payment-acceptence-col pt-6 md:pt-0"
          cols="12"
          lg="4"
        >
          <div class="acceptence-section">
            <div class="checkbox-wrapper relative mt-24">
              <input
                type="checkbox"
                name="acceptence"
                v-model="acceptence"
                id="checkbox-acceptence"
                class="custom-checkbox"
              />
              <label for="checkbox-acceptence" class="custom-checkbox-label"
                >Ich akzeptiere die
                <a href="/agb" class="font-bold" target="_blank">AGBs</a></label
              >
            </div>

            <div
              class="acceptence-buttons flex pt-14 md:mt-3.5 md:pt-2.5 lg:pt-0 lg:mt-10 justify-center w-full md:w-auto"
            >
              <a
                @click.prevent="moveTo(1)"
                class="btn mr-3 mb-0 btn-hollow btn-mobile"
              >
                Abbrechen
              </a>
              <a
                @click.prevent="submitBasket()"
                class="btn mb-0 btn-primary btn-mobile"
                :class="{ disabled: orderValid() }"
              >
                Jetzt bestellen
              </a>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
  </b-overlay>
</template>

<script>
import SideLine from '../components/SideLine.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { moveTo } from '@/utils/fullpage'
import shop from '../api/shop'

export default {
  name: 'BuySection',
  props: {
    msg: String
  },
  components: {
    SideLine
  },
  data() {
    return {
      selected: '',
      coupon: '',
      comment: '',
      acceptence: false,
      processing: false
    }
  },
  computed: {
    ...mapState({
      cart_items: state => state.cart.cart_items,
      billing_address: state => state.cart.billing_address,
      shipping_address: state => state.cart.shipping_address,
      current_shipping_method: state => state.cart.current_shipping_method,
      current_payment_method: state => state.cart.current_payment_method,
      shipping_costs: state => state.cart.cart_costs.shipping,
      discount: state => state.cart.discount,
      price_tax: state => state.cart.cart_costs.tax,
      price_total: state => state.cart.cart_costs.total_price,
      user_logged_in: state => state.user.user_logged_in
    }),
    ...mapGetters({
      total_price: 'cart/totalPrice',
      total_items: 'cart/totalItems'
    })
  },
  methods: {
    moveTo(x) {
      moveTo(x)
    },
    cleanText(val) {
      return val.split(' - weiter')[0]
    },
    ...mapActions({
      submitBasket: 'cart/submitBasket'
    }),
    setPaymentMethod(val) {
      this.$store.dispatch('cart/setPaymentMethod', val)
    },
    formatText(text, index) {
      return text.split(', ')[index]
    },
    submitBasket() {
      this.processing = true;
      this.$store.dispatch('cart/addAddressToCart', {
          cb: () => {
            shop.orderBasket(data => {
              if (data.status && data.beleg) {
                this.$store.dispatch('cart/uploadFilesToFTP', {
                  orderId: data.beleg.belegnummer,
                  cb: () => {
                    this.processing = false;
                    sessionStorage.clear();
                    window.location.replace('https://pk.conseo-test.ch/zahlung-rechnung/')
                  }
                });
              }
            })
          }
      })

    },
    roundPrice(number, decimal) {
      return parseFloat(number).toFixed(decimal)
    },
    orderValid() {
      if (this.acceptence && (this.billing_address.name.length || this.billing_address.company.length) && (this.shipping_address.name.length || this.shipping_address.company.length)) return false;
      else return true;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
#buy-section {
  &.container {
    background: #f2f2f2;
    color: #58585a;

    .container-inner {
      padding: 40px 170px;

      @media (max-width: 1600px) {
        padding: 40px 90px;
      }

      @media (max-width: 1200px) {
        padding: 40px;
      }

      @media (max-width: 991px) {
        padding: 40px 0 70px;
      }

      .buy-row {
        margin-left: 0px;
        margin-right: 0px;
        border-bottom: 1px solid #707070;

        &.buy-row-spacing {
          padding-top: 1.75rem;
          padding-bottom: 1.75rem;

          @media (max-width: 1200px) {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
          }

          @media (max-width: 768px) {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
        }

        &.no-border {
          border-bottom: none;
        }

        &.buy-headings {
          font-size: 1.125rem;
          line-height: 1.25rem;

          @media (max-width: 1199px) {
            font-size: 1rem;
            line-height: 1.45rem;
          }
        }

        &.payment-row {
          .payment-methods-col {
            @media (max-width: 768px) {
              display: flex;
            }

            .payment-method-wrapper {
              max-width: 103px;
              font-size: 0.938rem;
              line-height: 1.25rem;
              margin-right: 1.25rem;
              margin-bottom: 0.75rem;

              @media (max-width: 768px) {
                max-width: 90px;
                margin-right: 1rem;
              }

              &:last-child {
                margin-right: 0;
              }

              .payment-method {
                &:hover,
                &.activeArt {
                  .payment-img-wrapper {
                    border: 4px solid #f7a600;
                  }
                }
              }

              .payment-img-wrapper {
                height: 103px;
                transition: all 0.2s ease;

                @media (max-width: 768px) {
                  max-height: 90px;
                }
              }
            }
          }
        }

        .buy-col {
          padding-left: 10px;
          padding-right: 10px;

          @media (max-width: 768px) {
            padding-left: 0;
            padding-right: 0;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }

          &.payment-methods-col {
            @media (max-width: 1599px) and (min-width: 1200px) {
              flex: 0 0 41.666667%;
              max-width: 41.666667%;
            }
          }

          &.buy-smaller-col {
            max-width: 245px;
          }

          &.payment-smaller-col {
            @media (min-width: 1300px) {
              max-width: 200px;
            }

            span {
              word-break: break-all;
            }
          }

          &.buy-bigger-text {
            font-size: 1.125rem;
            line-height: 1.563rem;

            @media (max-width: 1199px) {
              font-size: 1rem;
              line-height: 1.45rem;
            }

            @media (max-width: 768px) {
              font-size: 0.938rem;
              line-height: 1.25rem;
            }
          }

          &.payment-acceptence-col {
            @media (max-width: 1599px) and (min-width: 1200px) {
              flex: 0 0 25%;
              max-width: 25%;
            }

            @media (max-width: 991px) {
              padding-left: 0;
            }
          }

          &.buy-col-address-1 {
            @media (max-width: 768px) {
              padding-right: 10px;
            }
          }

          &.buy-col-address-2 {
            @media (max-width: 768px) {
              padding-left: 10px;
            }
          }

          .buy-mobile-product-info {
            font-size: 0.938rem;
            line-height: 1.25rem;
          }
        }
      }

      .buy-total {
        font-size: 1.125rem;
        line-height: 1.25rem;

        .total-wrapper {
          border-bottom: 6px double #707070;
        }
      }

      .title-small {
        font-size: 2.188rem;
        line-height: 3.125rem;

        @media (max-width: 768px) {
          font-size: 2rem;
          line-height: 3rem;
        }
      }

      .acceptence-section {
        @media (min-width: 1851px) {
          padding-left: 4rem;
        }

        @media (max-width: 1850px) {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        @media (max-width: 991px) {
          align-items: flex-start;
        }

        .checkbox-wrapper {
          @media (max-width: 1850px) {
            margin-top: 0 !important;
          }

          .custom-checkbox-label {
            margin-bottom: 0;
            cursor: pointer;
            color: #58585a;
            margin-left: 35px;
            font-size: 1.125rem;
            line-height: 1.563rem;

            &::before {
              content: '';
              display: inline-block;
              width: 24px;
              height: 24px;
              border: 1px solid #707070;
              position: absolute;
              left: 0;
            }
          }

          .custom-checkbox {
            appearance: none;
            display: none;
          }

          .custom-checkbox:checked + .custom-checkbox-label {
            &::after {
              content: '';
              display: inline-block;
              height: 16px;
              width: 16px;
              position: absolute;
              left: 4px;
              top: 4px;
              background: #f7a600;
            }
          }

          a {
            &:hover,
            &:focus,
            &:visited {
              text-decoration: none;
              color: unset;
            }
          }
        }

        .acceptence-buttons {
          @media (max-width: 1850px) {
            flex-direction: column;
          }

          @media (max-width: 991px) {
            flex-direction: row;
          }

          .btn {
            max-width: 14.125rem;

            &:first-child {
              @media (max-width: 1850px) {
                margin-right: 0 !important;
                margin-bottom: 0.75rem !important;

                @media (max-width: 991px) {
                  margin-bottom: 0 !important;
                  margin-right: 1.25rem !important;
                }
              }
            }

            &.disabled {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
</style>
