<template>
    <b-overlay :show="addingToBasket" rounded="sm">
        <b-container id="editor-section" class="container section-lines relative" fluid>
            <SideLine title="Konfiguration" />
            <div class="container-inner flex flex-col">
                <div class="text-center">
                    <h2 class="title">Konfiguriere dein Bild</h2>
                </div>

                <b-row class="text-center mt-10 xl:mt-12 mb-9 hidden lg:flex">
                    <b-col></b-col>
                    <b-col cols="8" class="editor-buttons">
                        <b-row>
                            <b-col class="px-2 button-col" v-if="current_material" cols="6">
                                <span class="option-number option-number-1 block mb-2">1.</span>
                                <span class="option-title block mb-3">Material</span>
                                <button class="option-button btn btn-primary w-100" @click="$bvModal.show('modal-materialien')">
                                    {{ current_material.reference }}
                                </button>
                            </b-col>
                            <b-col class="px-2 button-col" v-if="frame_options && frame_options.length" cols="6">
                                <span class="option-number option-number-1 block mb-2">1.</span>
                                <span class="option-number option-number-2 block mb-2">2.</span>
                                <span class="option-title block mb-3">{{ getMaterialEdgeName() }}</span>
                                <button class="option-button btn w-100" :class="frame_is_selected ? 'btn-primary' : 'btn-secondary'" @click="$bvModal.show('modal-frame')">
                                    <template v-if="selected_frame && getCurrentFrame.reference">{{ getCurrentFrame.reference }}</template>
                                    <template v-if="selected_frame && !getCurrentFrame.reference">{{ getMaterialEdgeName() }} wählen</template>
                                </button>
                            </b-col>
                            <b-col class="px-2 button-col" v-if="edge_options && edge_options.length" cols="6">
                                <span class="option-number option-number-1 block mb-2">1.</span>
                                <span class="option-number option-number-2 block mb-2">2.</span>
                                <span class="option-number option-number-3 block mb-2">3.</span>
                                <span class="option-title block mb-3">
                                  Kantendruck
                                    <span class="edge-color-picker inline-flex align-items-end ml-2 absolute cursor-pointer" v-if="current_edge_is_kolor" @click="toggleColorPicker">
                                        <span class="color-picker-color w-5 h-5 rounded-full inline-block mr-1.5" :style="{ 'background-color': color_picker.hex }"></span>
                                        <img class="inline-block" src="../assets/svg/color-picker.svg" />
                                    </span>
                                </span>

                                <button class="option-button btn w-100" :class="edge_is_selected ? 'btn-primary' : 'btn-secondary'" @click="$bvModal.show('modal-edge')">
                                    <span v-if="selected_edge && getCurrentEdge.reference">{{ getCurrentEdge.reference }}</span>
                                    <span v-if="selected_edge && !getCurrentEdge.reference">Kantendruck wählen</span>
                                </button>
                            </b-col>
                            <b-col class="px-2 button-col" v-if="size_options" cols="6">
                                <span class="option-number option-number-1 block mb-2">1.</span>
                                <span class="option-number option-number-2 block mb-2">2.</span>
                                <span class="option-number option-number-3 block mb-2">3.</span>
                                <span class="option-number option-number-4 block mb-2">4.</span>
                                <span class="option-title block mb-3">Grösse</span>

                                <button class="option-button btn w-100" :class="size_is_selected ? 'btn-primary' : 'btn-secondary'" @click="$bvModal.show('modal-size')">
                                    <span v-if="selected_size">{{ selected_size.width }}x{{ selected_size.height }}</span>
                                    <span v-if="!selected_size">Grösse wählen</span>
                                </button>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="flex items-end justify-end material-price-wrapper text-right">
                        <div class="text-left inline-block" v-if="selected_size">
                            <p>Preis</p>
                            <p class="material-price font-bold">
                                CHF
                                {{ (Math.round(selected_size.price * 100) / 100).toFixed(2) }}
                            </p>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="text-center flex lg:hidden mt-8">
                    <b-col cols="12" md="6" v-if="current_material">
                        <p class="select-label text-left mb-1">Material</p>
                        <vSelect class="select-normal" label="title" v-model="selected_material" :options="formatMaterialOptions()" @input="updateMaterial">
                            <template slot="option" slot-scope="option">
                                <span class="font-bold">{{ option.title }}</span>
                            </template>
                        </vSelect>
                    </b-col>

                    <b-col cols="12" md="6" v-if="frame_options && frame_options.length">
                        <p class="select-label text-left mb-1">{{ getMaterialEdgeName() }}</p>
                        <vSelect class="select-normal" label="title" v-model="selected_frame_text" :options="formatFrameOptions()">
                            <template slot="option" slot-scope="option">
                                <span class="font-bold">{{ option.title }}</span>
                            </template>
                        </vSelect>
                    </b-col>

                    <b-col cols="12" md="6" v-if="edge_options && edge_options.length">
                        <p class="select-label text-left mb-1">Kantendruck</p>
                        <vSelect class="select-normal" label="title" v-model="selected_edge_text" :options="formatEdgeOptions()">
                            <template slot="option" slot-scope="option">
                                <span class="font-bold">{{ option.title }}</span>
                            </template>
                        </vSelect>
                    </b-col>

                    <b-col cols="12" class="block md:hidden mb-3" v-if="current_edge_is_kolor">
                        <div class="color-picker-mobile-wrapper py-8 px-4 bg-white">
                            <colorpicker :width="250" :height="250" v-model="colorPickerValueMobile" class="mx-auto"></colorpicker>
                            <div class="selected-color-info flex align-items-center justify-center my-3">
                                <p class="mr-2">Gewählte Farbe:</p>
                                <svg class="inline-block" height="32" width="32">
                                    <circle cx="16" cy="16" r="15" :fill="color_picker.hex" />
                                </svg>
                            </div>
                            <p>{{ color_picker.hex }}</p>
                        </div>
                    </b-col>

                    <b-col class="md:order-1" cols="12" v-if="size_options">
                        <p class="select-label text-left mb-3.5">Format wählen</p>

                        <div class="format-options format-options-mobile grid grid-cols-2 md:grid-cols-4 text-center mb-9">
                            <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'best' }" @click="changeFormat('best', true)">
                                <img :src="require('../assets/svg/icon-format-best-size.svg')" />
                                <p class="mt-2.5">Passend zu deinem Bild</p>
                            </div>

                            <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'custom' }" @click="changeFormat('custom', true)">
                                <img :src="require('../assets/svg/icon-format-custom.svg')" />
                                <p class="mt-2.5">Eigenes Format</p>
                            </div>

                            <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == '3_2' }" @click="changeFormat('3_2', true)">
                                <div class="format-rect" style="width: 75px; height: 50px"></div>
                                <p class="mt-2.5">3:2</p>
                            </div>

                            <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == '4_3' }" @click="changeFormat('4_3', true)">
                                <div class="format-rect" style="width: 66.67px; height: 50px"></div>
                                <p class="mt-2.5">4:3</p>
                            </div>

                            <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'quadrat' }" @click="changeFormat('quadrat', true)">
                                <div class="format-rect" style="width: 50px; height: 50px"></div>
                                <p class="mt-2.5">Quadrat</p>
                            </div>

                            <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'standardformat' }" @click="changeFormat('standardformat', true)">
                                <div class="format-rect" style="width: 75px; height: 50px"></div>
                                <p class="mt-2.5">Standardformat</p>
                            </div>

                            <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'panorama' }" @click="changeFormat('panorama', true)">
                                <img :src="require('../assets/svg/icon-format-panorama.svg')" />
                                <p class="mt-2.5">Panorama</p>
                            </div>

                            <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'din' }" @click="changeFormat('din', true)">
                                <img :src="require('../assets/svg/icon-format-din.svg')" />
                                <p class="mt-2.5">DIN</p>
                            </div>
                        </div>
                    </b-col>

                    <b-col cols="12" md="6" v-if="size_options">
                        <p class="select-label text-left mb-1">Grösse wählen</p>
                        <vSelect label="title" v-model="selected_size" :options="formatSizeOptions(this.current_format)">
                            <template slot="option" slot-scope="option">
                                <div class="option-inner w-full h-full flex items-center justify-between cursor-pointer" :class="option.disabled && 'option_inactive'">
                                    <span class="hidden left-red-line"></span>
                                    <span class="option-left font-bold">{{ option.label_size }}</span>
                                    <span class="option-right" v-if="option.disabled">Auflösung zu niedrig</span>
                                    <span class="option-right" v-else>{{ option.label_price }}</span>
                                </div>
                            </template>
                            <template v-slot:no-options>
                                <template> Keine passende Grösse gefunden </template>
                            </template>
                        </vSelect>
                    </b-col>
                </b-row>

                <div class="editor-wrapper mt-2 md:mt-6 lg:mt-0">
                    <ImageEditor v-if="ready" ref="editor" :key="reload_editor" :src="current_image" :ratio="ratio" :bleedX="bleedX" :bleedY="bleedY" :width="width" :height="height" :originalWidth="originalWidth" :originalHeight="originalHeight" :specialBorder="specialBorder" />
                    <div class="text-center" v-if="!ready">
                        <b-spinner label="Laden ..."></b-spinner>
                    </div>
                </div>

                <b-row class="below-editor-info flex lg:hidden mt-12 md:mt-8">
                    <b-col cols="12" class="material-price-wrapper flex justify-end">
                        <div class="text-left" v-if="selected_size">
                            <p class="font-bold mb-0.5">Preis</p>
                            <p class="material-price font-bold" v-if="selected_size">
                                CHF
                                {{ (Math.round(selected_size.price * 100) / 100).toFixed(2) }}
                            </p>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="mt-11 md:mt-7 2xl:mt-16 pt-1">
                    <b-col cols="12" class="editor-buttons-col flex justify-center md:block">
                        <a @click.prevent="uploadFile()" data-v-0d00cbd0="" class="btn mr-3 btn-hollow hidden lg:inline-block btn-mobile"> Anderes Bild auswählen </a>
                        <a @click.prevent="uploadFile()" data-v-0d00cbd0="" class="btn mr-2.5 md:mr-3 btn-hollow inline-block lg:hidden btn-mobile"> Anderes Bild wählen </a>
                        <a @click.prevent="goToCart()" data-v-0d00cbd0="" class="btn mr-3 btn-hollow hidden lg:inline-block btn-mobile"> Weiter ohne Vorschau </a>
                        <a @click.prevent="nextSection()" data-v-0d00cbd0="" class="btn btn-primary btn-mobile"> Weiter zur Vorschau </a>
                    </b-col>
                </b-row>
            </div>

            <b-modal id="modal-materialien" class="editor-modal" :hide-header="true" :hide-footer="true" centered>
                <div class="editor-modal-body">
                    <div class="modal-close absolute top-12 right-12" @click="$bvModal.hide('modal-materialien')">
                        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" version="1.1" class="inline-block cursor-pointer">
                            <g stroke="rgb(112, 112, 112)" stroke-width="1">
                                <line x1="0" y1="0" x2="30" y2="30" />
                                <line x1="0" y1="30" x2="30" y2="0" />
                            </g>
                        </svg>
                    </div>

                    <h2 class="modal-title title text-center mb-3">Material</h2>
                    <h3 class="modal-subtitle text-center mb-12 xl:mb-20 font-bold">Wähle dein Material</h3>
                    <div class="modal-boxes md:justify-center flex mb-8 xl:mb-28 pb-2">
                        <div class="modal-box mx-2.5 xl:mx-3 mb-4 xl:mb-0" v-for="material in materials" :key="material.image">
                            <div
                                class="text-center item-box cursor-pointer"
                                :class="{
                                    'item-material-active': current_material == material
                                }"
                                @click="chooseMaterial(material)"
                            >
                                <div class="item-box-image rounded-full mx-auto">
                                    <img :src="baseAPIUrl + material.image" />
                                </div>
                                <p class="item-box-title mt-4 mb-1">
                                    <b>{{ material.reference }}</b>
                                </p>
                                <p class="item-box-description">{{material.metaKeywords}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <div>
                            <a @click.prevent="$bvModal.hide('modal-materialien')" class="btn btn-hollow">Abbrechen</a>
                        </div>
                    </div>
                </div>

                <a @click.prevent="$bvModal.hide('modal-materialien')" @click="$bvModal.show('modal-upload-file')" class="btn btn-primary absolute button-under-modal" v-if="false">Bild hochladen</a>
            </b-modal>

            <b-modal id="modal-frame" class="editor-modal" :hide-header="true" :hide-footer="true" centered>
                <div class="editor-modal-body">
                    <div class="modal-close absolute top-12 right-12" @click="$bvModal.hide('modal-frame')">
                        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" version="1.1" class="inline-block cursor-pointer">
                            <g stroke="rgb(112, 112, 112)" stroke-width="1">
                                <line x1="0" y1="0" x2="30" y2="30" />
                                <line x1="0" y1="30" x2="30" y2="0" />
                            </g>
                        </svg>
                    </div>

                    <h2 class="modal-title title text-center mb-3">{{ getMaterialEdgeName() }}</h2>
                    <h3 class="modal-subtitle text-center mb-12 xl:mb-20 font-bold">
                        {{ getMaterialEdgeText() }}
                    </h3>
                    <div class="modal-boxes flex justify-center mb-8 xl:mb-28 pb-2">
                        <div class="modal-box mx-2.5 xl:mx-3 mb-4 xl:mb-0" v-for="frame_option in frame_options" :key="frame_option.id">
                            <div
                                class="text-center item-material-box cursor-pointer"
                                :class="{
                                    'item-material-active': selected_frame == frame_option.id
                                }"
                                @click="chooseFrame(frame_option.id)"
                            >
                                <div class="item-box-image rounded-full mx-auto">
                                    <img :src="baseAPIUrl + frame_option.image" />
                                </div>
                                <p class="item-box-title mt-4 mb-1">
                                    <b>{{ frame_option.reference }}</b>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <div>
                            <a @click.prevent="$bvModal.hide('modal-frame')" class="btn btn-hollow">Abbrechen</a>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal id="modal-edge" class="editor-modal" :hide-header="true" :hide-footer="true" centered>
                <div class="editor-modal-body">
                    <div class="modal-close absolute top-12 right-12" @click="$bvModal.hide('modal-edge')">
                        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" version="1.1" class="inline-block cursor-pointer">
                            <g stroke="rgb(112, 112, 112)" stroke-width="1">
                                <line x1="0" y1="0" x2="30" y2="30" />
                                <line x1="0" y1="30" x2="30" y2="0" />
                            </g>
                        </svg>
                    </div>

                    <h2 class="modal-title title text-center mb-3">Kantendruck</h2>
                    <h3 class="modal-subtitle text-center mb-12 xl:mb-20 font-bold">Wie sollen die Kanten bedruckt sein?</h3>
                    <div class="modal-boxes flex justify-center mb-8 xl:mb-28 pb-2">
                        <div class="modal-box mx-2.5 xl:mx-3 mb-4 xl:mb-0" v-for="edge_option in edge_options" :key="edge_option.id">
                            <div
                                class="text-center item-material-box cursor-pointer"
                                :class="{
                                    'item-material-active': selected_edge == edge_option.id
                                }"
                                @click="chooseEdge(edge_option)"
                            >
                                <div class="item-box-image rounded-full mx-auto">
                                    <img :src="baseAPIUrl + edge_option.image" />
                                </div>
                                <p class="item-box-title mt-4 mb-1">
                                    <b>{{ edge_option.reference }}</b>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-center">
                        <div>
                            <a @click.prevent="$bvModal.hide('modal-edge')" class="btn btn-hollow">Abbrechen</a>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal id="modal-size" class="editor-modal" :hide-header="true" :hide-footer="true" centered @shown="recalculateImage()" @hide="modalSizeCancel()">
                <div class="editor-modal-body">
                    <div class="modal-close absolute top-12 right-12" @click="modalSizeCancel()">
                        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" version="1.1" class="inline-block cursor-pointer">
                            <g stroke="rgb(112, 112, 112)" stroke-width="1">
                                <line x1="0" y1="0" x2="30" y2="30" />
                                <line x1="0" y1="30" x2="30" y2="0" />
                            </g>
                        </svg>
                    </div>

                    <h2 class="modal-title title text-center mb-3">Format &amp; Grösse</h2>
                    <h3 class="modal-subtitle text-center mb-2.5 font-bold">Wähle Format und Grösse aus</h3>

                    <b-row class="modal-size-content">
                        <b-col class="modal-size-options-wrapper size-column">
                            <h4 class="font-bold mb-2.5">1. Format wählen:</h4>

                            <div class="format-options grid text-center mb-9">
                                <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'best' }" @click="changeFormat('best')">
                                    <img :src="require('../assets/svg/icon-format-best-size.svg')" />
                                    <p class="mt-2.5">Passend zu deinem Bild</p>
                                </div>

                                <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'custom' }" @click="changeFormat('custom')">
                                    <img :src="require('../assets/svg/icon-format-custom.svg')" />
                                    <p class="mt-2.5">Eigenes Format</p>
                                </div>

                                <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == '3_2' }" @click="changeFormat('3_2')">
                                    <div class="format-rect" style="width: 75px; height: 50px"></div>
                                    <p class="mt-2.5">3:2</p>
                                </div>

                                <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == '4_3' }" @click="changeFormat('4_3')">
                                    <div class="format-rect" style="width: 66.67px; height: 50px"></div>
                                    <p class="mt-2.5">4:3</p>
                                </div>

                                <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'quadrat' }" @click="changeFormat('quadrat')">
                                    <div class="format-rect" style="width: 50px; height: 50px"></div>
                                    <p class="mt-2.5">Quadrat</p>
                                </div>

                                <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'standardformat' }" @click="changeFormat('standardformat')">
                                    <div class="format-rect" style="width: 75px; height: 50px"></div>
                                    <p class="mt-2.5">Standardformat</p>
                                </div>

                                <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'panorama' }" @click="changeFormat('panorama')">
                                    <img :src="require('../assets/svg/icon-format-panorama.svg')" />
                                    <p class="mt-2.5">Panorama</p>
                                </div>

                                <div class="format-option flex items-center justify-center flex-column" :class="{ active: current_format == 'din' }" @click="changeFormat('din')">
                                    <img :src="require('../assets/svg/icon-format-din.svg')" />
                                    <p class="mt-2.5">DIN</p>
                                </div>
                            </div>

                            <h4 class="font-bold mb-2.5">2. Grösse wählen:</h4>

                            <div class="format-select-wrapper flex">
                                <vSelect label="title" v-model="selected_size" :options="formatSizeOptions(this.current_format)" :selectable="(option) => !option.disabled">
                                    <template slot="option" slot-scope="option">
                                        <div class="option-inner w-full h-full flex items-center justify-between cursor-pointer" :class="option.disabled && 'option_inactive'">
                                            <span class="hidden left-red-line"></span>
                                            <span class="option-left font-bold">{{ option.label_size }}</span>
                                            <span class="option-right" v-if="option.disabled">Auflösung zu niedrig</span>
                                            <span class="option-right" v-else>{{ option.label_price }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:no-options>
                                        <template> Keine passende Grösse gefunden </template>
                                    </template>
                                </vSelect>
                            </div>
                        </b-col>

                        <b-col class="modal-size-preview size-column">
                            <h4 class="font-bold mb-2.5 opacity-0">Format wählen:</h4>
                            <div class="preview-image-container relative h-80 2xl:h-96 flex justify-center align-items-center">
                                <div class="preview-box preview-overlay absolute" :style="previewImageBox">&nbsp;</div>
                                <!--<img id="preview-overlay-img" class="preview-overlay absolute" :src="current_preview_image" :style="previewImage" />-->
                                <img id="preview-overlay-human" class="preview-overlay-human absolute l-auto" :src="require('../assets/human-body-with-measure.svg')" />
                                <p class="preview-overlay-height absolute l-auto right-2.5">180 cm</p>
                            </div>
                            <div class="preview-bottom flex justify-center mt-6 2xl:mt-6">
                                <div class="preview-info">
                                    <div class="flex">
                                        <p class="preview-size font-bold mr-3" v-if="selected_size">
                                            {{ selected_size.label_size }}
                                        </p>
                                        <p class="font-bold" v-if="selected_size">
                                            CHF
                                            {{ (Math.round(selected_size.price * 100) / 100).toFixed(2) }}
                                        </p>
                                    </div>
                                </div>                              
                            </div>

                            <div class="preview-bottom flex justify-center mt-6 2xl:mt-6">                          
                                <div class="preview-buttons flex justify-right align-items-center">
                                    <a @click.prevent="modalSizeCancel()" class="btn btn-hollow">Abbrechen</a>
                                    <a @click.prevent="modalSizeConfirm()" class="btn btn-primary">Weiter</a>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>

            <div class="color-picker-wrapper absolute h-full w-full top-0 justify-center align-items-center text-center hidden md:flex" :class="{ 'color-picker-active': colorPickerActive }">
                <div class="color-picker-close top-7 right-6 absolute text-right" @click="toggleColorPicker">
                    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" version="1.1" class="inline-block cursor-pointer">
                        <g stroke="rgb(112, 112, 112)" stroke-width="1">
                            <line x1="0" y1="0" x2="30" y2="30" />
                            <line x1="0" y1="30" x2="30" y2="0" />
                        </g>
                    </svg>
                </div>

                <div class="color-picker-inside">
                    <h3 class="mt-0 mb-4 font-bold flex align-items-center justify-center">Kantenfarbe<i class="fas fa-eye-dropper ml-2"></i></h3>
                    <p class="mx-auto mb-9">Wähle mit der Pipette die gewünschte Kantenfarbe aus dem Farbfeld oder entnimm eine Farbe aus deinem Foto.</p>

                    <div class="color-picker flex">
                        <div class="color-sample" :style="{ 'background-color': color_picker.hex }"></div>
                        <chrome-picker v-model="colorPickerValue" class="flex-1"></chrome-picker>
                        <span class="hidden" :style="{ 'background-color': current_bleed.hex }"></span>
                    </div>

                    <div class="flex mt-14">
                        <a @click="toggleColorPicker" class="btn btn-hollow mr-3">Abbrechen</a>
                        <a @click="setBleedColor(true)" class="btn btn-primary">Farbe wählen</a>
                    </div>
                </div>
            </div>
        </b-container>
    </b-overlay>
</template>

<script>
import SideLine from '../components/SideLine.vue'
import { mapState, mapActions } from 'vuex'
// Import API
import shop from '../api/shop'
import ImageEditor from '../components/ImageEditor'
import { moveUp, moveTo } from '@/utils/fullpage'
import vSelect from 'vue-select'
import { Chrome } from 'vue-color'
import colorpicker from 'vue-color-picker-wheel'
import config from '@/utils/config'

export default {
    name: 'EditorSection',
    props: {
        msg: String
    },
    components: {
        SideLine,
        ImageEditor,
        vSelect,
        'chrome-picker': Chrome,
        colorpicker
    },
    watch: {
        update_size_options() {
            console.log('current_material', this.current_material)

            let material_id = this.current_material ? this.current_material.id : null,
                frame_id = this.selected_frame,
                edge_id = this.selected_edge

            console.log(frame_id)

            if (!material_id) return
            if (!frame_id && this.frame_options && this.frame_options.length) {
                console.log(this.frame_options)
                this.selected_frame = this.frame_options[0].id
                frame_id = this.selected_frame
            }
            if (!edge_id && this.edge_options && this.edge_options.length) {
                this.selected_edge = this.edge_options[0].id
                edge_id = this.selected_edge
            }

            this.$store.commit('cart/setCurrentFrame', this.selected_frame)
            this.$store.commit('cart/setCurrentEdge', this.selected_edge)

            console.log(material_id, frame_id, edge_id)

            if (!material_id) return

            shop.getMaterialDetails(material_id, frame_id, edge_id, (data) => {
                this.size_options = []
                data.sort((a, b) => (a.breite > b.breite ? 1 : b.breite > a.breite ? -1 : 0))
                console.log('getMaterialDetails', data)

                data.forEach((item) => {
                    this.size_options.push({
                        id: {
                            sorting: item.additional[2].value,
                            aspect_ratio: item.additional[1].value,
                            bleed: item.additional[0].value,
                            height: item.hoehe,
                            id: item.nr,
                            item: item,
                            price: item.preis_brutto.toString().includes('.') ? (Math.round(item.preis_brutto * 100) / 100).toFixed(2) : item.preis_brutto + '.00',
                            width: item.breite
                        },
                        text: item.preis_brutto.toString().includes('.') ? item.breite + 'x' + item.hoehe + ' (' + (Math.round(item.preis_brutto * 100) / 100).toFixed(2) + ' CHF)' : item.breite + 'x' + item.hoehe + ' (' + item.preis_brutto + '.00 CHF)'
                    })
                })
                // console.log('size options', this.size_options)

                let formatted_size_options = this.formatSizeOptions(this.current_format)
                if (this.size_options && this.size_options.length) {
                    if (this.cart_current_size) {
                        let foundSize = this.findStateSize(formatted_size_options)
                        if (foundSize) this.selected_size = foundSize
                        else this.selected_size = formatted_size_options[0]
                    } else {
                        this.selected_size = formatted_size_options[0]
                    }
                }
            })
        },
        selected_size(val) {
            if (!val) {
                this.$store.commit('cart/setCurrentItem', null)
                return
            }

            console.log('selected_size', this.selected_size)
            this.$store.commit('cart/setCurrentItem', this.selected_size.item)

            var size_height = parseFloat(this.selected_size.height),
                size_width = parseFloat(this.selected_size.width)

            var bleed
            if (this.selected_size.bleed) {
                bleed = parseInt(this.selected_size.bleed, 10) / 10
            } else {
                bleed = 5
            }
            this.$store.commit('cart/setCartBleed', bleed)

            this.width = size_width
            this.height = size_height
            this.originalWidth = this.current_image_size.width
            this.originalHeight = this.current_image_size.height
            this.bleedX = bleed / (size_width + bleed) //*this.current_image_size.width;
            this.bleedY = bleed / (size_height + bleed) //*this.current_image_size.height;

            if (this.selected_frame && this.selected_edge) console.log('option', this.getCurrentFrame.reference, this.getCurrentEdge.reference, this.selected_size)
            console.log('bleed', parseFloat(bleed) + ' - ' + (size_width + bleed * 2))
            console.log('bleedX', size_width + bleed * 2)
            console.log('bleed: ' + bleed + ' - ' + size_width + ' - ' + this.bleedX)
            console.log(this.bleedX + 'x' + this.bleedY)

            this.ratio = (size_width + bleed * 2) / (size_height + bleed * 2)

            console.log('size_height', size_height, 'bleed', bleed, 'size_width', size_width)
            console.log('this.ratio: ' + this.ratio)

            this.recalculateImage()
            this.$store.commit('cart/setCurrentSize', this.selected_size)
            if (!this.previews_size) this.$store.commit('cart/setPreviewsSize', this.selected_size)
        },
        current_image(val) {
            console.log('current_image updated')
            this.inlineSrc = val
            this.selected_frame = null
            console.log('refresh image')
            this.refreshEditor()
        }
    },
    computed: {
        ...mapState({
            current_image: (state) => state.cart.current_image,
            current_preview_image: (state) => state.cart.current_preview_image,
            current_image_size: (state) => state.cart.current_image_size,
            current_material(state) {
                if (state.cart.current_material) this.selected_material = state.cart.current_material.reference
                return state.cart.current_material
            },
            current_frame: (state) => state.cart.current_frame,
            current_edge: (state) => state.cart.current_edge,
            current_material_detail: (state) => state.cart.current_material_detail,
            materials: (state) => state.shop.materials,
            frames(state) {
                if (
                    this.current_material &&
                    state.shop.frames.filter((x) => {
                        return x.parent == this.current_material.id
                    }).length
                ) {
                    if (this.current_frame) this.selected_frame = this.current_frame
                    else this.selected_frame = state.shop.frames.map((elt) => elt.id == this.current_material.id)[0].id
                }

                return state.shop.frames
            },
            edges(state) {
                if (this.selected_frame && state.shop.edges.filter((x) => x.parent === this.selected_frame).length) {
                    if (this.current_edge) this.selected_edge = this.current_edge
                    else this.selected_edge = state.shop.edges.map((elt) => elt.id == this.current_material.id)[0].id
                }
                return state.shop.edges
            },
            current_bleed: (state) => state.cart.current_edge_color,
            color_picker: (state) => state.cart.color_picker,
            cart_current_size: (state) => state.cart.current_size,
            preview_size: (state) => state.cart.preview_size,
            previews_size: (state) => state.cart.previews_size,
            current_format: (state) => state.cart.current_format,
            frame_is_selected: (state) => state.cart.selected_options_frame,
            edge_is_selected: (state) => state.cart.selected_options_edge,
            size_is_selected: (state) => state.cart.selected_options_size,
            show_preview: (state) => state.cart.show_preview
        }),
        ready() {
            return this.selected_size ? true : false
        },
        update_size_options() {
            // one watch handler for multiple properties
            return [this.selected_frame, this.selected_edge, this.current_material]
        },
        frame_options() {
            let material_id = this.current_material ? this.current_material.id : null
            if (!this.frames) return []
            return this.frames.filter((x) => {
                return x.parent == material_id
            })
        },
        edge_options() {
            let material = this.current_material // needed for vue watch update
            var selected_frame = this.selected_frame
            if (!this.edges) return []
            return this.edges.filter((x) => x.parent === selected_frame)
        },
        getCurrentFrame() {
            return this.frames.filter((obj) => {
                return obj.id == this.selected_frame
            })[0]
        },
        getCurrentEdge() {
            return this.edges.filter((obj) => {
                return obj.id == this.selected_edge
            })[0]
        },
        previewImage() {
            return {
                width: 'auto',
                height: this.previewImageSize.height
            }
        },
        previewImageBox() {
            return {
                width: this.previewImageSize.width,
                height: this.previewImageSize.height,
                backgroundImage: "url("+ this.current_preview_image +")" 
            }
        },
        colorPickerValue: {
            get() {
                return this.color_picker
            },
            set(value) {
                this.$store.commit('cart/setColorPicker', value)
                document.querySelector('.vc-hue-picker').style.backgroundColor = 'hsl(' + this.color_picker.hsl.h + ', 100%, 50%)'
            }
        },
        colorPickerValueMobile: {
            get() {
                return this.color_picker.hex
            },
            set(value) {
                let rgb = this.hexToRgb(value),
                    hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b)

                this.$store.commit('cart/setColorPicker', {
                    hex: value,
                    rgba: {
                        r: rgb.r,
                        g: rgb.g,
                        b: rgb.b,
                        a: 1
                    },
                    hsl: {
                        h: hsl[0] * 360,
                        s: hsl[1] * 100,
                        l: hsl[2] * 100
                    }
                })
            }
        },
        selected_frame_text: {
            get() {
                return this.getCurrentFrame ? this.getCurrentFrame.reference : ''
            },
            set(value) {
                if (value.value !== this.current_frame) {
                    this.selected_edge = null
                    this.selected_size = null
                }
                this.selected_frame = this.frames.filter((obj) => {
                    return obj.id == value.value
                })[0].id
                this.$store.commit('cart/setCurrentFrame', this.selected_frame)
            }
        },
        selected_edge_text: {
            get() {
                return this.getCurrentEdge ? this.getCurrentEdge.reference : ''
            },
            set(value) {
                if (value.value !== this.current_edge) {
                    this.selected_size = null
                }
                if (value.title.toLowerCase().includes('farbe')) this.setBleedColor(false)
                else this.resetBleedColor(value.title)
                this.selected_edge = this.edges.filter((obj) => {
                    return obj.id == value.value
                })[0].id
                this.$store.commit('cart/setCurrentEdge', this.selected_edge)
            }
        },
        current_edge_is_kolor() {
            if (this.getCurrentEdge) return this.getCurrentEdge.reference.toLowerCase().includes('farbe')
            return false
        }
    },
    mounted() {
        this.$root.$on('chooseMaterialFromMaterialSection', (material) => {
            this.chooseMaterial(material)
        })
    },
    data() {
        return {
            reload_editor: 0,
            ratio: null,
            bleedX: 0,
            bleedY: 0,
            width: 0,
            height: 0,
            originalWidth: 0,
            originalHeight: 0,
            selected_frame: null,
            selected_edge: null,
            selected_size: null,
            selected_material: null,

            specialBorder: false,

            size_options: [],

            selected: 120,
            options: [
                {
                    value: 120,
                    text: '120 x 80'
                },
                {
                    value: 200,
                    text: '200 x 2'
                }
            ],
            myFiles: [],
            colorPickerActive: false,
            color_picker_mobile: '#ff6600',
            previewImageSize: {
                width: 0,
                height: 0
            },
            addingToBasket: false,
            baseAPIUrl: config.baseAPIUrl
        }
    },
    methods: {
        moveUp,
        moveTo(x) {
            moveTo(x)
        },
        ...mapActions({
            addToBasket: 'cart/addToBasket',
            setCurrentOutputImage: 'cart/setCurrentOutputImage'
        }),
        refreshEditor() {
            // updating the key will force-reload the vue component
            this.reload_editor += 1
        },
        getImage(path) {
            var img = require(`../assets/svg/${path}.png`)
            return img
        },
        chooseMaterial(material) {
            if (material !== this.current_material) {
                this.selected_frame = null
                this.selected_edge = null
                this.selected_size = null
            }
            this.$bvModal.hide('modal-materialien')
            this.$store.dispatch('cart/setCurrentMaterial', material)
            this.specialBorder = false;
        },
        chooseFrame(frame) {
            if (frame !== this.current_frame) {
                this.selected_edge = null
                this.selected_size = null
            }
            this.$bvModal.hide('modal-frame')
            this.selected_frame = frame
            this.$store.commit('cart/setCurrentFrame', this.selected_frame)
            this.$store.commit('cart/updateSelectedOptions', 1)
        },
        chooseEdge(edge) {
            if (edge.id !== this.current_edge) {
                this.selected_size = null
            }
            this.$bvModal.hide('modal-edge')
            let edgeReference = edge.reference
            if (edgeReference.toLowerCase().includes('farbe')) this.setBleedColor()
            else this.resetBleedColor(edgeReference)
            this.selected_edge = edge.id

            const edgeName = this.selected_edge_text.toLowerCase();
            this.specialBorder = ((this.selected_material.toLowerCase().includes('leinwand')) && (edgeName.includes('farbe') || edgeName.includes('weiss') || edgeName.includes('schwarz')));

            this.$store.commit('cart/setCurrentEdge', this.selected_edge)
            this.$store.commit('cart/updateSelectedOptions', 2)
        },
        uploadFile() {
            moveTo(1)
            this.$bvModal.show('modal-upload-file')
        },
        nextSection() {
            this.goToPreview(5)
            this.$store.dispatch('wizard/updateWizard', {
                index: 2,
                value: 1
            })
        },
        goToCart() {
            this.goToPreview(6)
            this.$store.dispatch('wizard/updateWizard', {
                index: 2,
                value: 1
            })
        },
        goToPreview(section) {
            this.$store.commit('cart/showPreview')
            this.$refs.editor.updateImage((file1, file2) => {
                console.log('go to preview')
                console.log(file1)
                console.log(file2)
                this.$store.dispatch('cart/setCurrentImageFileFull', file1)
                this.$store.dispatch('cart/setCurrentImageFile', file2)

                this.setCurrentOutputImage(URL.createObjectURL(file2)).then(() => {
                    this.$root.$emit('recalculatePreviewSize', this.cart_current_size)
                    if (section == 6) {
                        this.addingToBasket = true
                        this.$store.dispatch('cart/uploadUpscaledImage', () => {
                            this.addToBasket((data) => {
                                this.$store.dispatch('cart/addFinalImage', data.data).then(() => {
                                    this.$store.dispatch('cart/updateBasketItems', data).then(() => {
                                        console.log('done')
                                        this.addingToBasket = false
                                        this.$store.dispatch('wizard/updateWizard', {
                                            index: 3,
                                            value: 1
                                        })
                                        moveTo(section)
                                    })
                                })
                            })
                        })
                    } else moveTo(section)
                })
            })
        },
        changeFormat(format, refresh = false) {
            if (this.current_format != format) {
                this.$store.commit('cart/setCurrentFormat', format)
                let formatted_size_options = this.formatSizeOptions(format)

                if (this.size_options && this.size_options.length) {
                    if (this.cart_current_size) {
                        let foundSize = this.findStateSize(formatted_size_options)
                        if (foundSize) this.selected_size = foundSize
                        else this.selected_size = formatted_size_options[0]
                    } else {
                        this.selected_size = formatted_size_options[0]
                    }
                }
                if (refresh) this.refreshEditor();
            }
        },
        formatSizeOptions(filter) {
            let selectOptions = [],
                selectOptions2 = [],
                aspectRatio = this.current_image_size.width / this.current_image_size.height

            this.size_options.forEach((elt, i) => {
                let option = elt.text.split(/ (.+)/),
                    disabled = true,
                    width = elt.id.width,
                    height = elt.id.height,
                    label_size = option[0],
                    title = elt.text

                if (aspectRatio < 1) {
                    width = elt.id.height
                    height = elt.id.width
                    label_size = option[0].split('x')[1] + 'x' + option[0].split('x')[0]
                    title = label_size + ' ' + option[1]
                }

                let obj = {
                    aspect_ratio: elt.id.aspect_ratio,
                    bleed: elt.id.bleed,
                    id: elt.id.id,
                    height: height,
                    width: width,
                    price: elt.id.price,
                    item: elt.id.item,
                    title: title,
                    label_size: label_size + ' cm',
                    label_price: option[1].replaceAll('(', '').replaceAll(')', ''),
                    disabled: disabled
                }

                if (filter == 'best') {
                    if (Math.abs(aspectRatio - width / height) < 0.5 && Math.round((width / 2.54) * 72) < this.current_image_size.width && Math.round((height / 2.54) * 72) < this.current_image_size.height) {
                        obj.disabled = false
                        selectOptions.push(obj)
                    } else if (Math.abs(aspectRatio - width / height) < 0.5) selectOptions2.push(obj)
                } else if (filter == 'custom') {
                    if (Math.round((width / 2.54) * 72) < this.current_image_size.width && Math.round((height / 2.54) * 72) < this.current_image_size.height) {
                        obj.disabled = false
                        selectOptions.push(obj)
                    } else selectOptions2.push(obj)
                } else {
                    if (filter.toLowerCase() == obj.aspect_ratio && Math.round((width / 2.54) * 72) < this.current_image_size.width && Math.round((height / 2.54) * 72) < this.current_image_size.height) {
                        obj.disabled = false
                        selectOptions.push(obj)
                    } else if (filter.toLowerCase() == obj.aspect_ratio) selectOptions2.push(obj)
                }
            })

            if (aspectRatio < 1) {
                selectOptions.sort((a, b) => (a.width > b.width ? 1 : b.width > a.width ? -1 : 0))
                selectOptions2.sort((a, b) => (a.width > b.width ? 1 : b.width > a.width ? -1 : 0))
            }

            let disableOnFormats = ['best', 'custom', '3_2', '4_3', 'quadrat', 'standardformat', 'panorama', 'din']
            if (disableOnFormats.includes(filter)) selectOptions = selectOptions.concat(selectOptions2)
            return selectOptions
        },
        formatMaterialOptions() {
            let selectOptions = []
            this.materials.forEach((elt) => {
                selectOptions.push({
                    id: elt.id,
                    title: elt.reference,
                    material: elt
                })
            })
            return selectOptions
        },
        formatFrameOptions() {
            let selectOptions = []
            this.frame_options.forEach((elt) => {
                selectOptions.push({
                    value: elt.id,
                    title: elt.reference
                })
            })
            return selectOptions
        },
        formatEdgeOptions() {
            let selectOptions = []
            this.edge_options.forEach((elt) => {
                selectOptions.push({
                    value: elt.id,
                    title: elt.reference
                })
            })
            return selectOptions
        },
        findStateSize(array) {
            return array.find((obj) => {
                return obj.width == this.cart_current_size.width && obj.height == this.cart_current_size.height && !obj.disabled
            })
        },
        modalSizeCancel() {
            this.selected_size = this.previews_size
            this.$bvModal.hide('modal-size')
        },
        modalSizeConfirm() {
            this.$store.commit('cart/setPreviewsSize', this.selected_size)
            this.$bvModal.hide('modal-size')
            this.refreshEditor()
            this.$store.commit('cart/updateSelectedOptions', 3)
        },
        toggleColorPicker() {
            if (!this.colorPickerActive) document.querySelector('.vc-hue-picker').style.backgroundColor = 'hsl(' + this.color_picker.hsl.h + ', 100%, 50%)'
            this.colorPickerActive = !this.colorPickerActive
        },
        updateMaterial(value) {
            this.chooseMaterial(value.material)
        },
        recalculateImage() {
            let img = document.getElementById('preview-overlay-human')
            if (img) {
                let ratio = img.height / 180
                //this.previewImageSize.width = 'auto'
                this.previewImageSize.width = Math.trunc(this.selected_size.width * ratio) + 'px'
                this.previewImageSize.height = Math.trunc(this.selected_size.height * ratio) + 'px'
            }
        },
        setBleedColor(refresh) {
            let colorRGB = this.hexToRgb(this.color_picker.hex)
            this.$store.commit('cart/setCurrentEdgeColor', {
                hex: this.color_picker.hex,
                rgba: 'rgba(' + colorRGB.r + ', ' + colorRGB.g + ', ' + colorRGB.b + ', .7)'
            })
            console.log('refresh set bleed color')
            if (refresh) this.refreshEditor()
            if (this.colorPickerActive) this.toggleColorPicker()
        },
        resetBleedColor(reference) {
            console.log(reference)
            if (reference.toLowerCase().includes('schwarz'))
                this.$store.commit('cart/setCurrentEdgeColor', {
                    hex: '#000000',
                    rgba: 'rgba(0, 0, 0, .7)'
                })
            else if (reference.toLowerCase().includes('weiss'))
                this.$store.commit('cart/setCurrentEdgeColor', {
                    hex: '#ffffff',
                    rgba: 'rgba(255, 255, 255, .7)'
                })
            else
                this.$store.commit('cart/setCurrentEdgeColor', {
                    hex: '#ffa500',
                    rgba: 'rgba(255, 165, 0, .7)'
                })
        },
        getMaterialEdgeName() {
            let materialName = this.current_material.reference.toLowerCase()
            if (materialName.includes('leinwand') || materialName.includes('alu')) return 'Rahmendicke'
            else if (materialName.includes('acrylglas') || materialName.includes('galeriedruck')) return 'Aufhänger'
            else if (materialName.includes('hartschaum')) return 'Farbe'
            else if (materialName.includes('poster')) return 'Oberfläche'
            else return 'Rahmendicke'
        },
        getMaterialEdgeText() {
            let materialName = this.current_material.reference.toLowerCase()
            if (materialName.includes('leinwand') || materialName.includes('alu')) return 'Wähle die Dicke des Holzrahmens'
            else if (materialName.includes('acrylglas') || materialName.includes('galeriedruck')) return 'Wähle deinen passenden Aufhänger'
            else if (materialName.includes('hartschaum')) return 'Farbe auswählen'
            else if (materialName.includes('poster')) return 'Wähle die Oberfläche'
            else return 'Wähle die Dicke des Holzrahmens'
        },
        hexToRgb(hex) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
            return result
                ? {
                      r: parseInt(result[1], 16),
                      g: parseInt(result[2], 16),
                      b: parseInt(result[3], 16)
                  }
                : null
        },
        componentToHex(c) {
            var hex = c.toString(16)
            return hex.length == 1 ? '0' + hex : hex
        },
        rgbToHex(r, g, b) {
            return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
        },
        rgbToHsl(r, g, b) {
            (r /= 255), (g /= 255), (b /= 255)
            var max = Math.max(r, g, b),
                min = Math.min(r, g, b)
            var h,
                s,
                l = (max + min) / 2

            if (max == min) {
                h = s = 0 // achromatic
            } else {
                var d = max - min
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
                switch (max) {
                    case r:
                        h = (g - b) / d + (g < b ? 6 : 0)
                        break
                    case g:
                        h = (b - r) / d + 2
                        break
                    case b:
                        h = (r - g) / d + 4
                        break
                }
                h /= 6
            }

            return [h, s, l]
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

#editor-section {
    background: #f2f2f2;

    .container-inner {
        padding-right: 220px;
        padding-left: 220px;

        @media (max-width: 1600px) {
            padding-right: 90px;
            padding-left: 90px;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        @media (max-width: 1199px) {
            padding-right: 40px;
            padding-left: 40px;
        }

        @media (max-width: 991px) {
            padding-right: 0;
            padding-left: 0;
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
    .btn-secondary {
        background-color: #d7d7d7;
        border-color: #d7d7d7;
        &:hover {
            background-color: #58585a;
            border-color: #58585a;
        }
    }

    .title {
        color: #58585a;
    }

    a.btn {
        margin-bottom: 0 !important;
    }

    .option-number {
        color: #f7a600;
        font-size: 4rem;
        line-height: 4rem;
    }
    .option-title {
        color: #58585a;
        font-size: 1.125rem;
        line-height: 1.563rem;
    }

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }

    .modal-title {
        font-size: 2rem;
        font-weight: bold;
    }

    .select-label {
        font-size: 1.125rem;
        line-height: 1.563rem;
        color: #58585a;
    }

    .editor-buttons {
        @media (min-width: 1400px) {
            flex: 0 0 75%;
            max-width: 75%;
        }

        .row {
            .button-col {
                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: 1rem;
                }

                @media (min-width: 1450px) {
                    flex: 0 0 25%;
                    max-width: 25%;
                    margin-bottom: 0;
                }

                .option-number {
                    display: none;
                }

                &:nth-child(1) {
                    .option-number-1 {
                        display: block;
                    }
                }

                &:nth-child(2) {
                    .option-number-2 {
                        display: block;
                    }
                }

                &:nth-child(3) {
                    .option-number-3 {
                        display: block;
                    }
                }

                &:nth-child(4) {
                    .option-number-4 {
                        display: block;
                    }
                }
            }
        }

        .btn {
            min-width: unset;
            white-space: nowrap;

            span {
                white-space: nowrap;
            }
        }
    }

    .color-picker-mobile-wrapper {
        border-radius: 21px;
    }

    .editor-wrapper {
        flex: 1;
        min-height: 600px;
    }

    .below-editor-info {
        p {
            font-size: 0.938rem;
            line-height: 1.25rem;

            &.font-bold {
                font-size: 1.125rem;
                line-height: 1.563rem;
            }

            &.material-price {
                font-size: 1.375rem;
            }
        }

        .red-circle {
            display: inline-block;
            width: 15px;
            height: 15px;
            background-color: #ff1d1d;
        }
    }

    .editor-buttons-col {
        text-align: right;

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    .material-price-wrapper {
        font-size: 1.125rem;
        line-height: 1.563rem;
        color: #58585a;
        margin-bottom: 0.5rem;

        @media (min-width: 1450px) {
            margin-bottom: 1.5rem;
        }

        .material-price {
            color: #f7a600;
            font-size: 1.375rem;
        }
    }
}

.v-select {
    @media (max-width: 1199px) {
        margin-bottom: 1.5rem;
    }

    .vs__dropdown-toggle {
        padding: 0;
    }

    .vs__selected-options {
        padding: 0;
    }

    .vs__dropdown-toggle {
        border: 1px solid #bcbcbc;
        border-radius: 7px;
        background-color: #fff;
    }

    .vs__search,
    .vs__selected,
    .vs__selected {
        font-family: 'Titillium Web';
        padding: 0.5rem 1.25rem;
        margin: 0;
        font-size: 1.1rem;
    }

    .vs__actions {
        display: none;
    }

    .vs__dropdown-menu {
        top: calc(100% + 11px);
        border-top-style: solid;
        border-radius: 7px;
        max-height: 431px;
        box-shadow: 0px 6px 6px #00000029;
        padding: 0;

        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari, and Opera */
        }

        .vs__dropdown-option {
            margin: 0;
            font-size: 1.1rem;
            color: #58585a;
            cursor: default;
            padding: 0;

            .option-inner {
                padding: 0.875rem 1.188rem 0.875rem 2rem;

                &:hover {
                    background-color: #f2f2f2;
                }

                &.option_inactive {
                    color: #c7c7c7;
                    pointer-events: none;
                    cursor: default;

                    /*.left-red-line {
                        display: inline-block;
                        position: absolute;
                        width: 5px;
                        height: 30px;
                        left: 0;
                        background-color: #fd1c1d;
                    }*/
                }
            }
        }

        .vs__dropdown-option--highlight {
            background-color: #fff;
        }
    }

    &.select-normal {
        .vs__dropdown-option {
            padding: 0.875rem 1.188rem 0.875rem 2rem;

            &:hover {
                background-color: #f2f2f2;
                cursor: pointer;
            }
        }
    }
}

#modal-materialien,
#modal-frame,
#modal-edge,
#modal-size {
    padding-right: 0 !important;

    .modal-dialog {
        max-width: 1680px;
        padding-left: 3rem;
        padding-right: 3rem;
        box-sizing: content-box;

        .modal-body {
            padding: 3.75rem 5.75rem 6.75rem;
            box-shadow: 0px 10px 10px #00000029;
            color: #58585a;

            @media (max-width: 1199px) {
                padding: 2.5rem 4rem;
            }

            .modal-subtitle {
                font-size: 2.188rem;
                line-height: 2.5rem;
            }

            .modal-boxes {
                @media (max-width: 1199px) {
                    flex-wrap: wrap;
                }

                .modal-box {
                    @media (min-width: 1200px) {
                        &:first-child {
                            margin-left: 0 !important;
                        }

                        &:last-child {
                            margin-right: 0 !important;
                        }
                    }
                    width: 16.666%;
                    @media (max-width: 1199px) {
                        width: 25%;
                    }

                    .item-box-image {
                        overflow: hidden;
                        max-width: 12.5rem;
                        transition: all 0.2s ease;

                        @media (max-width: 1199px) {
                            max-width: 9rem;
                        }

                        img {
                            transition: all 0.2s ease;
                        }

                        &:hover {
                            border: 5px solid #f7a600 !important;

                            img {
                                transform: scale(1.1);
                            }
                        }
                    }

                    .item-material-active {
                        .item-box-image {
                            border: 5px solid #f7a600;
                        }
                    }

                    .item-box-title {
                        font-size: 1.375rem;
                        line-height: 1.6rem;
                        color: #f7a600;
                        transition: color 0.2s ease;

                        @media (max-width: 1199px) {
                            font-size: 1.2rem;
                            line-height: 1.5rem;
                        }

                        &:hover {
                            color: #fdbe3c;
                        }
                    }

                    .item-box-description {
                        font-size: 1.125rem;
                        line-height: 1.563rem;

                        @media (max-width: 1199px) {
                            font-size: 1rem;
                            line-height: 1.4rem;
                        }
                    }
                }
            }
        }

        .button-under-modal {
            bottom: -4rem;
            right: 5.75rem;
        }
    }
}

.format-select-info-red-line {
    font-size: 0.938rem;

    .red-line {
        width: 30px;
        height: 5px;
        background-color: #fd1c1d;
        margin-bottom: 3px;
        margin-right: 13px;
    }
}

.format-options {
    gap: 13px;

    .format-option {
        width: 100%;
        height: 125px;
        border: 1px solid #bcbcbc;
        border-radius: 7px;

        &.active {
            border: 5px solid #f7a600;
        }

        &:hover {
            cursor: pointer;
            border: 5px solid #f7a600;
        }

        .format-rect {
            background-color: #e9e9e9;
        }

        p {
            font-size: 0.938rem;
            line-height: 1.1rem;
        }
    }

    &.format-options-mobile {
        .format-option {
            background-color: #fff;
            padding: 0.5rem 0.75rem;
        }
    }
}

#modal-size {
    .modal-dialog .modal-body {
        @media (max-width: 1600px) {
            padding: 2.5rem 3rem;
        }
    }

    .editor-modal-body {
        color: #58585a;

        .row {
            margin-left: -10px;
            margin-right: -10px;

            @media (max-width: 1600px) {
                margin-top: 2rem;
            }

            .size-column {
                padding: 0 10px;
            }
        }

        .modal-size-content {
            h4 {
                font-size: 1.375rem;
                line-height: 2.125rem;
            }

            .modal-size-options-wrapper {
                .format-options {
                    max-width: max-content;
                    grid-template-columns: repeat(4, minmax(0, 1fr));

                    @media (max-width: 1400px) {
                        grid-template-columns: repeat(3, minmax(0, 1fr));
                    }

                    @media (max-width: 1199px) {
                        grid-template-columns: repeat(4, minmax(0, 1fr));
                    }

                    .format-option {
                        width: 120px;
                        height: 120px;
                    }
                }

                .v-select {
                    max-width: 382px;
                    flex: 1;

                    > ul {
                        max-height: 205px;
                    }
                }
            }

            .modal-size-preview {
                max-width: 740px;
                flex: unset;

                @media (max-width: 1600px) {
                    max-width: 600px;
                }

                @media (max-width: 1199px) {
                    max-width: 100%;
                }

                .preview-image-container {
                    border-radius: 7px;
                    border: 1px solid #bcbcbc;
                    .preview-box {
                       background-position: center center;
                       background-size: cover;
                    }

                    .preview-overlay {
                        @media (max-width: 1600px) {
                            right: 13rem;
                        }

                        @media (max-width: 1199px) {
                            right: unset;
                        }
                    }

                    .preview-overlay-human {
                        right: 3.75rem;
                    }

                    .preview-overlay-height {
                        font-size: 0.938rem;
                        line-height: 1.25rem;
                    }
                }

                .preview-bottom {
                    .preview-info {
                        font-size: 1.375rem;
                        line-height: 2.125rem;

                        .preview-size {
                            color: #f7a600;
                        }
                    }

                    .preview-buttons {
                        flex-direction: row;

                        @media (max-width: 1600px) {
                            flex-direction: column;
                        }

                        @media (max-width: 1199px) {
                            flex-direction: row;
                        }

                        .btn-hollow {
                            margin-right: 0.875rem;
                            margin-bottom: 0;

                            @media (max-width: 1600px) {
                                margin-right: 0;
                                margin-bottom: 0.875rem;
                            }

                            @media (max-width: 1199px) {
                                margin-right: 0.875rem;
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.color-picker-wrapper {
    max-width: 460px;
    box-shadow: -10px 0px 15px #00000029;
    background-color: #ffffff;
    color: #58585a;
    right: -100%;
    z-index: 99999;
    transition: all 1s ease;

    &.color-picker-active {
        right: 0;
    }

    .color-picker-inside {
        h3 {
            font-size: 2.188rem;
            line-height: 2.5rem;

            i {
                font-size: 1.3rem;
            }
        }

        p {
            font-size: 1.125rem;
            line-height: 1.563rem;
            max-width: 370px;
        }

        .color-sample {
            width: 100px;
            height: 160px;
        }

        .vc-chrome {
            box-shadow: none;

            .vc-chrome-saturation-wrap {
                height: 160px;
                padding-bottom: 0;
                border-radius: 0;
            }

            .vc-chrome-body {
                padding: 1.5rem 0 0;
                margin-left: -100px;

                .vc-chrome-controls {
                    .vc-chrome-color-wrap,
                    .vc-chrome-alpha-wrap {
                        display: none;
                    }

                    .vc-chrome-hue-wrap {
                        margin: 0;
                        height: 8px;

                        .vc-hue {
                            border-radius: 6px;

                            .vc-hue-picker {
                                width: 18px;
                                height: 18px;
                                border-radius: 50%;
                                box-shadow: none;
                                border: 2px solid #fff;
                                margin-top: -2px;
                            }
                        }
                    }
                }

                .vc-chrome-fields-wrap {
                    display: none;
                }
            }
        }

        .btn {
            min-width: 190px;
        }
    }
}
</style>
