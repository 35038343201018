/* global fullpage_api */

export function moveDown() {
  fullpage_api.moveSectionDown()
}

export function moveUp() {
  fullpage_api.moveSectionUp()
}

export function moveTo(x) {
  fullpage_api.moveTo(x)
}
export function setAllowScrolling(enable, direction) {
  fullpage_api.setAllowScrolling(enable, direction)
}
