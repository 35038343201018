<template>
<div class="lines hidden lg:block">
    <div class="bubble"></div>
    <div class="text">{{title}}</div>
    <div class="line"></div>
</div>
</template>

<script>
export default {
    name: "SideLine",
    props: {
        title: String
    },
};
</script>

<style lang="scss">
.bubble {
    position: relative;
    left: -5px;
    width: 20px;
    height: 20px;
    background: orange;
    border-radius: 50%;
    margin-bottom: 14px;
    margin-top: 62px;

    &:before {
        content: " ";
        display: none;
        ;
    }

    &:after {
        position: absolute;
        left: 5px;
        top: -62px;
        content: "";
        height: 50px;
        width: 2px;
        background: orange;
        margin-left: 4.3px;
        display: flex;
        margin-bottom: 14px;
    }
}

.text {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 1.25rem;
    display: flex;
    margin-left: -10px;
    margin-bottom: 20px;
}

.line {
    width: 2px;
    height: 100%;
    display: flex;
    background: orange;
    margin-left: 4.3px;
}

/* Line between two cols */
.line-col {
    width: 3px;
    height: auto;
    background: orange;
    margin-left: 5.3px;
    margin: 30px 0 120px 0;
}
</style>
